import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './footer.module.scss'

import { Heart } from '@isdd/metais-common/assets/images'
import { FEEDBACK_EMAIL } from '@isdd/metais-common/api'

export const FooterFeedback: React.FC = () => {
    const { t } = useTranslation()
    const [showFeedbackReplay, setShowFeedbackReplay] = useState(false)

    const handleEmailClick = () => {
        window.location.href = `mailto:${FEEDBACK_EMAIL}?subject=${t('footer.feedback.emailSubject')}`
    }

    return (
        <div className="idsk-footer-extended-feedback">
            <div className="govuk-width-container">
                <div id="idsk-footer-extended-feedback" className="idsk-footer-extended-feedback-text">
                    <div className="idsk-footer-extended-feedback-container">
                        {!showFeedbackReplay && (
                            <div id="idsk-footer-extended-info-question">
                                <div className="govuk-grid-column-two-thirds idsk-footer-extended-usefull-question">
                                    <span className="idsk-footer-extended-feedback-question-info-usefull">{t('footer.feedback.usefulQuestion')}</span>
                                    <span className="idsk-footer-extended-usefull-question-mobile">{t('footer.feedback.usefulQuestion')}</span>
                                    <div className="idsk-footer-extended-usefull-answers-mobile">
                                        <div>
                                            <button
                                                id="idsk-footer-extended-feedback-yes-button"
                                                type="button"
                                                className="idsk-footer-extended-feedback-text-answers idsk-footer-extended-help-button"
                                                onClick={() => setShowFeedbackReplay(true)}
                                            >
                                                {t('radioButton.yes')}
                                                <span className="govuk-visually-hidden">{t('footer.feedback.usefulQuestion')}</span>
                                            </button>
                                            <button
                                                id="idsk-footer-extended-feedback-no-button"
                                                type="button"
                                                className="idsk-footer-extended-feedback-text-answers idsk-footer-extended-help-button"
                                                onClick={handleEmailClick}
                                            >
                                                {t('radioButton.no')}
                                                <span className="govuk-visually-hidden">{t('footer.feedback.usefulQuestion')}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="govuk-grid-column-one-third idsk-footer-extended-usefull-question idsk-footer-extended-white-border">
                                    <div className="idsk-footer-extended-feedback-question-info-usefull">
                                        <button
                                            id="idsk-footer-extended-error-button"
                                            type="button"
                                            className="idsk-footer-extended-feedback-text-answers idsk-footer-extended-help-button"
                                            onClick={handleEmailClick}
                                        >
                                            {t('footer.feedback.errorQuestion')}
                                        </button>
                                    </div>
                                    <div className={'idsk-footer-extended-usefull-question-mobile'}>
                                        <span>{t('footer.feedback.errorQuestion')}</span>
                                        <button
                                            id="idsk-footer-extended-write-us-button"
                                            type="button"
                                            className={classNames([
                                                'idsk-footer-extended-feedback-text-answers',
                                                'idsk-footer-extended-help-button',
                                                styles.contacUs,
                                            ])}
                                            onClick={handleEmailClick}
                                        >
                                            {t('footer.feedback.contactUs')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showFeedbackReplay && (
                            <div
                                id="idsk-footer-extended-heart"
                                className={classNames(['govuk-grid-column-full', styles.feedbackResponse])}
                                aria-label={t('footer.feedback.response')}
                            >
                                {t('footer.feedback.response')}
                                <img className="idsk-footer-extended-heart--svg" src={Heart} alt="" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
