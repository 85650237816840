import { LoadingIndicator } from '@isdd/idsk-ui-kit/loading-indicator/LoadingIndicator'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import { RouterRoutes } from '@isdd/metais-common/navigation/routeNames'
import { PORTAL_URL } from '@isdd/metais-common/constants'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { useUserPreferences } from '@isdd/metais-common/contexts/userPreferences/userPreferencesContext'
import { ADMIN, Actions, CANNOT_READ_ENTITY, CAN_CREATE_WITHOUT_LOGIN, HKO } from '@isdd/metais-common/hooks/permissions/useUserAbility'

interface iProtectedRoute {
    element: JSX.Element
    slug?: string
    isAdmin?: boolean
}

enum AUTHORIZATION_STATUS {
    UNKNOWN = 'unknown',
    AUTHORIZED = 'authorized',
    UNAUTHORIZED = 'unauthorized',
}

const ProtectedRoute = ({ element, slug, isAdmin }: iProtectedRoute) => {
    const [authorizationStatus, setAuthorizationStatus] = useState<AUTHORIZATION_STATUS>(AUTHORIZATION_STATUS.UNKNOWN)
    const { isLoadingUserPreferences } = useUserPreferences()
    const {
        state: { token, user },
    } = useAuth()
    const actions = Object.values(Actions)
    const selectedAbility = actions?.find((action) => slug?.includes(action))
    const isUserLogged = !!token
    const isCannotReadPage = CANNOT_READ_ENTITY?.some((entity) => slug?.includes(entity))
    const isCanWithoutLogin = CAN_CREATE_WITHOUT_LOGIN.some((entity) => slug?.includes(entity))

    useEffect(() => {
        const isUnauthorized =
            (isAdmin && !isUserLogged && !(slug === 'Home' || slug === RouterRoutes.AUTH_SUCCESS.substring(1))) ||
            (!isUserLogged && selectedAbility && !isCanWithoutLogin) ||
            (!isUserLogged && isCannotReadPage)
        setAuthorizationStatus(isUnauthorized ? AUTHORIZATION_STATUS.UNAUTHORIZED : AUTHORIZATION_STATUS.AUTHORIZED)
    }, [isUserLogged, selectedAbility, isCannotReadPage, isCanWithoutLogin, isAdmin, slug])

    useEffect(() => {
        if (isAdmin && user && !user?.roles.includes(ADMIN) && !user?.roles.includes(HKO)) {
            window.location.replace(PORTAL_URL)
        }
    }, [isUserLogged, user, isAdmin])

    if (authorizationStatus === AUTHORIZATION_STATUS.UNKNOWN || isLoadingUserPreferences) return <LoadingIndicator fullscreen />
    if (authorizationStatus === AUTHORIZATION_STATUS.UNAUTHORIZED) return <Navigate to={'/'} />
    return element
}

export default ProtectedRoute
