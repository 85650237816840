import { TAuthConfig } from 'react-oauth2-code-pkce'

import { RouterRoutes } from '@isdd/metais-common/navigation/routeNames'

interface AuthConfig {
    clientId: string
    scope: string
    redirectUri?: string
}

export const PRE_LOGIN_PATH = 'preLoginPath'

const baseUrl =
    import.meta.env.VITE_REST_CLIENT_IAM_OIDC_BASE_URL + (import.meta.env.VITE_IAM_OIDC_PATH ? `/${import.meta.env.VITE_IAM_OIDC_PATH}` : '')

export const authConfig: (props: AuthConfig) => TAuthConfig = ({ clientId, scope, redirectUri = RouterRoutes.AUTH_SUCCESS }: AuthConfig) => {
    return {
        clientId,
        scope,
        extraAuthParameters: { response_type: 'code' },
        authorizationEndpoint: baseUrl + '/authorize',
        tokenEndpoint: baseUrl + '/token',
        redirectUri: window.location.protocol + '//' + window.location.host + redirectUri,
        autoLogin: false,
        preLogin: () => {
            window.location.pathname !== '/' &&
                !window.location.search.includes('code') &&
                localStorage.setItem(PRE_LOGIN_PATH, window.location.pathname)
        },
        storage: 'session',
        clearURL: false,
    }
}
