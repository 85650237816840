import { ProfileDarkBlueIcon } from '@isdd/idsk-ui-kit/index'
import { SortType } from '@isdd/idsk-ui-kit/types'
import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { useReadCiList1Hook } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { ProfileIcon } from '@isdd/metais-common/assets/images'
import styles from '@isdd/metais-common/components/navbar/navbar.module.scss'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { UserPreferencesFormNamesEnum, useUserPreferences } from '@isdd/metais-common/contexts/userPreferences/userPreferencesContext'
import { RouteNames } from '@isdd/metais-common/navigation/routeNames'
import { ADMIN, HKO } from '@isdd/metais-common/hooks/permissions/useUserAbility'

const ADMIN_URL = import.meta.env.VITE_PORTAL_URL + import.meta.env.VITE_ADMIN_URL + '/'

type NavProfileProps = {
    isAdmin?: boolean
}

export const NavProfile: React.FC<NavProfileProps> = ({ isAdmin }) => {
    const { t } = useTranslation()

    const {
        state: { user },
    } = useAuth()

    const navigate = useNavigate()

    const handleLogout = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        navigate('/logout')
    }
    const location = useLocation()
    const ciOptionsHook = useReadCiList1Hook()
    const { currentPreferences, updateUserPreferences } = useUserPreferences()

    const showOnlyItemsOfMyOrganization = async () => {
        if (currentPreferences[UserPreferencesFormNamesEnum.MY_PO]) {
            const preferencesData = { ...currentPreferences, [UserPreferencesFormNamesEnum.MY_PO]: '' }
            updateUserPreferences(preferencesData)
            return
        }
        const userPoUuids = user?.groupData.map((po) => po.orgId) ?? []
        const ciResponse = await ciOptionsHook({
            page: 1,
            perpage: 2,
            sortBy: 'Gen_Profil_nazov',
            sortType: SortType.ASC,
            filter: { type: ['PO'], uuid: [...userPoUuids] },
        })
        let myPoUuid = ''
        if (ciResponse.configurationItemSet?.length === 1) {
            myPoUuid = ciResponse.configurationItemSet[0]?.uuid ?? ''
        } else if (ciResponse.configurationItemSet?.length === 2) {
            //find first one which is not 'Garant eGovernmentu'
            myPoUuid =
                (ciResponse.configurationItemSet[0]?.attributes as { [key: string]: string })[ATTRIBUTE_NAME.Gen_Profil_nazov] ===
                'Garant eGovernmentu'
                    ? ciResponse.configurationItemSet[1]?.uuid ?? ''
                    : ciResponse.configurationItemSet[0]?.uuid ?? ''
        }
        if (myPoUuid) {
            const preferencesData = { ...currentPreferences, [UserPreferencesFormNamesEnum.MY_PO]: myPoUuid }
            updateUserPreferences(preferencesData)
        }
    }

    return (
        <div
            className={classnames(styles.loginProfile, user ? styles.displayFlex : styles.displayNone, {
                'idsk-header-web__main--login-action--active': user,
                'idsk-header-web__main--login-action': !user,
            })}
        >
            <img
                className="idsk-header-web__main--login-action-profile-img"
                src={currentPreferences.myPO ? ProfileDarkBlueIcon : ProfileIcon}
                alt=""
                onClick={showOnlyItemsOfMyOrganization}
            />
            <div className="idsk-header-web__main--login-action-text">
                {user && <span className="govuk-body-s idsk-header-web__main--login-action-text-user-name">{user.displayName}</span>}
                <div className="govuk-!-margin-bottom-1">
                    <Link
                        onClick={handleLogout}
                        state={{ from: location }}
                        className={classnames(
                            'govuk-link',
                            'idsk-header-web__main--login-action-text-logout',
                            user ? 'idsk-header-web__main--login-logoutbtn--active' : 'idsk-header-web__main--login-logoutbtn',
                        )}
                        to="#"
                    >
                        {t('navbar.logout')}
                    </Link>
                    <span aria-hidden> | </span>
                    {!isAdmin && (user?.roles?.includes(ADMIN) || user?.roles?.includes(HKO)) && (
                        <>
                            <Link to={ADMIN_URL} state={{ from: location }} className={'govuk-link'} target="_blank">
                                {t('navbar.admin')}
                            </Link>
                            <span aria-hidden> | </span>
                        </>
                    )}
                    <Link
                        state={{ from: location }}
                        className={classnames(
                            'govuk-link',
                            'idsk-header-web__main--login-action-text-profile',
                            user ? 'idsk-header-web__main--login-profilebtn--active' : 'idsk-header-web__main--login-profilebtn',
                        )}
                        to={RouteNames.USER_PROFILE}
                    >
                        {t('navbar.profile')}
                    </Link>
                </div>
            </div>
        </div>
    )
}
