//@ts-nocheck
/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * MetaIS CMDB Codelist Repo
 * OpenAPI spec version: latest
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { useCodeListRepoSwaggerClient } from '../hooks/useCodeListRepoSwaggerClient'
export type GetAutoincrementTypesType = (typeof GetAutoincrementTypesType)[keyof typeof GetAutoincrementTypesType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAutoincrementTypesType = {
    NUMERIC: 'NUMERIC',
    ALPHABETIC: 'ALPHABETIC',
} as const

export type GetAutoincrementTypesParams = {
    type?: GetAutoincrementTypesType
}

export type ExistTemporalCodelistItem200 = { [key: string]: unknown }

export type UnlockTemporalCodelistItem200 = { [key: string]: unknown }

export type GetCodelistRequestItemsParams = {
    language: string
    pageNumber: number
    perPage: number
    sortBy?: string
    toDate?: string
    ascending?: boolean
}

export type DownloadInternalCodelistParams = {
    type?: string
}

export type DownloadExternalCodelist200 = { [key: string]: unknown }

export type DownloadInternalCodelistRequestParams = {
    type?: string
}

export type GetCodelistHistoryParams = {
    pageNumber?: number
    perPage?: number
    sortBy?: string
    ascending?: boolean
    action?: string[]
    lastModifiedBy?: string[]
    fromDate?: string
    toDate?: string
}

export type ExistTemporalCodelistHeader200 = { [key: string]: unknown }

export type GetUnlockTemporalCodelistHeader200 = { [key: string]: unknown }

export type GetCodelistHeadersParams = {
    language: string
    pageNumber: number
    perPage: number
    sortBy?: string
    ascending?: boolean
    isBase?: boolean
    isTemporal?: boolean
    toDate?: string
    wfState?: string
    uvs?: string
    avs?: string
    mainGestorPoUuid?: string
    mainGestorGid?: string
    code?: string
    nameFilter?: string
    name?: string
    ciTypeTN?: string
}

export type GetCodelistRequestDetailStateISVSProcessingParams = {
    code: string
}

export type GetFirstNotUsedCode200 = { [key: string]: string }

export type GetNextItemCodeParams = {
    isCodelistRequest?: boolean
}

export type CheckCodelistNowParams = {
    forceUpdate?: boolean
}

export type CheckCodelistParams = {
    forceUpdate?: boolean
}

export type QuickCheckCodelistNowParams = {
    forceUpdate?: boolean
}

export type QuickCheckCodelistParams = {
    forceUpdate?: boolean
}

export type GetCodeListByRefIdParams = {
    refID: string
}

export type UploadCodeListPreviewBody = {
    file: Blob
}

export type UploadCodeListPreviewParams = {
    code: string
}

export type UploadCodeListRequestPreviewBody = {
    file: Blob
}

export type UploadCodeListRequestPreviewParams = {
    code: string
}

export type UploadInternalCodelist200 = { [key: string]: { [key: string]: unknown } }

export type UploadInternalCodelistBody = {
    file: Blob
}

export type UploadInternalCodelistRequest200 = { [key: string]: unknown }

export type UploadInternalCodelistRequestBody = {
    file: Blob
}

export type VerifyInternalCodelistBody = {
    file: Blob
}

export type VerifyInternalCodelistRequestBody = {
    file: Blob
}

export type CreateNewCodelistItemLangExtended200 = { [key: string]: unknown }

export type CreateCodelistItem200 = { [key: string]: unknown }

export type GetCodelistItemsParams = {
    language: string
    pageNumber: number
    perPage: number
    sortBy?: string
    state?: string
    effective?: boolean
    toDate?: string
    ascending?: boolean
}

export type ConnectCodelistWithEntity200 = { [key: string]: unknown }

export type CreateCodelistRequest200 = { [key: string]: unknown }

export type GetCodelistRequestsParams = {
    language: string
    pageNumber: number
    perPage: number
    sortBy?: string
    ascending?: boolean
    isBase?: boolean
    wfState?: string
    uvs?: string
    avs?: string
    mainGestorGid?: string
    code?: string
    nameFilter?: string
    name?: string
}

export type SaveAndSendCodelist200 = { [key: string]: unknown }

export type ExistsCodelist200 = (typeof ExistsCodelist200)[keyof typeof ExistsCodelist200]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExistsCodelist200 = {
    '100_CONTINUE': '100 CONTINUE',
    '101_SWITCHING_PROTOCOLS': '101 SWITCHING_PROTOCOLS',
    '102_PROCESSING': '102 PROCESSING',
    '103_EARLY_HINTS': '103 EARLY_HINTS',
    '103_CHECKPOINT': '103 CHECKPOINT',
    '200_OK': '200 OK',
    '201_CREATED': '201 CREATED',
    '202_ACCEPTED': '202 ACCEPTED',
    '203_NON_AUTHORITATIVE_INFORMATION': '203 NON_AUTHORITATIVE_INFORMATION',
    '204_NO_CONTENT': '204 NO_CONTENT',
    '205_RESET_CONTENT': '205 RESET_CONTENT',
    '206_PARTIAL_CONTENT': '206 PARTIAL_CONTENT',
    '207_MULTI_STATUS': '207 MULTI_STATUS',
    '208_ALREADY_REPORTED': '208 ALREADY_REPORTED',
    '226_IM_USED': '226 IM_USED',
    '300_MULTIPLE_CHOICES': '300 MULTIPLE_CHOICES',
    '301_MOVED_PERMANENTLY': '301 MOVED_PERMANENTLY',
    '302_FOUND': '302 FOUND',
    '302_MOVED_TEMPORARILY': '302 MOVED_TEMPORARILY',
    '303_SEE_OTHER': '303 SEE_OTHER',
    '304_NOT_MODIFIED': '304 NOT_MODIFIED',
    '305_USE_PROXY': '305 USE_PROXY',
    '307_TEMPORARY_REDIRECT': '307 TEMPORARY_REDIRECT',
    '308_PERMANENT_REDIRECT': '308 PERMANENT_REDIRECT',
    '400_BAD_REQUEST': '400 BAD_REQUEST',
    '401_UNAUTHORIZED': '401 UNAUTHORIZED',
    '402_PAYMENT_REQUIRED': '402 PAYMENT_REQUIRED',
    '403_FORBIDDEN': '403 FORBIDDEN',
    '404_NOT_FOUND': '404 NOT_FOUND',
    '405_METHOD_NOT_ALLOWED': '405 METHOD_NOT_ALLOWED',
    '406_NOT_ACCEPTABLE': '406 NOT_ACCEPTABLE',
    '407_PROXY_AUTHENTICATION_REQUIRED': '407 PROXY_AUTHENTICATION_REQUIRED',
    '408_REQUEST_TIMEOUT': '408 REQUEST_TIMEOUT',
    '409_CONFLICT': '409 CONFLICT',
    '410_GONE': '410 GONE',
    '411_LENGTH_REQUIRED': '411 LENGTH_REQUIRED',
    '412_PRECONDITION_FAILED': '412 PRECONDITION_FAILED',
    '413_PAYLOAD_TOO_LARGE': '413 PAYLOAD_TOO_LARGE',
    '413_REQUEST_ENTITY_TOO_LARGE': '413 REQUEST_ENTITY_TOO_LARGE',
    '414_URI_TOO_LONG': '414 URI_TOO_LONG',
    '414_REQUEST_URI_TOO_LONG': '414 REQUEST_URI_TOO_LONG',
    '415_UNSUPPORTED_MEDIA_TYPE': '415 UNSUPPORTED_MEDIA_TYPE',
    '416_REQUESTED_RANGE_NOT_SATISFIABLE': '416 REQUESTED_RANGE_NOT_SATISFIABLE',
    '417_EXPECTATION_FAILED': '417 EXPECTATION_FAILED',
    '418_I_AM_A_TEAPOT': '418 I_AM_A_TEAPOT',
    '419_INSUFFICIENT_SPACE_ON_RESOURCE': '419 INSUFFICIENT_SPACE_ON_RESOURCE',
    '420_METHOD_FAILURE': '420 METHOD_FAILURE',
    '421_DESTINATION_LOCKED': '421 DESTINATION_LOCKED',
    '422_UNPROCESSABLE_ENTITY': '422 UNPROCESSABLE_ENTITY',
    '423_LOCKED': '423 LOCKED',
    '424_FAILED_DEPENDENCY': '424 FAILED_DEPENDENCY',
    '425_TOO_EARLY': '425 TOO_EARLY',
    '426_UPGRADE_REQUIRED': '426 UPGRADE_REQUIRED',
    '428_PRECONDITION_REQUIRED': '428 PRECONDITION_REQUIRED',
    '429_TOO_MANY_REQUESTS': '429 TOO_MANY_REQUESTS',
    '431_REQUEST_HEADER_FIELDS_TOO_LARGE': '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
    '451_UNAVAILABLE_FOR_LEGAL_REASONS': '451 UNAVAILABLE_FOR_LEGAL_REASONS',
    '500_INTERNAL_SERVER_ERROR': '500 INTERNAL_SERVER_ERROR',
    '501_NOT_IMPLEMENTED': '501 NOT_IMPLEMENTED',
    '502_BAD_GATEWAY': '502 BAD_GATEWAY',
    '503_SERVICE_UNAVAILABLE': '503 SERVICE_UNAVAILABLE',
    '504_GATEWAY_TIMEOUT': '504 GATEWAY_TIMEOUT',
    '505_HTTP_VERSION_NOT_SUPPORTED': '505 HTTP_VERSION_NOT_SUPPORTED',
    '506_VARIANT_ALSO_NEGOTIATES': '506 VARIANT_ALSO_NEGOTIATES',
    '507_INSUFFICIENT_STORAGE': '507 INSUFFICIENT_STORAGE',
    '508_LOOP_DETECTED': '508 LOOP_DETECTED',
    '509_BANDWIDTH_LIMIT_EXCEEDED': '509 BANDWIDTH_LIMIT_EXCEEDED',
    '510_NOT_EXTENDED': '510 NOT_EXTENDED',
    '511_NETWORK_AUTHENTICATION_REQUIRED': '511 NETWORK_AUTHENTICATION_REQUIRED',
} as const

export type CreateCodelistCi200 = { [key: string]: unknown }

export type RepairCodelistParams = {
    forceUpdate?: boolean
    quickCheck?: boolean
}

export type ProcessHeaderAction200 = { [key: string]: unknown }

export type ProcessHeaderActionParams = {
    action: string
}

export type ProcessAllItemsAction200 = { [key: string]: unknown }

export type ProcessAllItemsActionParams = {
    action: string
}

export type ProcessItemAction200 = { [key: string]: unknown }

export type ProcessItemActionParams = {
    action: string
    validFrom?: string
    effectiveFrom?: string
}

export type UpdateCodelistItemLangExtended200 = { [key: string]: unknown }

export type DeleteTemporalCodelistItem200 = { [key: string]: unknown }

export type UpdateAndUnlockTemporalCodelistItem200 = { [key: string]: unknown }

export type ProcessItemRequestAction1200 = { [key: string]: unknown }

export type ProcessItemRequestAction1Params = {
    fromIndex: number
    toIndex: number
}

export type ProcessItemRequestAction200 = { [key: string]: unknown }

export type ProcessItemRequestActionParams = {
    action: string
    validFrom?: string
    effectiveFrom?: string
}

export type UpdateCodelistContactData200 = { [key: string]: unknown }

export type ProcessKsHeaderAction200 = { [key: string]: unknown }

export type ProcessKsHeaderActionParams = {
    action: string
}

export type CreateCodelistLanguageVersion200 = { [key: string]: unknown }

export type UpdateCodelistLanguageVersion200 = { [key: string]: unknown }

export type DeleteTemporalCodelistHeader200 = { [key: string]: unknown }

export type UpdateAndUnlockTemporalCodelistHeader200 = { [key: string]: unknown }

export type SetIndividuums200 = { [key: string]: unknown }

export type ProcessRequestAction200 = { [key: string]: unknown }

export type ProcessRequestActionParams = {
    action: string
}

export type ProcessRequestKsAction200 = { [key: string]: unknown }

export type ProcessRequestKsActionParams = {
    action: string
}

export type SaveDates200 = { [key: string]: unknown }

export type SaveDatesParams = {
    validFrom?: string
    effectiveFrom?: string
}

export type ApiAutoincrementTypeType = (typeof ApiAutoincrementTypeType)[keyof typeof ApiAutoincrementTypeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiAutoincrementTypeType = {
    NUMERIC: 'NUMERIC',
    ALPHABETIC: 'ALPHABETIC',
} as const

export interface ApiAutoincrementType {
    language?: string
    type?: ApiAutoincrementTypeType
    value?: string
}

export interface ApiCodelistGraphData {
    code?: string
    graphUuid?: string
    id?: number
    request?: boolean
}

export interface MetaAttributesUi {
    createdAt?: string
    createdBy?: string
    lastModifiedAt?: string
    lastModifiedBy?: string
    owner?: string
    state?: string
}

export type AttributeUiValue = { [key: string]: unknown }

export interface AttributeUi {
    name?: string
    value?: AttributeUiValue
}

export interface ConfigurationItemUi {
    attributes?: AttributeUi[]
    metaAttributes?: MetaAttributesUi
    owner?: string
    type?: string
    uuid?: string
}

export type AsyncTaskDtoState = (typeof AsyncTaskDtoState)[keyof typeof AsyncTaskDtoState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AsyncTaskDtoState = {
    CREATED: 'CREATED',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    CANCELLED: 'CANCELLED',
    FAILED: 'FAILED',
} as const

export interface AsyncTaskDto {
    exception?: string
    finishedAt?: string
    processedItems?: number
    startAt?: string
    state?: AsyncTaskDtoState
    uuid?: string
}

export type CodelistRepairStatusSyncStatus = (typeof CodelistRepairStatusSyncStatus)[keyof typeof CodelistRepairStatusSyncStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CodelistRepairStatusSyncStatus = {
    SYNCHRONIZED: 'SYNCHRONIZED',
    NOT_SYNCHRONIZED: 'NOT_SYNCHRONIZED',
    ITEMS_NOT_SYNCHRONIZED_TO_CMDB: 'ITEMS_NOT_SYNCHRONIZED_TO_CMDB',
    ZC_MISSING_IN_CMDB: 'ZC_MISSING_IN_CMDB',
    ZC_MISSING_IN_CODELIST_DB: 'ZC_MISSING_IN_CODELIST_DB',
} as const

export interface CodelistRepairItem {
    cmdbId?: string
    cmdbState?: string
    code?: string
    dbId?: number
    description?: string
    descriptionEng?: string
    effectiveFrom?: string
    effectiveTo?: string
    name?: string
    nameEng?: string
    note?: string
    temporal?: boolean
    wfState?: string
}

export interface CodelistRepairInfo {
    basic?: boolean
    cmdbId?: string
    cmdbState?: string
    cmdbTechnicalName?: string
    code?: string
    dbId?: number
    effectiveFrom?: string
    effectiveTo?: string
    itemsCount?: number
    lastModifiedAt?: string
    mainGestor?: string
    name?: string
    nameEng?: string
    note?: string
    subGestors?: string[]
    temporal?: boolean
    wfState?: string
}

export type CodelistRepairDiffResolveStatus = (typeof CodelistRepairDiffResolveStatus)[keyof typeof CodelistRepairDiffResolveStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CodelistRepairDiffResolveStatus = {
    RESOLVED: 'RESOLVED',
    UNRESOLVED: 'UNRESOLVED',
} as const

export type CodelistRepairDiffResolveAction = (typeof CodelistRepairDiffResolveAction)[keyof typeof CodelistRepairDiffResolveAction]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CodelistRepairDiffResolveAction = {
    CMDB_UPDATE: 'CMDB_UPDATE',
    CMDB_CREATE: 'CMDB_CREATE',
    CMDB_INVALIDATE: 'CMDB_INVALIDATE',
    CMDB_VALIDATE_AND_UPDATE: 'CMDB_VALIDATE_AND_UPDATE',
    UNKNOWN: 'UNKNOWN',
    NONE: 'NONE',
    IGNORE: 'IGNORE',
} as const

export interface CodelistRepairDiff {
    cmdbDatabase?: CodelistRepairInfo
    codelistDatabase?: CodelistRepairInfo
    matches?: boolean
    resolvable?: boolean
    resolveAction?: CodelistRepairDiffResolveAction
    resolveMessage?: string
    resolveStatus?: CodelistRepairDiffResolveStatus
}

export type CodelistItemRepairDiffResolveStatus = (typeof CodelistItemRepairDiffResolveStatus)[keyof typeof CodelistItemRepairDiffResolveStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CodelistItemRepairDiffResolveStatus = {
    RESOLVED: 'RESOLVED',
    UNRESOLVED: 'UNRESOLVED',
} as const

export type CodelistItemRepairDiffResolveAction = (typeof CodelistItemRepairDiffResolveAction)[keyof typeof CodelistItemRepairDiffResolveAction]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CodelistItemRepairDiffResolveAction = {
    CMDB_UPDATE: 'CMDB_UPDATE',
    CMDB_CREATE: 'CMDB_CREATE',
    CMDB_INVALIDATE: 'CMDB_INVALIDATE',
    CMDB_VALIDATE_AND_UPDATE: 'CMDB_VALIDATE_AND_UPDATE',
    UNKNOWN: 'UNKNOWN',
    NONE: 'NONE',
    IGNORE: 'IGNORE',
} as const

export interface CodelistItemRepairDiff {
    cmdbDatabase?: CodelistRepairItem
    codelistDatabase?: CodelistRepairItem
    matches?: boolean
    resolvable?: boolean
    resolveAction?: CodelistItemRepairDiffResolveAction
    resolveMessage?: string
    resolveStatus?: CodelistItemRepairDiffResolveStatus
}

export interface CodelistRepairStatus {
    cmdbRequestIds?: string[]
    codelistDifferences?: CodelistRepairDiff
    itemDifferences?: CodelistItemRepairDiff[]
    syncStatus?: CodelistRepairStatusSyncStatus
}

export type DiffResultApiCodelistPreviewStatus = (typeof DiffResultApiCodelistPreviewStatus)[keyof typeof DiffResultApiCodelistPreviewStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffResultApiCodelistPreviewStatus = {
    SAME: 'SAME',
    ADDED: 'ADDED',
    UPDATED: 'UPDATED',
    DELETED: 'DELETED',
} as const

export interface DiffResultApiCodelistPreview {
    first?: ApiCodelistPreview
    second?: ApiCodelistPreview
    status?: DiffResultApiCodelistPreviewStatus
}

export type DiffResultApiCodelistItemStatus = (typeof DiffResultApiCodelistItemStatus)[keyof typeof DiffResultApiCodelistItemStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiffResultApiCodelistItemStatus = {
    SAME: 'SAME',
    ADDED: 'ADDED',
    UPDATED: 'UPDATED',
    DELETED: 'DELETED',
} as const

export interface DiffResultApiCodelistItem {
    first?: ApiCodelistItem
    second?: ApiCodelistItem
    status?: DiffResultApiCodelistItemStatus
}

export interface DiffApiCodelist {
    codelistItems?: DiffResultApiCodelistItem[]
    codelistPreview?: DiffResultApiCodelistPreview
}

export interface MultiValueMapObjectObject {
    empty?: boolean
    [key: string]: { [key: string]: unknown }
}

export interface ApiCreateCodelistCiRequest {
    color?: string
    description?: string
    descriptionEng?: string
    labels?: string[]
    metaIsCodePrefix: string
    name: string
    nameEng: string
    roles: string[]
    technicalName: string
    uriPrefix: string
}

export interface CodelistItemsDates {
    effectiveFrom?: string
    itemCodes?: string[]
    validFrom?: string
}

export interface ApiCodelistContactData {
    code?: string
    contactFirstName?: string
    contactMail?: string
    contactPhone?: string
    contactSurname?: string
}

export interface ApiCodelistName {
    effectiveFrom?: string
    effectiveTo?: string
    id?: number
    language?: string
    value?: string
}

export interface ApiCodelistLanguageVersion {
    codelistNames?: ApiCodelistName[]
    codelistNotes?: ApiCodelistNote[]
    language?: string
}

export interface ApiCodelistManager {
    effectiveFrom?: string
    effectiveTo?: string
    id?: number
    value?: string
}

export interface ApiCodelistPreview {
    apiCodelistItemList?: ApiCodelistItemList
    autoincrement?: ApiCodelistAutoincrement
    avs?: string
    base?: boolean
    changeType?: string
    cmuDataElement?: string
    code?: string
    codelistManagers?: ApiCodelistManager[]
    codelistNames?: ApiCodelistName[]
    codelistNotes?: ApiCodelistNote[]
    codelistSource?: string[]
    codelistState?: string
    comment?: string
    commentDate?: string
    contactFirstName?: string
    contactMail?: string
    contactPhone?: string
    contactSurname?: string
    effectiveFrom?: string
    effectiveTo?: string
    fromDate?: string
    graphUuid?: string
    histories?: ApiCodelistHistoriesList
    id?: number
    locked?: boolean
    lockedBy?: string
    lockedFrom?: string
    mainCodelistManagers?: ApiCodelistManager[]
    prefix?: string
    resortCode?: string
    temporal?: boolean
    toDate?: string
    uri?: string
    uriOfDataElement?: string
    uriOfUriTemplate?: string
    uvs?: string
    validFrom?: string
}

export interface ApiCodelistPreviewList {
    codelists?: ApiCodelistPreview[]
    codelistsCount?: number
}

export interface ApiCodelistItemValidity {
    effectiveFrom?: string
    effectiveFromValue?: string
    effectiveTo?: string
    effectiveToValue?: string
    id?: number
}

export interface ApiCodelistItemUnitOfMeasure {
    effectiveFrom?: string
    effectiveTo?: string
    id?: number
    value?: string
}

export interface ApiCodelistItemShortenedName {
    effectiveFrom?: string
    effectiveTo?: string
    id?: number
    language?: string
    value?: string
}

export interface ApiCodelistItemNote {
    id?: number
    language?: string
    value?: string
}

export interface ApiCodelistItemName {
    effectiveFrom?: string
    effectiveTo?: string
    id?: number
    language?: string
    value?: string
}

export interface ApiCodelistItemLogicalOrder {
    id?: number
    language?: string
    value?: string
}

export interface ApiCodelistItemList {
    codelistsItemCount?: number
    codelistsItems?: ApiCodelistItem[]
}

export interface ApiCodelistItemLegislativeValidity {
    effectiveFrom?: string
    effectiveTo?: string
    id?: number
    validityValue?: boolean
    value?: boolean
}

export interface ApiCodelistItemIncludesAlso {
    effectiveFrom?: string
    effectiveTo?: string
    id?: number
    language?: string
    value?: string
}

export interface ApiCodelistItemInclude {
    effectiveFrom?: string
    effectiveTo?: string
    id?: number
    language?: string
    value?: string
}

export interface ApiCodelistItemExclude {
    effectiveFrom?: string
    effectiveTo?: string
    id?: number
    language?: string
    value?: string
}

export interface ApiCodelistItemAdditionalContent {
    effectiveFrom?: string
    effectiveTo?: string
    id?: number
    language?: string
    value?: string
}

export interface ApiCodelistItemAbbreviatedName {
    effectiveFrom?: string
    effectiveTo?: string
    id?: number
    language?: string
    value?: string
}

export interface ApiCodelistItem {
    codelistItemAbbreviatedNames?: ApiCodelistItemAbbreviatedName[]
    codelistItemAdditionalContents?: ApiCodelistItemAdditionalContent[]
    codelistItemExcludes?: ApiCodelistItemExclude[]
    codelistItemIncludes?: ApiCodelistItemInclude[]
    codelistItemIncludesAlso?: ApiCodelistItemIncludesAlso[]
    codelistItemLegislativeValidities?: ApiCodelistItemLegislativeValidity[]
    codelistItemLogicalOrders?: ApiCodelistItemLogicalOrder[]
    codelistItemNames?: ApiCodelistItemName[]
    codelistItemNotes?: ApiCodelistItemNote[]
    codelistItemShortenedNames?: ApiCodelistItemShortenedName[]
    codelistItemState?: string
    codelistItemUnitsOfMeasure?: ApiCodelistItemUnitOfMeasure[]
    codelistItemValidities?: ApiCodelistItemValidity[]
    id?: number
    itemCode?: string
    itemUri?: string
    locked?: boolean
    lockedBy?: string
    lockedFrom?: string
    published?: boolean
    temporal?: boolean
    validFrom?: string
}

export interface ApiCodelistHistory {
    action?: string
    changedAt?: string
    changedBy?: string
}

export interface ApiCodelistHistoriesList {
    codelistHistories?: ApiCodelistHistory[]
    codelistHistoriesCount?: number
    codelistHistriesCount?: number
}

export type ApiCodelistAutoincrementType = (typeof ApiCodelistAutoincrementType)[keyof typeof ApiCodelistAutoincrementType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiCodelistAutoincrementType = {
    NUMERIC: 'NUMERIC',
    ALPHABETIC: 'ALPHABETIC',
} as const

export interface ApiCodelistAutoincrement {
    charCount?: number
    type?: ApiCodelistAutoincrementType
    valid?: boolean
}

export interface ApiCodelistItemUriTemplate {
    uri?: string
}

export interface ApiAction {
    comment?: string
    commentDate?: string
}

export interface ApiCodelistNote {
    id?: number
    language?: string
    value?: string
}

export type ApiErrorData = { [key: string]: unknown }

export interface ApiError {
    data?: ApiErrorData
    logToken?: string
    message?: string
    type?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useSaveDatesHook = () => {
    const saveDates = useCodeListRepoSwaggerClient<SaveDates200>()

    return useCallback(
        (code: string, apiCodelistNote: ApiCodelistNote[], params?: SaveDatesParams) => {
            return saveDates({
                url: `/codelists/codelistrequests/${code}/savedates`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiCodelistNote,
                params,
            })
        },
        [saveDates],
    )
}

export const useSaveDatesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSaveDatesHook>>>,
        TError,
        { code: string; data: ApiCodelistNote[]; params?: SaveDatesParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSaveDatesHook>>>,
    TError,
    { code: string; data: ApiCodelistNote[]; params?: SaveDatesParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const saveDates = useSaveDatesHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useSaveDatesHook>>>,
        { code: string; data: ApiCodelistNote[]; params?: SaveDatesParams }
    > = (props) => {
        const { code, data, params } = props ?? {}

        return saveDates(code, data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type SaveDatesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSaveDatesHook>>>>
export type SaveDatesMutationBody = ApiCodelistNote[]
export type SaveDatesMutationError = ApiError

export const useSaveDates = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSaveDatesHook>>>,
        TError,
        { code: string; data: ApiCodelistNote[]; params?: SaveDatesParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useSaveDatesHook>>>,
    TError,
    { code: string; data: ApiCodelistNote[]; params?: SaveDatesParams },
    TContext
> => {
    const mutationOptions = useSaveDatesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useProcessRequestKsActionHook = () => {
    const processRequestKsAction = useCodeListRepoSwaggerClient<ProcessRequestKsAction200>()

    return useCallback(
        (code: string, apiAction: ApiAction, params: ProcessRequestKsActionParams) => {
            return processRequestKsAction({
                url: `/codelists/codelistrequests/${code}/ksactions`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiAction,
                params,
            })
        },
        [processRequestKsAction],
    )
}

export const useProcessRequestKsActionMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessRequestKsActionHook>>>,
        TError,
        { code: string; data: ApiAction; params: ProcessRequestKsActionParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProcessRequestKsActionHook>>>,
    TError,
    { code: string; data: ApiAction; params: ProcessRequestKsActionParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const processRequestKsAction = useProcessRequestKsActionHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useProcessRequestKsActionHook>>>,
        { code: string; data: ApiAction; params: ProcessRequestKsActionParams }
    > = (props) => {
        const { code, data, params } = props ?? {}

        return processRequestKsAction(code, data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ProcessRequestKsActionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProcessRequestKsActionHook>>>>
export type ProcessRequestKsActionMutationBody = ApiAction
export type ProcessRequestKsActionMutationError = ApiError

export const useProcessRequestKsAction = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessRequestKsActionHook>>>,
        TError,
        { code: string; data: ApiAction; params: ProcessRequestKsActionParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useProcessRequestKsActionHook>>>,
    TError,
    { code: string; data: ApiAction; params: ProcessRequestKsActionParams },
    TContext
> => {
    const mutationOptions = useProcessRequestKsActionMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useProcessRequestActionHook = () => {
    const processRequestAction = useCodeListRepoSwaggerClient<ProcessRequestAction200>()

    return useCallback(
        (code: string, apiAction: ApiAction, params: ProcessRequestActionParams) => {
            return processRequestAction({
                url: `/codelists/codelistrequests/${code}/actions`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiAction,
                params,
            })
        },
        [processRequestAction],
    )
}

export const useProcessRequestActionMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessRequestActionHook>>>,
        TError,
        { code: string; data: ApiAction; params: ProcessRequestActionParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProcessRequestActionHook>>>,
    TError,
    { code: string; data: ApiAction; params: ProcessRequestActionParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const processRequestAction = useProcessRequestActionHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useProcessRequestActionHook>>>,
        { code: string; data: ApiAction; params: ProcessRequestActionParams }
    > = (props) => {
        const { code, data, params } = props ?? {}

        return processRequestAction(code, data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ProcessRequestActionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProcessRequestActionHook>>>>
export type ProcessRequestActionMutationBody = ApiAction
export type ProcessRequestActionMutationError = ApiError

export const useProcessRequestAction = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessRequestActionHook>>>,
        TError,
        { code: string; data: ApiAction; params: ProcessRequestActionParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useProcessRequestActionHook>>>,
    TError,
    { code: string; data: ApiAction; params: ProcessRequestActionParams },
    TContext
> => {
    const mutationOptions = useProcessRequestActionMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetIndividuumsHook = () => {
    const getIndividuums = useCodeListRepoSwaggerClient<ConfigurationItemUi[]>()

    return useCallback(
        (id: number, signal?: AbortSignal) => {
            return getIndividuums({ url: `/codelists/codelistheaders/${id}/individuums`, method: 'GET', signal })
        },
        [getIndividuums],
    )
}

export const getGetIndividuumsQueryKey = (id: number) => {
    return [`/codelists/codelistheaders/${id}/individuums`] as const
}

export const useGetIndividuumsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetIndividuumsHook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIndividuumsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetIndividuumsQueryKey(id)

    const getIndividuums = useGetIndividuumsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetIndividuumsHook>>>> = ({ signal }) => getIndividuums(id, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetIndividuumsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetIndividuumsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetIndividuumsHook>>>>
export type GetIndividuumsQueryError = ApiError

export function useGetIndividuums<TData = Awaited<ReturnType<ReturnType<typeof useGetIndividuumsHook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIndividuumsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetIndividuumsQueryOptions(id, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useSetIndividuumsHook = () => {
    const setIndividuums = useCodeListRepoSwaggerClient<SetIndividuums200>()

    return useCallback(
        (id: number, apiCodelistItemUriTemplate: ApiCodelistItemUriTemplate) => {
            return setIndividuums({
                url: `/codelists/codelistheaders/${id}/individuums`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiCodelistItemUriTemplate,
            })
        },
        [setIndividuums],
    )
}

export const useSetIndividuumsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSetIndividuumsHook>>>,
        TError,
        { id: number; data: ApiCodelistItemUriTemplate },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetIndividuumsHook>>>,
    TError,
    { id: number; data: ApiCodelistItemUriTemplate },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const setIndividuums = useSetIndividuumsHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useSetIndividuumsHook>>>,
        { id: number; data: ApiCodelistItemUriTemplate }
    > = (props) => {
        const { id, data } = props ?? {}

        return setIndividuums(id, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type SetIndividuumsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetIndividuumsHook>>>>
export type SetIndividuumsMutationBody = ApiCodelistItemUriTemplate
export type SetIndividuumsMutationError = ApiError

export const useSetIndividuums = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSetIndividuumsHook>>>,
        TError,
        { id: number; data: ApiCodelistItemUriTemplate },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useSetIndividuumsHook>>>,
    TError,
    { id: number; data: ApiCodelistItemUriTemplate },
    TContext
> => {
    const mutationOptions = useSetIndividuumsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetTemporalCodelistHeaderHook = () => {
    const getTemporalCodelistHeader = useCodeListRepoSwaggerClient<ApiCodelistPreview>()

    return useCallback(
        (code: string, signal?: AbortSignal) => {
            return getTemporalCodelistHeader({ url: `/codelists/codelistheaders/${code}/temporal`, method: 'GET', signal })
        },
        [getTemporalCodelistHeader],
    )
}

export const getGetTemporalCodelistHeaderQueryKey = (code: string) => {
    return [`/codelists/codelistheaders/${code}/temporal`] as const
}

export const useGetTemporalCodelistHeaderQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistHeaderHook>>>,
    TError = ApiError,
>(
    code: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistHeaderHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTemporalCodelistHeaderQueryKey(code)

    const getTemporalCodelistHeader = useGetTemporalCodelistHeaderHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistHeaderHook>>>> = ({ signal }) =>
        getTemporalCodelistHeader(code, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistHeaderHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetTemporalCodelistHeaderQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistHeaderHook>>>>
export type GetTemporalCodelistHeaderQueryError = ApiError

export function useGetTemporalCodelistHeader<TData = Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistHeaderHook>>>, TError = ApiError>(
    code: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistHeaderHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetTemporalCodelistHeaderQueryOptions(code, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateAndUnlockTemporalCodelistHeaderHook = () => {
    const updateAndUnlockTemporalCodelistHeader = useCodeListRepoSwaggerClient<UpdateAndUnlockTemporalCodelistHeader200>()

    return useCallback(
        (code: string, apiCodelistPreview: ApiCodelistPreview) => {
            return updateAndUnlockTemporalCodelistHeader({
                url: `/codelists/codelistheaders/${code}/temporal`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiCodelistPreview,
            })
        },
        [updateAndUnlockTemporalCodelistHeader],
    )
}

export const useUpdateAndUnlockTemporalCodelistHeaderMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateAndUnlockTemporalCodelistHeaderHook>>>,
        TError,
        { code: string; data: ApiCodelistPreview },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateAndUnlockTemporalCodelistHeaderHook>>>,
    TError,
    { code: string; data: ApiCodelistPreview },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateAndUnlockTemporalCodelistHeader = useUpdateAndUnlockTemporalCodelistHeaderHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateAndUnlockTemporalCodelistHeaderHook>>>,
        { code: string; data: ApiCodelistPreview }
    > = (props) => {
        const { code, data } = props ?? {}

        return updateAndUnlockTemporalCodelistHeader(code, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateAndUnlockTemporalCodelistHeaderMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useUpdateAndUnlockTemporalCodelistHeaderHook>>>
>
export type UpdateAndUnlockTemporalCodelistHeaderMutationBody = ApiCodelistPreview
export type UpdateAndUnlockTemporalCodelistHeaderMutationError = ApiError

export const useUpdateAndUnlockTemporalCodelistHeader = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateAndUnlockTemporalCodelistHeaderHook>>>,
        TError,
        { code: string; data: ApiCodelistPreview },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateAndUnlockTemporalCodelistHeaderHook>>>,
    TError,
    { code: string; data: ApiCodelistPreview },
    TContext
> => {
    const mutationOptions = useUpdateAndUnlockTemporalCodelistHeaderMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteTemporalCodelistHeaderHook = () => {
    const deleteTemporalCodelistHeader = useCodeListRepoSwaggerClient<DeleteTemporalCodelistHeader200>()

    return useCallback(
        (code: string) => {
            return deleteTemporalCodelistHeader({ url: `/codelists/codelistheaders/${code}/temporal`, method: 'DELETE' })
        },
        [deleteTemporalCodelistHeader],
    )
}

export const useDeleteTemporalCodelistHeaderMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteTemporalCodelistHeaderHook>>>, TError, { code: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteTemporalCodelistHeaderHook>>>, TError, { code: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteTemporalCodelistHeader = useDeleteTemporalCodelistHeaderHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteTemporalCodelistHeaderHook>>>, { code: string }> = (props) => {
        const { code } = props ?? {}

        return deleteTemporalCodelistHeader(code)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteTemporalCodelistHeaderMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteTemporalCodelistHeaderHook>>>>

export type DeleteTemporalCodelistHeaderMutationError = ApiError

export const useDeleteTemporalCodelistHeader = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteTemporalCodelistHeaderHook>>>, TError, { code: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useDeleteTemporalCodelistHeaderHook>>>, TError, { code: string }, TContext> => {
    const mutationOptions = useDeleteTemporalCodelistHeaderMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateCodelistLanguageVersionHook = () => {
    const updateCodelistLanguageVersion = useCodeListRepoSwaggerClient<UpdateCodelistLanguageVersion200>()

    return useCallback(
        (code: string, apiCodelistLanguageVersion: ApiCodelistLanguageVersion) => {
            return updateCodelistLanguageVersion({
                url: `/codelists/codelistheaders/${code}/languageversions`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiCodelistLanguageVersion,
            })
        },
        [updateCodelistLanguageVersion],
    )
}

export const useUpdateCodelistLanguageVersionMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateCodelistLanguageVersionHook>>>,
        TError,
        { code: string; data: ApiCodelistLanguageVersion },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateCodelistLanguageVersionHook>>>,
    TError,
    { code: string; data: ApiCodelistLanguageVersion },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateCodelistLanguageVersion = useUpdateCodelistLanguageVersionHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateCodelistLanguageVersionHook>>>,
        { code: string; data: ApiCodelistLanguageVersion }
    > = (props) => {
        const { code, data } = props ?? {}

        return updateCodelistLanguageVersion(code, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateCodelistLanguageVersionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateCodelistLanguageVersionHook>>>>
export type UpdateCodelistLanguageVersionMutationBody = ApiCodelistLanguageVersion
export type UpdateCodelistLanguageVersionMutationError = ApiError

export const useUpdateCodelistLanguageVersion = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateCodelistLanguageVersionHook>>>,
        TError,
        { code: string; data: ApiCodelistLanguageVersion },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateCodelistLanguageVersionHook>>>,
    TError,
    { code: string; data: ApiCodelistLanguageVersion },
    TContext
> => {
    const mutationOptions = useUpdateCodelistLanguageVersionMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCreateCodelistLanguageVersionHook = () => {
    const createCodelistLanguageVersion = useCodeListRepoSwaggerClient<CreateCodelistLanguageVersion200>()

    return useCallback(
        (code: string, apiCodelistLanguageVersion: ApiCodelistLanguageVersion) => {
            return createCodelistLanguageVersion({
                url: `/codelists/codelistheaders/${code}/languageversions`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiCodelistLanguageVersion,
            })
        },
        [createCodelistLanguageVersion],
    )
}

export const useCreateCodelistLanguageVersionMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateCodelistLanguageVersionHook>>>,
        TError,
        { code: string; data: ApiCodelistLanguageVersion },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateCodelistLanguageVersionHook>>>,
    TError,
    { code: string; data: ApiCodelistLanguageVersion },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const createCodelistLanguageVersion = useCreateCodelistLanguageVersionHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCreateCodelistLanguageVersionHook>>>,
        { code: string; data: ApiCodelistLanguageVersion }
    > = (props) => {
        const { code, data } = props ?? {}

        return createCodelistLanguageVersion(code, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateCodelistLanguageVersionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateCodelistLanguageVersionHook>>>>
export type CreateCodelistLanguageVersionMutationBody = ApiCodelistLanguageVersion
export type CreateCodelistLanguageVersionMutationError = ApiError

export const useCreateCodelistLanguageVersion = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateCodelistLanguageVersionHook>>>,
        TError,
        { code: string; data: ApiCodelistLanguageVersion },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useCreateCodelistLanguageVersionHook>>>,
    TError,
    { code: string; data: ApiCodelistLanguageVersion },
    TContext
> => {
    const mutationOptions = useCreateCodelistLanguageVersionMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useProcessKsHeaderActionHook = () => {
    const processKsHeaderAction = useCodeListRepoSwaggerClient<ProcessKsHeaderAction200>()

    return useCallback(
        (code: string, apiAction: ApiAction, params: ProcessKsHeaderActionParams) => {
            return processKsHeaderAction({
                url: `/codelists/codelistheaders/${code}/ksactions`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiAction,
                params,
            })
        },
        [processKsHeaderAction],
    )
}

export const useProcessKsHeaderActionMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessKsHeaderActionHook>>>,
        TError,
        { code: string; data: ApiAction; params: ProcessKsHeaderActionParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProcessKsHeaderActionHook>>>,
    TError,
    { code: string; data: ApiAction; params: ProcessKsHeaderActionParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const processKsHeaderAction = useProcessKsHeaderActionHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useProcessKsHeaderActionHook>>>,
        { code: string; data: ApiAction; params: ProcessKsHeaderActionParams }
    > = (props) => {
        const { code, data, params } = props ?? {}

        return processKsHeaderAction(code, data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ProcessKsHeaderActionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProcessKsHeaderActionHook>>>>
export type ProcessKsHeaderActionMutationBody = ApiAction
export type ProcessKsHeaderActionMutationError = ApiError

export const useProcessKsHeaderAction = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessKsHeaderActionHook>>>,
        TError,
        { code: string; data: ApiAction; params: ProcessKsHeaderActionParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useProcessKsHeaderActionHook>>>,
    TError,
    { code: string; data: ApiAction; params: ProcessKsHeaderActionParams },
    TContext
> => {
    const mutationOptions = useProcessKsHeaderActionMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateCodelistContactDataHook = () => {
    const updateCodelistContactData = useCodeListRepoSwaggerClient<UpdateCodelistContactData200>()

    return useCallback(
        (code: string, apiCodelistContactData: ApiCodelistContactData) => {
            return updateCodelistContactData({
                url: `/codelists/codelistheaders/${code}/contactData`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiCodelistContactData,
            })
        },
        [updateCodelistContactData],
    )
}

export const useUpdateCodelistContactDataMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateCodelistContactDataHook>>>,
        TError,
        { code: string; data: ApiCodelistContactData },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateCodelistContactDataHook>>>,
    TError,
    { code: string; data: ApiCodelistContactData },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateCodelistContactData = useUpdateCodelistContactDataHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateCodelistContactDataHook>>>,
        { code: string; data: ApiCodelistContactData }
    > = (props) => {
        const { code, data } = props ?? {}

        return updateCodelistContactData(code, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateCodelistContactDataMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateCodelistContactDataHook>>>>
export type UpdateCodelistContactDataMutationBody = ApiCodelistContactData
export type UpdateCodelistContactDataMutationError = ApiError

export const useUpdateCodelistContactData = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateCodelistContactDataHook>>>,
        TError,
        { code: string; data: ApiCodelistContactData },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateCodelistContactDataHook>>>,
    TError,
    { code: string; data: ApiCodelistContactData },
    TContext
> => {
    const mutationOptions = useUpdateCodelistContactDataMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useProcessItemRequestActionHook = () => {
    const processItemRequestAction = useCodeListRepoSwaggerClient<ProcessItemRequestAction200>()

    return useCallback(
        (code: string, itemCode: string, params: ProcessItemRequestActionParams) => {
            return processItemRequestAction({
                url: `/codelists/codelistheaders/${code}/codelistrequestitems/${itemCode}/actions`,
                method: 'PUT',
                params,
            })
        },
        [processItemRequestAction],
    )
}

export const useProcessItemRequestActionMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessItemRequestActionHook>>>,
        TError,
        { code: string; itemCode: string; params: ProcessItemRequestActionParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProcessItemRequestActionHook>>>,
    TError,
    { code: string; itemCode: string; params: ProcessItemRequestActionParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const processItemRequestAction = useProcessItemRequestActionHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useProcessItemRequestActionHook>>>,
        { code: string; itemCode: string; params: ProcessItemRequestActionParams }
    > = (props) => {
        const { code, itemCode, params } = props ?? {}

        return processItemRequestAction(code, itemCode, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ProcessItemRequestActionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProcessItemRequestActionHook>>>>

export type ProcessItemRequestActionMutationError = ApiError

export const useProcessItemRequestAction = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessItemRequestActionHook>>>,
        TError,
        { code: string; itemCode: string; params: ProcessItemRequestActionParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useProcessItemRequestActionHook>>>,
    TError,
    { code: string; itemCode: string; params: ProcessItemRequestActionParams },
    TContext
> => {
    const mutationOptions = useProcessItemRequestActionMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useProcessItemRequestAction1Hook = () => {
    const processItemRequestAction1 = useCodeListRepoSwaggerClient<ProcessItemRequestAction1200>()

    return useCallback(
        (code: string, allCodelistItems: boolean, codelistItemsDates: CodelistItemsDates, params: ProcessItemRequestAction1Params) => {
            return processItemRequestAction1({
                url: `/codelists/codelistheaders/${code}/codelistrequestitems/setDates/${allCodelistItems}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: codelistItemsDates,
                params,
            })
        },
        [processItemRequestAction1],
    )
}

export const useProcessItemRequestAction1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessItemRequestAction1Hook>>>,
        TError,
        { code: string; allCodelistItems: boolean; data: CodelistItemsDates; params: ProcessItemRequestAction1Params },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProcessItemRequestAction1Hook>>>,
    TError,
    { code: string; allCodelistItems: boolean; data: CodelistItemsDates; params: ProcessItemRequestAction1Params },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const processItemRequestAction1 = useProcessItemRequestAction1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useProcessItemRequestAction1Hook>>>,
        { code: string; allCodelistItems: boolean; data: CodelistItemsDates; params: ProcessItemRequestAction1Params }
    > = (props) => {
        const { code, allCodelistItems, data, params } = props ?? {}

        return processItemRequestAction1(code, allCodelistItems, data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ProcessItemRequestAction1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProcessItemRequestAction1Hook>>>>
export type ProcessItemRequestAction1MutationBody = CodelistItemsDates
export type ProcessItemRequestAction1MutationError = ApiError

export const useProcessItemRequestAction1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessItemRequestAction1Hook>>>,
        TError,
        { code: string; allCodelistItems: boolean; data: CodelistItemsDates; params: ProcessItemRequestAction1Params },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useProcessItemRequestAction1Hook>>>,
    TError,
    { code: string; allCodelistItems: boolean; data: CodelistItemsDates; params: ProcessItemRequestAction1Params },
    TContext
> => {
    const mutationOptions = useProcessItemRequestAction1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetTemporalCodelistItemHook = () => {
    const getTemporalCodelistItem = useCodeListRepoSwaggerClient<ApiCodelistItem>()

    return useCallback(
        (code: string, itemCode: string, signal?: AbortSignal) => {
            return getTemporalCodelistItem({ url: `/codelists/codelistheaders/${code}/codelistitems/${itemCode}/temporal`, method: 'GET', signal })
        },
        [getTemporalCodelistItem],
    )
}

export const getGetTemporalCodelistItemQueryKey = (code: string, itemCode: string) => {
    return [`/codelists/codelistheaders/${code}/codelistitems/${itemCode}/temporal`] as const
}

export const useGetTemporalCodelistItemQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistItemHook>>>,
    TError = ApiError,
>(
    code: string,
    itemCode: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistItemHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTemporalCodelistItemQueryKey(code, itemCode)

    const getTemporalCodelistItem = useGetTemporalCodelistItemHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistItemHook>>>> = ({ signal }) =>
        getTemporalCodelistItem(code, itemCode, signal)

    return { queryKey, queryFn, enabled: !!(code && itemCode), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistItemHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetTemporalCodelistItemQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistItemHook>>>>
export type GetTemporalCodelistItemQueryError = ApiError

export function useGetTemporalCodelistItem<TData = Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistItemHook>>>, TError = ApiError>(
    code: string,
    itemCode: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistItemHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetTemporalCodelistItemQueryOptions(code, itemCode, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateAndUnlockTemporalCodelistItemHook = () => {
    const updateAndUnlockTemporalCodelistItem = useCodeListRepoSwaggerClient<UpdateAndUnlockTemporalCodelistItem200>()

    return useCallback(
        (code: string, itemCode: string, apiCodelistItem: ApiCodelistItem) => {
            return updateAndUnlockTemporalCodelistItem({
                url: `/codelists/codelistheaders/${code}/codelistitems/${itemCode}/temporal`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiCodelistItem,
            })
        },
        [updateAndUnlockTemporalCodelistItem],
    )
}

export const useUpdateAndUnlockTemporalCodelistItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateAndUnlockTemporalCodelistItemHook>>>,
        TError,
        { code: string; itemCode: string; data: ApiCodelistItem },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateAndUnlockTemporalCodelistItemHook>>>,
    TError,
    { code: string; itemCode: string; data: ApiCodelistItem },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateAndUnlockTemporalCodelistItem = useUpdateAndUnlockTemporalCodelistItemHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateAndUnlockTemporalCodelistItemHook>>>,
        { code: string; itemCode: string; data: ApiCodelistItem }
    > = (props) => {
        const { code, itemCode, data } = props ?? {}

        return updateAndUnlockTemporalCodelistItem(code, itemCode, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateAndUnlockTemporalCodelistItemMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useUpdateAndUnlockTemporalCodelistItemHook>>>
>
export type UpdateAndUnlockTemporalCodelistItemMutationBody = ApiCodelistItem
export type UpdateAndUnlockTemporalCodelistItemMutationError = ApiError

export const useUpdateAndUnlockTemporalCodelistItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateAndUnlockTemporalCodelistItemHook>>>,
        TError,
        { code: string; itemCode: string; data: ApiCodelistItem },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateAndUnlockTemporalCodelistItemHook>>>,
    TError,
    { code: string; itemCode: string; data: ApiCodelistItem },
    TContext
> => {
    const mutationOptions = useUpdateAndUnlockTemporalCodelistItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteTemporalCodelistItemHook = () => {
    const deleteTemporalCodelistItem = useCodeListRepoSwaggerClient<DeleteTemporalCodelistItem200>()

    return useCallback(
        (code: string, itemCode: string) => {
            return deleteTemporalCodelistItem({ url: `/codelists/codelistheaders/${code}/codelistitems/${itemCode}/temporal`, method: 'DELETE' })
        },
        [deleteTemporalCodelistItem],
    )
}

export const useDeleteTemporalCodelistItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteTemporalCodelistItemHook>>>,
        TError,
        { code: string; itemCode: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteTemporalCodelistItemHook>>>,
    TError,
    { code: string; itemCode: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteTemporalCodelistItem = useDeleteTemporalCodelistItemHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteTemporalCodelistItemHook>>>,
        { code: string; itemCode: string }
    > = (props) => {
        const { code, itemCode } = props ?? {}

        return deleteTemporalCodelistItem(code, itemCode)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteTemporalCodelistItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteTemporalCodelistItemHook>>>>

export type DeleteTemporalCodelistItemMutationError = ApiError

export const useDeleteTemporalCodelistItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteTemporalCodelistItemHook>>>,
        TError,
        { code: string; itemCode: string },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useDeleteTemporalCodelistItemHook>>>,
    TError,
    { code: string; itemCode: string },
    TContext
> => {
    const mutationOptions = useDeleteTemporalCodelistItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateCodelistItemLangExtendedHook = () => {
    const updateCodelistItemLangExtended = useCodeListRepoSwaggerClient<UpdateCodelistItemLangExtended200>()

    return useCallback(
        (code: string, itemCode: string, apiCodelistItem: ApiCodelistItem) => {
            return updateCodelistItemLangExtended({
                url: `/codelists/codelistheaders/${code}/codelistitems/${itemCode}/langextended`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: apiCodelistItem,
            })
        },
        [updateCodelistItemLangExtended],
    )
}

export const useUpdateCodelistItemLangExtendedMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateCodelistItemLangExtendedHook>>>,
        TError,
        { code: string; itemCode: string; data: ApiCodelistItem },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateCodelistItemLangExtendedHook>>>,
    TError,
    { code: string; itemCode: string; data: ApiCodelistItem },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateCodelistItemLangExtended = useUpdateCodelistItemLangExtendedHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateCodelistItemLangExtendedHook>>>,
        { code: string; itemCode: string; data: ApiCodelistItem }
    > = (props) => {
        const { code, itemCode, data } = props ?? {}

        return updateCodelistItemLangExtended(code, itemCode, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateCodelistItemLangExtendedMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateCodelistItemLangExtendedHook>>>>
export type UpdateCodelistItemLangExtendedMutationBody = ApiCodelistItem
export type UpdateCodelistItemLangExtendedMutationError = ApiError

export const useUpdateCodelistItemLangExtended = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateCodelistItemLangExtendedHook>>>,
        TError,
        { code: string; itemCode: string; data: ApiCodelistItem },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUpdateCodelistItemLangExtendedHook>>>,
    TError,
    { code: string; itemCode: string; data: ApiCodelistItem },
    TContext
> => {
    const mutationOptions = useUpdateCodelistItemLangExtendedMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useProcessItemActionHook = () => {
    const processItemAction = useCodeListRepoSwaggerClient<ProcessItemAction200>()

    return useCallback(
        (code: string, itemCode: string, params: ProcessItemActionParams) => {
            return processItemAction({ url: `/codelists/codelistheaders/${code}/codelistitems/${itemCode}/actions`, method: 'PUT', params })
        },
        [processItemAction],
    )
}

export const useProcessItemActionMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessItemActionHook>>>,
        TError,
        { code: string; itemCode: string; params: ProcessItemActionParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProcessItemActionHook>>>,
    TError,
    { code: string; itemCode: string; params: ProcessItemActionParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const processItemAction = useProcessItemActionHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useProcessItemActionHook>>>,
        { code: string; itemCode: string; params: ProcessItemActionParams }
    > = (props) => {
        const { code, itemCode, params } = props ?? {}

        return processItemAction(code, itemCode, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ProcessItemActionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProcessItemActionHook>>>>

export type ProcessItemActionMutationError = ApiError

export const useProcessItemAction = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessItemActionHook>>>,
        TError,
        { code: string; itemCode: string; params: ProcessItemActionParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useProcessItemActionHook>>>,
    TError,
    { code: string; itemCode: string; params: ProcessItemActionParams },
    TContext
> => {
    const mutationOptions = useProcessItemActionMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useProcessAllItemsActionHook = () => {
    const processAllItemsAction = useCodeListRepoSwaggerClient<ProcessAllItemsAction200>()

    return useCallback(
        (code: string, params: ProcessAllItemsActionParams) => {
            return processAllItemsAction({ url: `/codelists/codelistheaders/${code}/codelistitems/actions`, method: 'PUT', params })
        },
        [processAllItemsAction],
    )
}

export const useProcessAllItemsActionMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessAllItemsActionHook>>>,
        TError,
        { code: string; params: ProcessAllItemsActionParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProcessAllItemsActionHook>>>,
    TError,
    { code: string; params: ProcessAllItemsActionParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const processAllItemsAction = useProcessAllItemsActionHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useProcessAllItemsActionHook>>>,
        { code: string; params: ProcessAllItemsActionParams }
    > = (props) => {
        const { code, params } = props ?? {}

        return processAllItemsAction(code, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ProcessAllItemsActionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProcessAllItemsActionHook>>>>

export type ProcessAllItemsActionMutationError = ApiError

export const useProcessAllItemsAction = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessAllItemsActionHook>>>,
        TError,
        { code: string; params: ProcessAllItemsActionParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useProcessAllItemsActionHook>>>,
    TError,
    { code: string; params: ProcessAllItemsActionParams },
    TContext
> => {
    const mutationOptions = useProcessAllItemsActionMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useProcessHeaderActionHook = () => {
    const processHeaderAction = useCodeListRepoSwaggerClient<ProcessHeaderAction200>()

    return useCallback(
        (code: string, params: ProcessHeaderActionParams) => {
            return processHeaderAction({ url: `/codelists/codelistheaders/${code}/actions`, method: 'PUT', params })
        },
        [processHeaderAction],
    )
}

export const useProcessHeaderActionMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessHeaderActionHook>>>,
        TError,
        { code: string; params: ProcessHeaderActionParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProcessHeaderActionHook>>>,
    TError,
    { code: string; params: ProcessHeaderActionParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const processHeaderAction = useProcessHeaderActionHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useProcessHeaderActionHook>>>,
        { code: string; params: ProcessHeaderActionParams }
    > = (props) => {
        const { code, params } = props ?? {}

        return processHeaderAction(code, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ProcessHeaderActionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProcessHeaderActionHook>>>>

export type ProcessHeaderActionMutationError = ApiError

export const useProcessHeaderAction = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useProcessHeaderActionHook>>>,
        TError,
        { code: string; params: ProcessHeaderActionParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useProcessHeaderActionHook>>>,
    TError,
    { code: string; params: ProcessHeaderActionParams },
    TContext
> => {
    const mutationOptions = useProcessHeaderActionMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useSynchronizeCodelistsHook = () => {
    const synchronizeCodelists = useCodeListRepoSwaggerClient<string>()

    return useCallback(() => {
        return synchronizeCodelists({ url: `/utils/synchronize-codelists`, method: 'POST' })
    }, [synchronizeCodelists])
}

export const useSynchronizeCodelistsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSynchronizeCodelistsHook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSynchronizeCodelistsHook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const synchronizeCodelists = useSynchronizeCodelistsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSynchronizeCodelistsHook>>>, void> = () => {
        return synchronizeCodelists()
    }

    return { mutationFn, ...mutationOptions }
}

export type SynchronizeCodelistsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSynchronizeCodelistsHook>>>>

export type SynchronizeCodelistsMutationError = ApiError

export const useSynchronizeCodelists = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSynchronizeCodelistsHook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useSynchronizeCodelistsHook>>>, TError, void, TContext> => {
    const mutationOptions = useSynchronizeCodelistsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRepairCodelistHook = () => {
    const repairCodelist = useCodeListRepoSwaggerClient<string>()

    return useCallback(
        (code: string, params?: RepairCodelistParams) => {
            return repairCodelist({ url: `/utils/repair-codelist/${code}`, method: 'POST', params })
        },
        [repairCodelist],
    )
}

export const useRepairCodelistMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRepairCodelistHook>>>,
        TError,
        { code: string; params?: RepairCodelistParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRepairCodelistHook>>>,
    TError,
    { code: string; params?: RepairCodelistParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const repairCodelist = useRepairCodelistHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useRepairCodelistHook>>>,
        { code: string; params?: RepairCodelistParams }
    > = (props) => {
        const { code, params } = props ?? {}

        return repairCodelist(code, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type RepairCodelistMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRepairCodelistHook>>>>

export type RepairCodelistMutationError = ApiError

export const useRepairCodelist = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRepairCodelistHook>>>,
        TError,
        { code: string; params?: RepairCodelistParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useRepairCodelistHook>>>,
    TError,
    { code: string; params?: RepairCodelistParams },
    TContext
> => {
    const mutationOptions = useRepairCodelistMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCheckEmptyCodelistsNowHook = () => {
    const checkEmptyCodelistsNow = useCodeListRepoSwaggerClient<string>()

    return useCallback(() => {
        return checkEmptyCodelistsNow({ url: `/utils/check-empty-codelists-now`, method: 'POST' })
    }, [checkEmptyCodelistsNow])
}

export const useCheckEmptyCodelistsNowMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCheckEmptyCodelistsNowHook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCheckEmptyCodelistsNowHook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const checkEmptyCodelistsNow = useCheckEmptyCodelistsNowHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCheckEmptyCodelistsNowHook>>>, void> = () => {
        return checkEmptyCodelistsNow()
    }

    return { mutationFn, ...mutationOptions }
}

export type CheckEmptyCodelistsNowMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCheckEmptyCodelistsNowHook>>>>

export type CheckEmptyCodelistsNowMutationError = ApiError

export const useCheckEmptyCodelistsNow = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCheckEmptyCodelistsNowHook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useCheckEmptyCodelistsNowHook>>>, TError, void, TContext> => {
    const mutationOptions = useCheckEmptyCodelistsNowMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCheckEmptyCodelistsBeforeHook = () => {
    const checkEmptyCodelistsBefore = useCodeListRepoSwaggerClient<string>()

    return useCallback(() => {
        return checkEmptyCodelistsBefore({ url: `/utils/check-empty-codelists-before`, method: 'POST' })
    }, [checkEmptyCodelistsBefore])
}

export const useCheckEmptyCodelistsBeforeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCheckEmptyCodelistsBeforeHook>>>, TError, void, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCheckEmptyCodelistsBeforeHook>>>, TError, void, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const checkEmptyCodelistsBefore = useCheckEmptyCodelistsBeforeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCheckEmptyCodelistsBeforeHook>>>, void> = () => {
        return checkEmptyCodelistsBefore()
    }

    return { mutationFn, ...mutationOptions }
}

export type CheckEmptyCodelistsBeforeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCheckEmptyCodelistsBeforeHook>>>>

export type CheckEmptyCodelistsBeforeMutationError = ApiError

export const useCheckEmptyCodelistsBefore = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCheckEmptyCodelistsBeforeHook>>>, TError, void, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useCheckEmptyCodelistsBeforeHook>>>, TError, void, TContext> => {
    const mutationOptions = useCheckEmptyCodelistsBeforeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCreateCodelistCiHook = () => {
    const createCodelistCi = useCodeListRepoSwaggerClient<CreateCodelistCi200>()

    return useCallback(
        (code: string, apiCreateCodelistCiRequest: ApiCreateCodelistCiRequest) => {
            return createCodelistCi({
                url: `/codelists/${code}/createci`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiCreateCodelistCiRequest,
            })
        },
        [createCodelistCi],
    )
}

export const useCreateCodelistCiMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateCodelistCiHook>>>,
        TError,
        { code: string; data: ApiCreateCodelistCiRequest },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateCodelistCiHook>>>,
    TError,
    { code: string; data: ApiCreateCodelistCiRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const createCodelistCi = useCreateCodelistCiHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCreateCodelistCiHook>>>,
        { code: string; data: ApiCreateCodelistCiRequest }
    > = (props) => {
        const { code, data } = props ?? {}

        return createCodelistCi(code, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateCodelistCiMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateCodelistCiHook>>>>
export type CreateCodelistCiMutationBody = ApiCreateCodelistCiRequest
export type CreateCodelistCiMutationError = ApiError

export const useCreateCodelistCi = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateCodelistCiHook>>>,
        TError,
        { code: string; data: ApiCreateCodelistCiRequest },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useCreateCodelistCiHook>>>,
    TError,
    { code: string; data: ApiCreateCodelistCiRequest },
    TContext
> => {
    const mutationOptions = useCreateCodelistCiMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary existsCodelist
 */
export const useExistsCodelistHook = () => {
    const existsCodelist = useCodeListRepoSwaggerClient<ExistsCodelist200>()

    return useCallback(
        (apiCodelistPreview: ApiCodelistPreview) => {
            return existsCodelist({
                url: `/codelists/existscodelist`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiCodelistPreview,
            })
        },
        [existsCodelist],
    )
}

export const useExistsCodelistMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useExistsCodelistHook>>>, TError, { data: ApiCodelistPreview }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useExistsCodelistHook>>>, TError, { data: ApiCodelistPreview }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const existsCodelist = useExistsCodelistHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useExistsCodelistHook>>>, { data: ApiCodelistPreview }> = (props) => {
        const { data } = props ?? {}

        return existsCodelist(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ExistsCodelistMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExistsCodelistHook>>>>
export type ExistsCodelistMutationBody = ApiCodelistPreview
export type ExistsCodelistMutationError = ApiError

/**
 * @summary existsCodelist
 */
export const useExistsCodelist = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useExistsCodelistHook>>>, TError, { data: ApiCodelistPreview }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useExistsCodelistHook>>>, TError, { data: ApiCodelistPreview }, TContext> => {
    const mutationOptions = useExistsCodelistMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useSaveAndSendCodelistHook = () => {
    const saveAndSendCodelist = useCodeListRepoSwaggerClient<SaveAndSendCodelist200>()

    return useCallback(
        (apiCodelistPreview: ApiCodelistPreview) => {
            return saveAndSendCodelist({
                url: `/codelists/codelistsaveandsend`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiCodelistPreview,
            })
        },
        [saveAndSendCodelist],
    )
}

export const useSaveAndSendCodelistMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveAndSendCodelistHook>>>, TError, { data: ApiCodelistPreview }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveAndSendCodelistHook>>>, TError, { data: ApiCodelistPreview }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const saveAndSendCodelist = useSaveAndSendCodelistHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSaveAndSendCodelistHook>>>, { data: ApiCodelistPreview }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return saveAndSendCodelist(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type SaveAndSendCodelistMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSaveAndSendCodelistHook>>>>
export type SaveAndSendCodelistMutationBody = ApiCodelistPreview
export type SaveAndSendCodelistMutationError = ApiError

export const useSaveAndSendCodelist = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveAndSendCodelistHook>>>, TError, { data: ApiCodelistPreview }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useSaveAndSendCodelistHook>>>, TError, { data: ApiCodelistPreview }, TContext> => {
    const mutationOptions = useSaveAndSendCodelistMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetCodelistRequestsHook = () => {
    const getCodelistRequests = useCodeListRepoSwaggerClient<ApiCodelistPreviewList>()

    return useCallback(
        (params: GetCodelistRequestsParams, signal?: AbortSignal) => {
            return getCodelistRequests({ url: `/codelists/codelistrequests`, method: 'GET', params, signal })
        },
        [getCodelistRequests],
    )
}

export const getGetCodelistRequestsQueryKey = (params: GetCodelistRequestsParams) => {
    return [`/codelists/codelistrequests`, ...(params ? [params] : [])] as const
}

export const useGetCodelistRequestsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestsHook>>>, TError = ApiError>(
    params: GetCodelistRequestsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCodelistRequestsQueryKey(params)

    const getCodelistRequests = useGetCodelistRequestsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestsHook>>>> = ({ signal }) =>
        getCodelistRequests(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetCodelistRequestsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestsHook>>>>
export type GetCodelistRequestsQueryError = ApiError

export function useGetCodelistRequests<TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestsHook>>>, TError = ApiError>(
    params: GetCodelistRequestsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetCodelistRequestsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCreateCodelistRequestHook = () => {
    const createCodelistRequest = useCodeListRepoSwaggerClient<CreateCodelistRequest200>()

    return useCallback(
        (apiCodelistPreview: ApiCodelistPreview) => {
            return createCodelistRequest({
                url: `/codelists/codelistrequests`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiCodelistPreview,
            })
        },
        [createCodelistRequest],
    )
}

export const useCreateCodelistRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateCodelistRequestHook>>>,
        TError,
        { data: ApiCodelistPreview },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateCodelistRequestHook>>>, TError, { data: ApiCodelistPreview }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const createCodelistRequest = useCreateCodelistRequestHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateCodelistRequestHook>>>, { data: ApiCodelistPreview }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return createCodelistRequest(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateCodelistRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateCodelistRequestHook>>>>
export type CreateCodelistRequestMutationBody = ApiCodelistPreview
export type CreateCodelistRequestMutationError = ApiError

export const useCreateCodelistRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateCodelistRequestHook>>>,
        TError,
        { data: ApiCodelistPreview },
        TContext
    >
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useCreateCodelistRequestHook>>>, TError, { data: ApiCodelistPreview }, TContext> => {
    const mutationOptions = useCreateCodelistRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useConnectCodelistWithEntityHook = () => {
    const connectCodelistWithEntity = useCodeListRepoSwaggerClient<ConnectCodelistWithEntity200>()

    return useCallback(
        (code: string, entity: string) => {
            return connectCodelistWithEntity({ url: `/codelists/codelistheaders/${code}/connect/${entity}`, method: 'POST' })
        },
        [connectCodelistWithEntity],
    )
}

export const useConnectCodelistWithEntityMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useConnectCodelistWithEntityHook>>>,
        TError,
        { code: string; entity: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useConnectCodelistWithEntityHook>>>,
    TError,
    { code: string; entity: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const connectCodelistWithEntity = useConnectCodelistWithEntityHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useConnectCodelistWithEntityHook>>>, { code: string; entity: string }> = (
        props,
    ) => {
        const { code, entity } = props ?? {}

        return connectCodelistWithEntity(code, entity)
    }

    return { mutationFn, ...mutationOptions }
}

export type ConnectCodelistWithEntityMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useConnectCodelistWithEntityHook>>>>

export type ConnectCodelistWithEntityMutationError = ApiError

export const useConnectCodelistWithEntity = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useConnectCodelistWithEntityHook>>>,
        TError,
        { code: string; entity: string },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useConnectCodelistWithEntityHook>>>,
    TError,
    { code: string; entity: string },
    TContext
> => {
    const mutationOptions = useConnectCodelistWithEntityMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetCodelistItemsHook = () => {
    const getCodelistItems = useCodeListRepoSwaggerClient<ApiCodelistItemList>()

    return useCallback(
        (code: string, params: GetCodelistItemsParams, signal?: AbortSignal) => {
            return getCodelistItems({ url: `/codelists/codelistheaders/${code}/codelistitems`, method: 'GET', params, signal })
        },
        [getCodelistItems],
    )
}

export const getGetCodelistItemsQueryKey = (code: string, params: GetCodelistItemsParams) => {
    return [`/codelists/codelistheaders/${code}/codelistitems`, ...(params ? [params] : [])] as const
}

export const useGetCodelistItemsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistItemsHook>>>, TError = ApiError>(
    code: string,
    params: GetCodelistItemsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistItemsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCodelistItemsQueryKey(code, params)

    const getCodelistItems = useGetCodelistItemsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCodelistItemsHook>>>> = ({ signal }) =>
        getCodelistItems(code, params, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCodelistItemsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetCodelistItemsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCodelistItemsHook>>>>
export type GetCodelistItemsQueryError = ApiError

export function useGetCodelistItems<TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistItemsHook>>>, TError = ApiError>(
    code: string,
    params: GetCodelistItemsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistItemsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetCodelistItemsQueryOptions(code, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCreateCodelistItemHook = () => {
    const createCodelistItem = useCodeListRepoSwaggerClient<CreateCodelistItem200>()

    return useCallback(
        (code: string, apiCodelistItem: ApiCodelistItem) => {
            return createCodelistItem({
                url: `/codelists/codelistheaders/${code}/codelistitems`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiCodelistItem,
            })
        },
        [createCodelistItem],
    )
}

export const useCreateCodelistItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateCodelistItemHook>>>,
        TError,
        { code: string; data: ApiCodelistItem },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateCodelistItemHook>>>,
    TError,
    { code: string; data: ApiCodelistItem },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const createCodelistItem = useCreateCodelistItemHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateCodelistItemHook>>>, { code: string; data: ApiCodelistItem }> = (
        props,
    ) => {
        const { code, data } = props ?? {}

        return createCodelistItem(code, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateCodelistItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateCodelistItemHook>>>>
export type CreateCodelistItemMutationBody = ApiCodelistItem
export type CreateCodelistItemMutationError = ApiError

export const useCreateCodelistItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateCodelistItemHook>>>,
        TError,
        { code: string; data: ApiCodelistItem },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useCreateCodelistItemHook>>>,
    TError,
    { code: string; data: ApiCodelistItem },
    TContext
> => {
    const mutationOptions = useCreateCodelistItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCreateNewCodelistItemLangExtendedHook = () => {
    const createNewCodelistItemLangExtended = useCodeListRepoSwaggerClient<CreateNewCodelistItemLangExtended200>()

    return useCallback(
        (code: string, itemCode: string, actualLang: string, apiCodelistItem: ApiCodelistItem) => {
            return createNewCodelistItemLangExtended({
                url: `/codelists/codelistheaders/${code}/codelistitems/${itemCode}/${actualLang}/langextended`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: apiCodelistItem,
            })
        },
        [createNewCodelistItemLangExtended],
    )
}

export const useCreateNewCodelistItemLangExtendedMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateNewCodelistItemLangExtendedHook>>>,
        TError,
        { code: string; itemCode: string; actualLang: string; data: ApiCodelistItem },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateNewCodelistItemLangExtendedHook>>>,
    TError,
    { code: string; itemCode: string; actualLang: string; data: ApiCodelistItem },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const createNewCodelistItemLangExtended = useCreateNewCodelistItemLangExtendedHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCreateNewCodelistItemLangExtendedHook>>>,
        { code: string; itemCode: string; actualLang: string; data: ApiCodelistItem }
    > = (props) => {
        const { code, itemCode, actualLang, data } = props ?? {}

        return createNewCodelistItemLangExtended(code, itemCode, actualLang, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateNewCodelistItemLangExtendedMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useCreateNewCodelistItemLangExtendedHook>>>
>
export type CreateNewCodelistItemLangExtendedMutationBody = ApiCodelistItem
export type CreateNewCodelistItemLangExtendedMutationError = ApiError

export const useCreateNewCodelistItemLangExtended = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateNewCodelistItemLangExtendedHook>>>,
        TError,
        { code: string; itemCode: string; actualLang: string; data: ApiCodelistItem },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useCreateNewCodelistItemLangExtendedHook>>>,
    TError,
    { code: string; itemCode: string; actualLang: string; data: ApiCodelistItem },
    TContext
> => {
    const mutationOptions = useCreateNewCodelistItemLangExtendedMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary verifyInternalCodelistRequest
 */
export const useVerifyInternalCodelistRequestHook = () => {
    const verifyInternalCodelistRequest = useCodeListRepoSwaggerClient<MultiValueMapObjectObject>()

    return useCallback(
        (verifyInternalCodelistRequestBody: VerifyInternalCodelistRequestBody) => {
            return verifyInternalCodelistRequest({
                url: `/codelists/codelistheaders/verifyrequest`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: verifyInternalCodelistRequestBody,
            })
        },
        [verifyInternalCodelistRequest],
    )
}

export const useVerifyInternalCodelistRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useVerifyInternalCodelistRequestHook>>>,
        TError,
        { data: VerifyInternalCodelistRequestBody },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useVerifyInternalCodelistRequestHook>>>,
    TError,
    { data: VerifyInternalCodelistRequestBody },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const verifyInternalCodelistRequest = useVerifyInternalCodelistRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useVerifyInternalCodelistRequestHook>>>,
        { data: VerifyInternalCodelistRequestBody }
    > = (props) => {
        const { data } = props ?? {}

        return verifyInternalCodelistRequest(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type VerifyInternalCodelistRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useVerifyInternalCodelistRequestHook>>>>
export type VerifyInternalCodelistRequestMutationBody = VerifyInternalCodelistRequestBody
export type VerifyInternalCodelistRequestMutationError = ApiError

/**
 * @summary verifyInternalCodelistRequest
 */
export const useVerifyInternalCodelistRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useVerifyInternalCodelistRequestHook>>>,
        TError,
        { data: VerifyInternalCodelistRequestBody },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useVerifyInternalCodelistRequestHook>>>,
    TError,
    { data: VerifyInternalCodelistRequestBody },
    TContext
> => {
    const mutationOptions = useVerifyInternalCodelistRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary verifyInternalCodelist
 */
export const useVerifyInternalCodelistHook = () => {
    const verifyInternalCodelist = useCodeListRepoSwaggerClient<MultiValueMapObjectObject>()

    return useCallback(
        (verifyInternalCodelistBody: VerifyInternalCodelistBody) => {
            return verifyInternalCodelist({
                url: `/codelists/codelistheaders/verify`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: verifyInternalCodelistBody,
            })
        },
        [verifyInternalCodelist],
    )
}

export const useVerifyInternalCodelistMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useVerifyInternalCodelistHook>>>,
        TError,
        { data: VerifyInternalCodelistBody },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useVerifyInternalCodelistHook>>>,
    TError,
    { data: VerifyInternalCodelistBody },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const verifyInternalCodelist = useVerifyInternalCodelistHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useVerifyInternalCodelistHook>>>,
        { data: VerifyInternalCodelistBody }
    > = (props) => {
        const { data } = props ?? {}

        return verifyInternalCodelist(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type VerifyInternalCodelistMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useVerifyInternalCodelistHook>>>>
export type VerifyInternalCodelistMutationBody = VerifyInternalCodelistBody
export type VerifyInternalCodelistMutationError = ApiError

/**
 * @summary verifyInternalCodelist
 */
export const useVerifyInternalCodelist = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useVerifyInternalCodelistHook>>>,
        TError,
        { data: VerifyInternalCodelistBody },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useVerifyInternalCodelistHook>>>,
    TError,
    { data: VerifyInternalCodelistBody },
    TContext
> => {
    const mutationOptions = useVerifyInternalCodelistMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUploadInternalCodelistRequestHook = () => {
    const uploadInternalCodelistRequest = useCodeListRepoSwaggerClient<UploadInternalCodelistRequest200>()

    return useCallback(
        (uploadInternalCodelistRequestBody: UploadInternalCodelistRequestBody) => {
            return uploadInternalCodelistRequest({
                url: `/codelists/codelistheaders/uploadrequest`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: uploadInternalCodelistRequestBody,
            })
        },
        [uploadInternalCodelistRequest],
    )
}

export const useUploadInternalCodelistRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUploadInternalCodelistRequestHook>>>,
        TError,
        { data: UploadInternalCodelistRequestBody },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadInternalCodelistRequestHook>>>,
    TError,
    { data: UploadInternalCodelistRequestBody },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const uploadInternalCodelistRequest = useUploadInternalCodelistRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUploadInternalCodelistRequestHook>>>,
        { data: UploadInternalCodelistRequestBody }
    > = (props) => {
        const { data } = props ?? {}

        return uploadInternalCodelistRequest(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UploadInternalCodelistRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUploadInternalCodelistRequestHook>>>>
export type UploadInternalCodelistRequestMutationBody = UploadInternalCodelistRequestBody
export type UploadInternalCodelistRequestMutationError = ApiError

export const useUploadInternalCodelistRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUploadInternalCodelistRequestHook>>>,
        TError,
        { data: UploadInternalCodelistRequestBody },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUploadInternalCodelistRequestHook>>>,
    TError,
    { data: UploadInternalCodelistRequestBody },
    TContext
> => {
    const mutationOptions = useUploadInternalCodelistRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUploadInternalCodelistHook = () => {
    const uploadInternalCodelist = useCodeListRepoSwaggerClient<UploadInternalCodelist200>()

    return useCallback(
        (uploadInternalCodelistBody: UploadInternalCodelistBody) => {
            return uploadInternalCodelist({
                url: `/codelists/codelistheaders/upload`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: uploadInternalCodelistBody,
            })
        },
        [uploadInternalCodelist],
    )
}

export const useUploadInternalCodelistMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUploadInternalCodelistHook>>>,
        TError,
        { data: UploadInternalCodelistBody },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadInternalCodelistHook>>>,
    TError,
    { data: UploadInternalCodelistBody },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const uploadInternalCodelist = useUploadInternalCodelistHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUploadInternalCodelistHook>>>,
        { data: UploadInternalCodelistBody }
    > = (props) => {
        const { data } = props ?? {}

        return uploadInternalCodelist(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UploadInternalCodelistMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUploadInternalCodelistHook>>>>
export type UploadInternalCodelistMutationBody = UploadInternalCodelistBody
export type UploadInternalCodelistMutationError = ApiError

export const useUploadInternalCodelist = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUploadInternalCodelistHook>>>,
        TError,
        { data: UploadInternalCodelistBody },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUploadInternalCodelistHook>>>,
    TError,
    { data: UploadInternalCodelistBody },
    TContext
> => {
    const mutationOptions = useUploadInternalCodelistMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary uploadCodeListRequestPreview
 */
export const useUploadCodeListRequestPreviewHook = () => {
    const uploadCodeListRequestPreview = useCodeListRepoSwaggerClient<DiffApiCodelist>()

    return useCallback(
        (uploadCodeListRequestPreviewBody: UploadCodeListRequestPreviewBody, params: UploadCodeListRequestPreviewParams) => {
            return uploadCodeListRequestPreview({
                url: `/codelists/codelistheaders/upload/previewrequest`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: uploadCodeListRequestPreviewBody,
                params,
            })
        },
        [uploadCodeListRequestPreview],
    )
}

export const useUploadCodeListRequestPreviewMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUploadCodeListRequestPreviewHook>>>,
        TError,
        { data: UploadCodeListRequestPreviewBody; params: UploadCodeListRequestPreviewParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadCodeListRequestPreviewHook>>>,
    TError,
    { data: UploadCodeListRequestPreviewBody; params: UploadCodeListRequestPreviewParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const uploadCodeListRequestPreview = useUploadCodeListRequestPreviewHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUploadCodeListRequestPreviewHook>>>,
        { data: UploadCodeListRequestPreviewBody; params: UploadCodeListRequestPreviewParams }
    > = (props) => {
        const { data, params } = props ?? {}

        return uploadCodeListRequestPreview(data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type UploadCodeListRequestPreviewMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUploadCodeListRequestPreviewHook>>>>
export type UploadCodeListRequestPreviewMutationBody = UploadCodeListRequestPreviewBody
export type UploadCodeListRequestPreviewMutationError = ApiError

/**
 * @summary uploadCodeListRequestPreview
 */
export const useUploadCodeListRequestPreview = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUploadCodeListRequestPreviewHook>>>,
        TError,
        { data: UploadCodeListRequestPreviewBody; params: UploadCodeListRequestPreviewParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUploadCodeListRequestPreviewHook>>>,
    TError,
    { data: UploadCodeListRequestPreviewBody; params: UploadCodeListRequestPreviewParams },
    TContext
> => {
    const mutationOptions = useUploadCodeListRequestPreviewMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary uploadCodeListPreview
 */
export const useUploadCodeListPreviewHook = () => {
    const uploadCodeListPreview = useCodeListRepoSwaggerClient<DiffApiCodelist>()

    return useCallback(
        (uploadCodeListPreviewBody: UploadCodeListPreviewBody, params: UploadCodeListPreviewParams) => {
            return uploadCodeListPreview({
                url: `/codelists/codelistheaders/upload/preview`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: uploadCodeListPreviewBody,
                params,
            })
        },
        [uploadCodeListPreview],
    )
}

export const useUploadCodeListPreviewMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUploadCodeListPreviewHook>>>,
        TError,
        { data: UploadCodeListPreviewBody; params: UploadCodeListPreviewParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadCodeListPreviewHook>>>,
    TError,
    { data: UploadCodeListPreviewBody; params: UploadCodeListPreviewParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const uploadCodeListPreview = useUploadCodeListPreviewHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUploadCodeListPreviewHook>>>,
        { data: UploadCodeListPreviewBody; params: UploadCodeListPreviewParams }
    > = (props) => {
        const { data, params } = props ?? {}

        return uploadCodeListPreview(data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type UploadCodeListPreviewMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUploadCodeListPreviewHook>>>>
export type UploadCodeListPreviewMutationBody = UploadCodeListPreviewBody
export type UploadCodeListPreviewMutationError = ApiError

/**
 * @summary uploadCodeListPreview
 */
export const useUploadCodeListPreview = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUploadCodeListPreviewHook>>>,
        TError,
        { data: UploadCodeListPreviewBody; params: UploadCodeListPreviewParams },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUploadCodeListPreviewHook>>>,
    TError,
    { data: UploadCodeListPreviewBody; params: UploadCodeListPreviewParams },
    TContext
> => {
    const mutationOptions = useUploadCodeListPreviewMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetCodeListByRefIdHook = () => {
    const getCodeListByRefId = useCodeListRepoSwaggerClient<ApiCodelistPreview>()

    return useCallback(
        (params: GetCodeListByRefIdParams, signal?: AbortSignal) => {
            return getCodeListByRefId({ url: `/codelists/codelistheaders/readByRefID`, method: 'GET', params, signal })
        },
        [getCodeListByRefId],
    )
}

export const getGetCodeListByRefIdQueryKey = (params: GetCodeListByRefIdParams) => {
    return [`/codelists/codelistheaders/readByRefID`, ...(params ? [params] : [])] as const
}

export const useGetCodeListByRefIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCodeListByRefIdHook>>>, TError = ApiError>(
    params: GetCodeListByRefIdParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodeListByRefIdHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCodeListByRefIdQueryKey(params)

    const getCodeListByRefId = useGetCodeListByRefIdHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCodeListByRefIdHook>>>> = ({ signal }) =>
        getCodeListByRefId(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCodeListByRefIdHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetCodeListByRefIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCodeListByRefIdHook>>>>
export type GetCodeListByRefIdQueryError = ApiError

export function useGetCodeListByRefId<TData = Awaited<ReturnType<ReturnType<typeof useGetCodeListByRefIdHook>>>, TError = ApiError>(
    params: GetCodeListByRefIdParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodeListByRefIdHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetCodeListByRefIdQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary postCodeListByRefId
 */
export const usePostCodeListByRefIdHook = () => {
    const postCodeListByRefId = useCodeListRepoSwaggerClient<ApiCodelistPreview>()

    return useCallback(
        (postCodeListByRefIdBody: string) => {
            return postCodeListByRefId({
                url: `/codelists/codelistheaders/readByRefID`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: postCodeListByRefIdBody,
            })
        },
        [postCodeListByRefId],
    )
}

export const usePostCodeListByRefIdMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostCodeListByRefIdHook>>>, TError, { data: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostCodeListByRefIdHook>>>, TError, { data: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const postCodeListByRefId = usePostCodeListByRefIdHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostCodeListByRefIdHook>>>, { data: string }> = (props) => {
        const { data } = props ?? {}

        return postCodeListByRefId(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostCodeListByRefIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostCodeListByRefIdHook>>>>
export type PostCodeListByRefIdMutationBody = string
export type PostCodeListByRefIdMutationError = ApiError

/**
 * @summary postCodeListByRefId
 */
export const usePostCodeListByRefId = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostCodeListByRefIdHook>>>, TError, { data: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof usePostCodeListByRefIdHook>>>, TError, { data: string }, TContext> => {
    const mutationOptions = usePostCodeListByRefIdMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useQuickCheckCodelistHook = () => {
    const quickCheckCodelist = useCodeListRepoSwaggerClient<string>()

    return useCallback(
        (code: string, params?: QuickCheckCodelistParams, signal?: AbortSignal) => {
            return quickCheckCodelist({ url: `/utils/quick-check-codelist/${code}`, method: 'GET', params, signal })
        },
        [quickCheckCodelist],
    )
}

export const getQuickCheckCodelistQueryKey = (code: string, params?: QuickCheckCodelistParams) => {
    return [`/utils/quick-check-codelist/${code}`, ...(params ? [params] : [])] as const
}

export const useQuickCheckCodelistQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useQuickCheckCodelistHook>>>, TError = ApiError>(
    code: string,
    params?: QuickCheckCodelistParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQuickCheckCodelistHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getQuickCheckCodelistQueryKey(code, params)

    const quickCheckCodelist = useQuickCheckCodelistHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useQuickCheckCodelistHook>>>> = ({ signal }) =>
        quickCheckCodelist(code, params, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useQuickCheckCodelistHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type QuickCheckCodelistQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useQuickCheckCodelistHook>>>>
export type QuickCheckCodelistQueryError = ApiError

export function useQuickCheckCodelist<TData = Awaited<ReturnType<ReturnType<typeof useQuickCheckCodelistHook>>>, TError = ApiError>(
    code: string,
    params?: QuickCheckCodelistParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQuickCheckCodelistHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useQuickCheckCodelistQueryOptions(code, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useQuickCheckCodelistNowHook = () => {
    const quickCheckCodelistNow = useCodeListRepoSwaggerClient<CodelistRepairStatus>()

    return useCallback(
        (code: string, params?: QuickCheckCodelistNowParams, signal?: AbortSignal) => {
            return quickCheckCodelistNow({ url: `/utils/quick-check-codelist-now/${code}`, method: 'GET', params, signal })
        },
        [quickCheckCodelistNow],
    )
}

export const getQuickCheckCodelistNowQueryKey = (code: string, params?: QuickCheckCodelistNowParams) => {
    return [`/utils/quick-check-codelist-now/${code}`, ...(params ? [params] : [])] as const
}

export const useQuickCheckCodelistNowQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useQuickCheckCodelistNowHook>>>, TError = ApiError>(
    code: string,
    params?: QuickCheckCodelistNowParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQuickCheckCodelistNowHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getQuickCheckCodelistNowQueryKey(code, params)

    const quickCheckCodelistNow = useQuickCheckCodelistNowHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useQuickCheckCodelistNowHook>>>> = ({ signal }) =>
        quickCheckCodelistNow(code, params, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useQuickCheckCodelistNowHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type QuickCheckCodelistNowQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useQuickCheckCodelistNowHook>>>>
export type QuickCheckCodelistNowQueryError = ApiError

export function useQuickCheckCodelistNow<TData = Awaited<ReturnType<ReturnType<typeof useQuickCheckCodelistNowHook>>>, TError = ApiError>(
    code: string,
    params?: QuickCheckCodelistNowParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQuickCheckCodelistNowHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useQuickCheckCodelistNowQueryOptions(code, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCheckCodelistsHook = () => {
    const checkCodelists = useCodeListRepoSwaggerClient<string>()

    return useCallback(
        (signal?: AbortSignal) => {
            return checkCodelists({ url: `/utils/check-codelists`, method: 'GET', signal })
        },
        [checkCodelists],
    )
}

export const getCheckCodelistsQueryKey = () => {
    return [`/utils/check-codelists`] as const
}

export const useCheckCodelistsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useCheckCodelistsHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckCodelistsHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getCheckCodelistsQueryKey()

    const checkCodelists = useCheckCodelistsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCheckCodelistsHook>>>> = ({ signal }) => checkCodelists(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckCodelistsHook>>>, TError, TData> & {
        queryKey: QueryKey
    }
}

export type CheckCodelistsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCheckCodelistsHook>>>>
export type CheckCodelistsQueryError = ApiError

export function useCheckCodelists<TData = Awaited<ReturnType<ReturnType<typeof useCheckCodelistsHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckCodelistsHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useCheckCodelistsQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCheckCodelistHook = () => {
    const checkCodelist = useCodeListRepoSwaggerClient<string>()

    return useCallback(
        (code: string, params?: CheckCodelistParams, signal?: AbortSignal) => {
            return checkCodelist({ url: `/utils/check-codelist/${code}`, method: 'GET', params, signal })
        },
        [checkCodelist],
    )
}

export const getCheckCodelistQueryKey = (code: string, params?: CheckCodelistParams) => {
    return [`/utils/check-codelist/${code}`, ...(params ? [params] : [])] as const
}

export const useCheckCodelistQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useCheckCodelistHook>>>, TError = ApiError>(
    code: string,
    params?: CheckCodelistParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckCodelistHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getCheckCodelistQueryKey(code, params)

    const checkCodelist = useCheckCodelistHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCheckCodelistHook>>>> = ({ signal }) => checkCodelist(code, params, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useCheckCodelistHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type CheckCodelistQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCheckCodelistHook>>>>
export type CheckCodelistQueryError = ApiError

export function useCheckCodelist<TData = Awaited<ReturnType<ReturnType<typeof useCheckCodelistHook>>>, TError = ApiError>(
    code: string,
    params?: CheckCodelistParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckCodelistHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useCheckCodelistQueryOptions(code, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCheckCodelistNowHook = () => {
    const checkCodelistNow = useCodeListRepoSwaggerClient<CodelistRepairStatus>()

    return useCallback(
        (code: string, params?: CheckCodelistNowParams, signal?: AbortSignal) => {
            return checkCodelistNow({ url: `/utils/check-codelist-now/${code}`, method: 'GET', params, signal })
        },
        [checkCodelistNow],
    )
}

export const getCheckCodelistNowQueryKey = (code: string, params?: CheckCodelistNowParams) => {
    return [`/utils/check-codelist-now/${code}`, ...(params ? [params] : [])] as const
}

export const useCheckCodelistNowQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useCheckCodelistNowHook>>>, TError = ApiError>(
    code: string,
    params?: CheckCodelistNowParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckCodelistNowHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getCheckCodelistNowQueryKey(code, params)

    const checkCodelistNow = useCheckCodelistNowHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCheckCodelistNowHook>>>> = ({ signal }) =>
        checkCodelistNow(code, params, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useCheckCodelistNowHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type CheckCodelistNowQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCheckCodelistNowHook>>>>
export type CheckCodelistNowQueryError = ApiError

export function useCheckCodelistNow<TData = Awaited<ReturnType<ReturnType<typeof useCheckCodelistNowHook>>>, TError = ApiError>(
    code: string,
    params?: CheckCodelistNowParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckCodelistNowHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useCheckCodelistNowQueryOptions(code, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListAllReindexTasksHook = () => {
    const listAllReindexTasks = useCodeListRepoSwaggerClient<AsyncTaskDto[]>()

    return useCallback(
        (signal?: AbortSignal) => {
            return listAllReindexTasks({ url: `/utils/async-tasks`, method: 'GET', signal })
        },
        [listAllReindexTasks],
    )
}

export const getListAllReindexTasksQueryKey = () => {
    return [`/utils/async-tasks`] as const
}

export const useListAllReindexTasksQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListAllReindexTasksQueryKey()

    const listAllReindexTasks = useListAllReindexTasksHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>> = ({ signal }) => listAllReindexTasks(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ListAllReindexTasksQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>>
export type ListAllReindexTasksQueryError = ApiError

export function useListAllReindexTasks<TData = Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useListAllReindexTasksQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetReindexTaskStateByUuidHook = () => {
    const getReindexTaskStateByUuid = useCodeListRepoSwaggerClient<AsyncTaskDto>()

    return useCallback(
        (uuid: string, signal?: AbortSignal) => {
            return getReindexTaskStateByUuid({ url: `/utils/async-tasks/${uuid}`, method: 'GET', signal })
        },
        [getReindexTaskStateByUuid],
    )
}

export const getGetReindexTaskStateByUuidQueryKey = (uuid: string) => {
    return [`/utils/async-tasks/${uuid}`] as const
}

export const useGetReindexTaskStateByUuidQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>,
    TError = ApiError,
>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetReindexTaskStateByUuidQueryKey(uuid)

    const getReindexTaskStateByUuid = useGetReindexTaskStateByUuidHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>> = ({ signal }) =>
        getReindexTaskStateByUuid(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetReindexTaskStateByUuidQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>>
export type GetReindexTaskStateByUuidQueryError = ApiError

export function useGetReindexTaskStateByUuid<TData = Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetReindexTaskStateByUuidQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCancelReindexTaskByUuidHook = () => {
    const cancelReindexTaskByUuid = useCodeListRepoSwaggerClient<void>()

    return useCallback(
        (uuid: string) => {
            return cancelReindexTaskByUuid({ url: `/utils/async-tasks/${uuid}`, method: 'DELETE' })
        },
        [cancelReindexTaskByUuid],
    )
}

export const useCancelReindexTaskByUuidMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>, TError, { uuid: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>, TError, { uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const cancelReindexTaskByUuid = useCancelReindexTaskByUuidHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>, { uuid: string }> = (props) => {
        const { uuid } = props ?? {}

        return cancelReindexTaskByUuid(uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type CancelReindexTaskByUuidMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>>

export type CancelReindexTaskByUuidMutationError = ApiError

export const useCancelReindexTaskByUuid = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>, TError, { uuid: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>, TError, { uuid: string }, TContext> => {
    const mutationOptions = useCancelReindexTaskByUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetNextItemCodeHook = () => {
    const getNextItemCode = useCodeListRepoSwaggerClient<string>()

    return useCallback(
        (id: number, params?: GetNextItemCodeParams, signal?: AbortSignal) => {
            return getNextItemCode({ url: `/codelists/${id}/generate/itemcode`, method: 'GET', params, signal })
        },
        [getNextItemCode],
    )
}

export const getGetNextItemCodeQueryKey = (id: number, params?: GetNextItemCodeParams) => {
    return [`/codelists/${id}/generate/itemcode`, ...(params ? [params] : [])] as const
}

export const useGetNextItemCodeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetNextItemCodeHook>>>, TError = ApiError>(
    id: number,
    params?: GetNextItemCodeParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNextItemCodeHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetNextItemCodeQueryKey(id, params)

    const getNextItemCode = useGetNextItemCodeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNextItemCodeHook>>>> = ({ signal }) => getNextItemCode(id, params, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetNextItemCodeHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetNextItemCodeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNextItemCodeHook>>>>
export type GetNextItemCodeQueryError = ApiError

export function useGetNextItemCode<TData = Awaited<ReturnType<ReturnType<typeof useGetNextItemCodeHook>>>, TError = ApiError>(
    id: number,
    params?: GetNextItemCodeParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNextItemCodeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetNextItemCodeQueryOptions(id, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetFirstNotUsedCodeHook = () => {
    const getFirstNotUsedCode = useCodeListRepoSwaggerClient<GetFirstNotUsedCode200>()

    return useCallback(
        (signal?: AbortSignal) => {
            return getFirstNotUsedCode({ url: `/codelists/firstNotUsedCode`, method: 'GET', signal })
        },
        [getFirstNotUsedCode],
    )
}

export const getGetFirstNotUsedCodeQueryKey = () => {
    return [`/codelists/firstNotUsedCode`] as const
}

export const useGetFirstNotUsedCodeQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetFirstNotUsedCodeHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFirstNotUsedCodeHook>>>, TError, TData>
}) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetFirstNotUsedCodeQueryKey()

    const getFirstNotUsedCode = useGetFirstNotUsedCodeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFirstNotUsedCodeHook>>>> = ({ signal }) => getFirstNotUsedCode(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetFirstNotUsedCodeHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetFirstNotUsedCodeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFirstNotUsedCodeHook>>>>
export type GetFirstNotUsedCodeQueryError = ApiError

export function useGetFirstNotUsedCode<TData = Awaited<ReturnType<ReturnType<typeof useGetFirstNotUsedCodeHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFirstNotUsedCodeHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetFirstNotUsedCodeQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetCodelistRequestDetailHook = () => {
    const getCodelistRequestDetail = useCodeListRepoSwaggerClient<ApiCodelistPreview>()

    return useCallback(
        (codelistId: number, signal?: AbortSignal) => {
            return getCodelistRequestDetail({ url: `/codelists/codelistrequests/${codelistId}`, method: 'GET', signal })
        },
        [getCodelistRequestDetail],
    )
}

export const getGetCodelistRequestDetailQueryKey = (codelistId: number) => {
    return [`/codelists/codelistrequests/${codelistId}`] as const
}

export const useGetCodelistRequestDetailQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestDetailHook>>>,
    TError = ApiError,
>(
    codelistId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestDetailHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCodelistRequestDetailQueryKey(codelistId)

    const getCodelistRequestDetail = useGetCodelistRequestDetailHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestDetailHook>>>> = ({ signal }) =>
        getCodelistRequestDetail(codelistId, signal)

    return { queryKey, queryFn, enabled: !!codelistId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestDetailHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetCodelistRequestDetailQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestDetailHook>>>>
export type GetCodelistRequestDetailQueryError = ApiError

export function useGetCodelistRequestDetail<TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestDetailHook>>>, TError = ApiError>(
    codelistId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestDetailHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetCodelistRequestDetailQueryOptions(codelistId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetCodelistRequestDetailStateISVSProcessingHook = () => {
    const getCodelistRequestDetailStateISVSProcessing = useCodeListRepoSwaggerClient<ApiCodelistPreview>()

    return useCallback(
        (params: GetCodelistRequestDetailStateISVSProcessingParams, signal?: AbortSignal) => {
            return getCodelistRequestDetailStateISVSProcessing({ url: `/codelists/codelistrequests/detail`, method: 'GET', params, signal })
        },
        [getCodelistRequestDetailStateISVSProcessing],
    )
}

export const getGetCodelistRequestDetailStateISVSProcessingQueryKey = (params: GetCodelistRequestDetailStateISVSProcessingParams) => {
    return [`/codelists/codelistrequests/detail`, ...(params ? [params] : [])] as const
}

export const useGetCodelistRequestDetailStateISVSProcessingQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestDetailStateISVSProcessingHook>>>,
    TError = ApiError,
>(
    params: GetCodelistRequestDetailStateISVSProcessingParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestDetailStateISVSProcessingHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCodelistRequestDetailStateISVSProcessingQueryKey(params)

    const getCodelistRequestDetailStateISVSProcessing = useGetCodelistRequestDetailStateISVSProcessingHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestDetailStateISVSProcessingHook>>>> = ({ signal }) =>
        getCodelistRequestDetailStateISVSProcessing(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestDetailStateISVSProcessingHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetCodelistRequestDetailStateISVSProcessingQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestDetailStateISVSProcessingHook>>>
>
export type GetCodelistRequestDetailStateISVSProcessingQueryError = ApiError

export function useGetCodelistRequestDetailStateISVSProcessing<
    TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestDetailStateISVSProcessingHook>>>,
    TError = ApiError,
>(
    params: GetCodelistRequestDetailStateISVSProcessingParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestDetailStateISVSProcessingHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetCodelistRequestDetailStateISVSProcessingQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetCodelistItemHook = () => {
    const getCodelistItem = useCodeListRepoSwaggerClient<ApiCodelistItem>()

    return useCallback(
        (codelistItemId: number, signal?: AbortSignal) => {
            return getCodelistItem({ url: `/codelists/codelistitems/${codelistItemId}`, method: 'GET', signal })
        },
        [getCodelistItem],
    )
}

export const getGetCodelistItemQueryKey = (codelistItemId: number) => {
    return [`/codelists/codelistitems/${codelistItemId}`] as const
}

export const useGetCodelistItemQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistItemHook>>>, TError = ApiError>(
    codelistItemId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistItemHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCodelistItemQueryKey(codelistItemId)

    const getCodelistItem = useGetCodelistItemHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCodelistItemHook>>>> = ({ signal }) =>
        getCodelistItem(codelistItemId, signal)

    return { queryKey, queryFn, enabled: !!codelistItemId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCodelistItemHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetCodelistItemQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCodelistItemHook>>>>
export type GetCodelistItemQueryError = ApiError

export function useGetCodelistItem<TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistItemHook>>>, TError = ApiError>(
    codelistItemId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistItemHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetCodelistItemQueryOptions(codelistItemId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetCodelistHeadersHook = () => {
    const getCodelistHeaders = useCodeListRepoSwaggerClient<ApiCodelistPreviewList>()

    return useCallback(
        (params: GetCodelistHeadersParams, signal?: AbortSignal) => {
            return getCodelistHeaders({ url: `/codelists/codelistheaders`, method: 'GET', params, signal })
        },
        [getCodelistHeaders],
    )
}

export const getGetCodelistHeadersQueryKey = (params: GetCodelistHeadersParams) => {
    return [`/codelists/codelistheaders`, ...(params ? [params] : [])] as const
}

export const useGetCodelistHeadersQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistHeadersHook>>>, TError = ApiError>(
    params: GetCodelistHeadersParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistHeadersHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCodelistHeadersQueryKey(params)

    const getCodelistHeaders = useGetCodelistHeadersHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCodelistHeadersHook>>>> = ({ signal }) =>
        getCodelistHeaders(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCodelistHeadersHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetCodelistHeadersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCodelistHeadersHook>>>>
export type GetCodelistHeadersQueryError = ApiError

export function useGetCodelistHeaders<TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistHeadersHook>>>, TError = ApiError>(
    params: GetCodelistHeadersParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistHeadersHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetCodelistHeadersQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTemporalCodelistHeaderWithLockHook = () => {
    const getTemporalCodelistHeaderWithLock = useCodeListRepoSwaggerClient<ApiCodelistPreview>()

    return useCallback(
        (code: string, signal?: AbortSignal) => {
            return getTemporalCodelistHeaderWithLock({ url: `/codelists/codelistheaders/${code}/temporalWithLock`, method: 'GET', signal })
        },
        [getTemporalCodelistHeaderWithLock],
    )
}

export const getGetTemporalCodelistHeaderWithLockQueryKey = (code: string) => {
    return [`/codelists/codelistheaders/${code}/temporalWithLock`] as const
}

export const useGetTemporalCodelistHeaderWithLockQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistHeaderWithLockHook>>>,
    TError = ApiError,
>(
    code: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistHeaderWithLockHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTemporalCodelistHeaderWithLockQueryKey(code)

    const getTemporalCodelistHeaderWithLock = useGetTemporalCodelistHeaderWithLockHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistHeaderWithLockHook>>>> = ({ signal }) =>
        getTemporalCodelistHeaderWithLock(code, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistHeaderWithLockHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetTemporalCodelistHeaderWithLockQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistHeaderWithLockHook>>>
>
export type GetTemporalCodelistHeaderWithLockQueryError = ApiError

export function useGetTemporalCodelistHeaderWithLock<
    TData = Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistHeaderWithLockHook>>>,
    TError = ApiError,
>(
    code: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistHeaderWithLockHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetTemporalCodelistHeaderWithLockQueryOptions(code, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetUnlockTemporalCodelistHeaderHook = () => {
    const getUnlockTemporalCodelistHeader = useCodeListRepoSwaggerClient<GetUnlockTemporalCodelistHeader200>()

    return useCallback(
        (code: string) => {
            return getUnlockTemporalCodelistHeader({ url: `/codelists/codelistheaders/${code}/temporalWithLock`, method: 'DELETE' })
        },
        [getUnlockTemporalCodelistHeader],
    )
}

export const useGetUnlockTemporalCodelistHeaderMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetUnlockTemporalCodelistHeaderHook>>>, TError, { code: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetUnlockTemporalCodelistHeaderHook>>>, TError, { code: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const getUnlockTemporalCodelistHeader = useGetUnlockTemporalCodelistHeaderHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetUnlockTemporalCodelistHeaderHook>>>, { code: string }> = (
        props,
    ) => {
        const { code } = props ?? {}

        return getUnlockTemporalCodelistHeader(code)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetUnlockTemporalCodelistHeaderMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetUnlockTemporalCodelistHeaderHook>>>
>

export type GetUnlockTemporalCodelistHeaderMutationError = ApiError

export const useGetUnlockTemporalCodelistHeader = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetUnlockTemporalCodelistHeaderHook>>>, TError, { code: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<ReturnType<typeof useGetUnlockTemporalCodelistHeaderHook>>>, TError, { code: string }, TContext> => {
    const mutationOptions = useGetUnlockTemporalCodelistHeaderMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExistTemporalCodelistHeaderHook = () => {
    const existTemporalCodelistHeader = useCodeListRepoSwaggerClient<ExistTemporalCodelistHeader200>()

    return useCallback(
        (code: string, signal?: AbortSignal) => {
            return existTemporalCodelistHeader({ url: `/codelists/codelistheaders/${code}/temporal/exist`, method: 'GET', signal })
        },
        [existTemporalCodelistHeader],
    )
}

export const getExistTemporalCodelistHeaderQueryKey = (code: string) => {
    return [`/codelists/codelistheaders/${code}/temporal/exist`] as const
}

export const useExistTemporalCodelistHeaderQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useExistTemporalCodelistHeaderHook>>>,
    TError = ApiError,
>(
    code: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useExistTemporalCodelistHeaderHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getExistTemporalCodelistHeaderQueryKey(code)

    const existTemporalCodelistHeader = useExistTemporalCodelistHeaderHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useExistTemporalCodelistHeaderHook>>>> = ({ signal }) =>
        existTemporalCodelistHeader(code, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useExistTemporalCodelistHeaderHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ExistTemporalCodelistHeaderQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExistTemporalCodelistHeaderHook>>>>
export type ExistTemporalCodelistHeaderQueryError = ApiError

export function useExistTemporalCodelistHeader<TData = Awaited<ReturnType<ReturnType<typeof useExistTemporalCodelistHeaderHook>>>, TError = ApiError>(
    code: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useExistTemporalCodelistHeaderHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useExistTemporalCodelistHeaderQueryOptions(code, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetOriginalCodelistHeaderHook = () => {
    const getOriginalCodelistHeader = useCodeListRepoSwaggerClient<ApiCodelistPreview>()

    return useCallback(
        (code: string, signal?: AbortSignal) => {
            return getOriginalCodelistHeader({ url: `/codelists/codelistheaders/${code}/original`, method: 'GET', signal })
        },
        [getOriginalCodelistHeader],
    )
}

export const getGetOriginalCodelistHeaderQueryKey = (code: string) => {
    return [`/codelists/codelistheaders/${code}/original`] as const
}

export const useGetOriginalCodelistHeaderQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetOriginalCodelistHeaderHook>>>,
    TError = ApiError,
>(
    code: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOriginalCodelistHeaderHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetOriginalCodelistHeaderQueryKey(code)

    const getOriginalCodelistHeader = useGetOriginalCodelistHeaderHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOriginalCodelistHeaderHook>>>> = ({ signal }) =>
        getOriginalCodelistHeader(code, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetOriginalCodelistHeaderHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetOriginalCodelistHeaderQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOriginalCodelistHeaderHook>>>>
export type GetOriginalCodelistHeaderQueryError = ApiError

export function useGetOriginalCodelistHeader<TData = Awaited<ReturnType<ReturnType<typeof useGetOriginalCodelistHeaderHook>>>, TError = ApiError>(
    code: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOriginalCodelistHeaderHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetOriginalCodelistHeaderQueryOptions(code, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetCodelistHistoryHook = () => {
    const getCodelistHistory = useCodeListRepoSwaggerClient<ApiCodelistHistoriesList>()

    return useCallback(
        (code: string, params?: GetCodelistHistoryParams, signal?: AbortSignal) => {
            return getCodelistHistory({ url: `/codelists/codelistheaders/${code}/history`, method: 'GET', params, signal })
        },
        [getCodelistHistory],
    )
}

export const getGetCodelistHistoryQueryKey = (code: string, params?: GetCodelistHistoryParams) => {
    return [`/codelists/codelistheaders/${code}/history`, ...(params ? [params] : [])] as const
}

export const useGetCodelistHistoryQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistHistoryHook>>>, TError = ApiError>(
    code: string,
    params?: GetCodelistHistoryParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistHistoryHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCodelistHistoryQueryKey(code, params)

    const getCodelistHistory = useGetCodelistHistoryHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCodelistHistoryHook>>>> = ({ signal }) =>
        getCodelistHistory(code, params, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCodelistHistoryHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetCodelistHistoryQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCodelistHistoryHook>>>>
export type GetCodelistHistoryQueryError = ApiError

export function useGetCodelistHistory<TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistHistoryHook>>>, TError = ApiError>(
    code: string,
    params?: GetCodelistHistoryParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistHistoryHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetCodelistHistoryQueryOptions(code, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetCodelistActionsHistoryHook = () => {
    const getCodelistActionsHistory = useCodeListRepoSwaggerClient<string[]>()

    return useCallback(
        (code: string, type: string, signal?: AbortSignal) => {
            return getCodelistActionsHistory({ url: `/codelists/codelistheaders/${code}/history/list/${type}`, method: 'GET', signal })
        },
        [getCodelistActionsHistory],
    )
}

export const getGetCodelistActionsHistoryQueryKey = (code: string, type: string) => {
    return [`/codelists/codelistheaders/${code}/history/list/${type}`] as const
}

export const useGetCodelistActionsHistoryQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistActionsHistoryHook>>>,
    TError = ApiError,
>(
    code: string,
    type: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistActionsHistoryHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCodelistActionsHistoryQueryKey(code, type)

    const getCodelistActionsHistory = useGetCodelistActionsHistoryHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCodelistActionsHistoryHook>>>> = ({ signal }) =>
        getCodelistActionsHistory(code, type, signal)

    return { queryKey, queryFn, enabled: !!(code && type), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCodelistActionsHistoryHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetCodelistActionsHistoryQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCodelistActionsHistoryHook>>>>
export type GetCodelistActionsHistoryQueryError = ApiError

export function useGetCodelistActionsHistory<TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistActionsHistoryHook>>>, TError = ApiError>(
    code: string,
    type: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistActionsHistoryHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetCodelistActionsHistoryQueryOptions(code, type, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useDownloadInternalCodelistRequestHook = () => {
    const downloadInternalCodelistRequest = useCodeListRepoSwaggerClient<Blob>()

    return useCallback(
        (code: string, params?: DownloadInternalCodelistRequestParams, signal?: AbortSignal) => {
            return downloadInternalCodelistRequest({
                url: `/codelists/codelistheaders/${code}/downloadrequest`,
                method: 'GET',
                params,
                responseType: 'blob',
                signal,
            })
        },
        [downloadInternalCodelistRequest],
    )
}

export const getDownloadInternalCodelistRequestQueryKey = (code: string, params?: DownloadInternalCodelistRequestParams) => {
    return [`/codelists/codelistheaders/${code}/downloadrequest`, ...(params ? [params] : [])] as const
}

export const useDownloadInternalCodelistRequestQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useDownloadInternalCodelistRequestHook>>>,
    TError = ApiError,
>(
    code: string,
    params?: DownloadInternalCodelistRequestParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useDownloadInternalCodelistRequestHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getDownloadInternalCodelistRequestQueryKey(code, params)

    const downloadInternalCodelistRequest = useDownloadInternalCodelistRequestHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useDownloadInternalCodelistRequestHook>>>> = ({ signal }) =>
        downloadInternalCodelistRequest(code, params, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useDownloadInternalCodelistRequestHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type DownloadInternalCodelistRequestQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDownloadInternalCodelistRequestHook>>>>
export type DownloadInternalCodelistRequestQueryError = ApiError

export function useDownloadInternalCodelistRequest<
    TData = Awaited<ReturnType<ReturnType<typeof useDownloadInternalCodelistRequestHook>>>,
    TError = ApiError,
>(
    code: string,
    params?: DownloadInternalCodelistRequestParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useDownloadInternalCodelistRequestHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useDownloadInternalCodelistRequestQueryOptions(code, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useDownloadExternalCodelistHook = () => {
    const downloadExternalCodelist = useCodeListRepoSwaggerClient<DownloadExternalCodelist200>()

    return useCallback(
        (code: string, signal?: AbortSignal) => {
            return downloadExternalCodelist({ url: `/codelists/codelistheaders/${code}/downloadexternal`, method: 'GET', signal })
        },
        [downloadExternalCodelist],
    )
}

export const getDownloadExternalCodelistQueryKey = (code: string) => {
    return [`/codelists/codelistheaders/${code}/downloadexternal`] as const
}

export const useDownloadExternalCodelistQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useDownloadExternalCodelistHook>>>,
    TError = ApiError,
>(
    code: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useDownloadExternalCodelistHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getDownloadExternalCodelistQueryKey(code)

    const downloadExternalCodelist = useDownloadExternalCodelistHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useDownloadExternalCodelistHook>>>> = ({ signal }) =>
        downloadExternalCodelist(code, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useDownloadExternalCodelistHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type DownloadExternalCodelistQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDownloadExternalCodelistHook>>>>
export type DownloadExternalCodelistQueryError = ApiError

export function useDownloadExternalCodelist<TData = Awaited<ReturnType<ReturnType<typeof useDownloadExternalCodelistHook>>>, TError = ApiError>(
    code: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useDownloadExternalCodelistHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useDownloadExternalCodelistQueryOptions(code, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useDownloadInternalCodelistHook = () => {
    const downloadInternalCodelist = useCodeListRepoSwaggerClient<Blob>()

    return useCallback(
        (code: string, params?: DownloadInternalCodelistParams, signal?: AbortSignal) => {
            return downloadInternalCodelist({
                url: `/codelists/codelistheaders/${code}/download`,
                method: 'GET',
                params,
                responseType: 'blob',
                signal,
            })
        },
        [downloadInternalCodelist],
    )
}

export const getDownloadInternalCodelistQueryKey = (code: string, params?: DownloadInternalCodelistParams) => {
    return [`/codelists/codelistheaders/${code}/download`, ...(params ? [params] : [])] as const
}

export const useDownloadInternalCodelistQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useDownloadInternalCodelistHook>>>,
    TError = ApiError,
>(
    code: string,
    params?: DownloadInternalCodelistParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useDownloadInternalCodelistHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getDownloadInternalCodelistQueryKey(code, params)

    const downloadInternalCodelist = useDownloadInternalCodelistHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useDownloadInternalCodelistHook>>>> = ({ signal }) =>
        downloadInternalCodelist(code, params, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useDownloadInternalCodelistHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type DownloadInternalCodelistQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDownloadInternalCodelistHook>>>>
export type DownloadInternalCodelistQueryError = ApiError

export function useDownloadInternalCodelist<TData = Awaited<ReturnType<ReturnType<typeof useDownloadInternalCodelistHook>>>, TError = ApiError>(
    code: string,
    params?: DownloadInternalCodelistParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useDownloadInternalCodelistHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useDownloadInternalCodelistQueryOptions(code, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetCodelistRequestItemsHook = () => {
    const getCodelistRequestItems = useCodeListRepoSwaggerClient<ApiCodelistItemList>()

    return useCallback(
        (code: number, params: GetCodelistRequestItemsParams, signal?: AbortSignal) => {
            return getCodelistRequestItems({ url: `/codelists/codelistheaders/${code}/codelistrequestitems`, method: 'GET', params, signal })
        },
        [getCodelistRequestItems],
    )
}

export const getGetCodelistRequestItemsQueryKey = (code: number, params: GetCodelistRequestItemsParams) => {
    return [`/codelists/codelistheaders/${code}/codelistrequestitems`, ...(params ? [params] : [])] as const
}

export const useGetCodelistRequestItemsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestItemsHook>>>,
    TError = ApiError,
>(
    code: number,
    params: GetCodelistRequestItemsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestItemsHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCodelistRequestItemsQueryKey(code, params)

    const getCodelistRequestItems = useGetCodelistRequestItemsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestItemsHook>>>> = ({ signal }) =>
        getCodelistRequestItems(code, params, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestItemsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetCodelistRequestItemsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestItemsHook>>>>
export type GetCodelistRequestItemsQueryError = ApiError

export function useGetCodelistRequestItems<TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestItemsHook>>>, TError = ApiError>(
    code: number,
    params: GetCodelistRequestItemsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistRequestItemsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetCodelistRequestItemsQueryOptions(code, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTemporalCodelistItemWithLockHook = () => {
    const getTemporalCodelistItemWithLock = useCodeListRepoSwaggerClient<ApiCodelistItem>()

    return useCallback(
        (code: string, itemCode: string, signal?: AbortSignal) => {
            return getTemporalCodelistItemWithLock({
                url: `/codelists/codelistheaders/${code}/codelistitems/${itemCode}/temporalWithLock`,
                method: 'GET',
                signal,
            })
        },
        [getTemporalCodelistItemWithLock],
    )
}

export const getGetTemporalCodelistItemWithLockQueryKey = (code: string, itemCode: string) => {
    return [`/codelists/codelistheaders/${code}/codelistitems/${itemCode}/temporalWithLock`] as const
}

export const useGetTemporalCodelistItemWithLockQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistItemWithLockHook>>>,
    TError = ApiError,
>(
    code: string,
    itemCode: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistItemWithLockHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTemporalCodelistItemWithLockQueryKey(code, itemCode)

    const getTemporalCodelistItemWithLock = useGetTemporalCodelistItemWithLockHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistItemWithLockHook>>>> = ({ signal }) =>
        getTemporalCodelistItemWithLock(code, itemCode, signal)

    return { queryKey, queryFn, enabled: !!(code && itemCode), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistItemWithLockHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetTemporalCodelistItemWithLockQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistItemWithLockHook>>>>
export type GetTemporalCodelistItemWithLockQueryError = ApiError

export function useGetTemporalCodelistItemWithLock<
    TData = Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistItemWithLockHook>>>,
    TError = ApiError,
>(
    code: string,
    itemCode: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTemporalCodelistItemWithLockHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetTemporalCodelistItemWithLockQueryOptions(code, itemCode, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUnlockTemporalCodelistItemHook = () => {
    const unlockTemporalCodelistItem = useCodeListRepoSwaggerClient<UnlockTemporalCodelistItem200>()

    return useCallback(
        (code: string, itemCode: string) => {
            return unlockTemporalCodelistItem({
                url: `/codelists/codelistheaders/${code}/codelistitems/${itemCode}/temporalWithLock`,
                method: 'DELETE',
            })
        },
        [unlockTemporalCodelistItem],
    )
}

export const useUnlockTemporalCodelistItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUnlockTemporalCodelistItemHook>>>,
        TError,
        { code: string; itemCode: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUnlockTemporalCodelistItemHook>>>,
    TError,
    { code: string; itemCode: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const unlockTemporalCodelistItem = useUnlockTemporalCodelistItemHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUnlockTemporalCodelistItemHook>>>,
        { code: string; itemCode: string }
    > = (props) => {
        const { code, itemCode } = props ?? {}

        return unlockTemporalCodelistItem(code, itemCode)
    }

    return { mutationFn, ...mutationOptions }
}

export type UnlockTemporalCodelistItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUnlockTemporalCodelistItemHook>>>>

export type UnlockTemporalCodelistItemMutationError = ApiError

export const useUnlockTemporalCodelistItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUnlockTemporalCodelistItemHook>>>,
        TError,
        { code: string; itemCode: string },
        TContext
    >
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useUnlockTemporalCodelistItemHook>>>,
    TError,
    { code: string; itemCode: string },
    TContext
> => {
    const mutationOptions = useUnlockTemporalCodelistItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExistTemporalCodelistItemHook = () => {
    const existTemporalCodelistItem = useCodeListRepoSwaggerClient<ExistTemporalCodelistItem200>()

    return useCallback(
        (code: string, itemCode: string, signal?: AbortSignal) => {
            return existTemporalCodelistItem({
                url: `/codelists/codelistheaders/${code}/codelistitems/${itemCode}/temporal/exist`,
                method: 'GET',
                signal,
            })
        },
        [existTemporalCodelistItem],
    )
}

export const getExistTemporalCodelistItemQueryKey = (code: string, itemCode: string) => {
    return [`/codelists/codelistheaders/${code}/codelistitems/${itemCode}/temporal/exist`] as const
}

export const useExistTemporalCodelistItemQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useExistTemporalCodelistItemHook>>>,
    TError = ApiError,
>(
    code: string,
    itemCode: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useExistTemporalCodelistItemHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getExistTemporalCodelistItemQueryKey(code, itemCode)

    const existTemporalCodelistItem = useExistTemporalCodelistItemHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useExistTemporalCodelistItemHook>>>> = ({ signal }) =>
        existTemporalCodelistItem(code, itemCode, signal)

    return { queryKey, queryFn, enabled: !!(code && itemCode), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useExistTemporalCodelistItemHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type ExistTemporalCodelistItemQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExistTemporalCodelistItemHook>>>>
export type ExistTemporalCodelistItemQueryError = ApiError

export function useExistTemporalCodelistItem<TData = Awaited<ReturnType<ReturnType<typeof useExistTemporalCodelistItemHook>>>, TError = ApiError>(
    code: string,
    itemCode: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useExistTemporalCodelistItemHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useExistTemporalCodelistItemQueryOptions(code, itemCode, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetOriginalCodelistItemHook = () => {
    const getOriginalCodelistItem = useCodeListRepoSwaggerClient<ApiCodelistItem>()

    return useCallback(
        (code: string, itemCode: string, signal?: AbortSignal) => {
            return getOriginalCodelistItem({ url: `/codelists/codelistheaders/${code}/codelistitems/${itemCode}/original`, method: 'GET', signal })
        },
        [getOriginalCodelistItem],
    )
}

export const getGetOriginalCodelistItemQueryKey = (code: string, itemCode: string) => {
    return [`/codelists/codelistheaders/${code}/codelistitems/${itemCode}/original`] as const
}

export const useGetOriginalCodelistItemQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetOriginalCodelistItemHook>>>,
    TError = ApiError,
>(
    code: string,
    itemCode: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOriginalCodelistItemHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetOriginalCodelistItemQueryKey(code, itemCode)

    const getOriginalCodelistItem = useGetOriginalCodelistItemHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOriginalCodelistItemHook>>>> = ({ signal }) =>
        getOriginalCodelistItem(code, itemCode, signal)

    return { queryKey, queryFn, enabled: !!(code && itemCode), ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetOriginalCodelistItemHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetOriginalCodelistItemQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOriginalCodelistItemHook>>>>
export type GetOriginalCodelistItemQueryError = ApiError

export function useGetOriginalCodelistItem<TData = Awaited<ReturnType<ReturnType<typeof useGetOriginalCodelistItemHook>>>, TError = ApiError>(
    code: string,
    itemCode: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOriginalCodelistItemHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetOriginalCodelistItemQueryOptions(code, itemCode, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetCodelistHeaderHook = () => {
    const getCodelistHeader = useCodeListRepoSwaggerClient<ApiCodelistPreview>()

    return useCallback(
        (codelistId: number, signal?: AbortSignal) => {
            return getCodelistHeader({ url: `/codelists/codelistheaders/${codelistId}`, method: 'GET', signal })
        },
        [getCodelistHeader],
    )
}

export const getGetCodelistHeaderQueryKey = (codelistId: number) => {
    return [`/codelists/codelistheaders/${codelistId}`] as const
}

export const useGetCodelistHeaderQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistHeaderHook>>>, TError = ApiError>(
    codelistId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistHeaderHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCodelistHeaderQueryKey(codelistId)

    const getCodelistHeader = useGetCodelistHeaderHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCodelistHeaderHook>>>> = ({ signal }) =>
        getCodelistHeader(codelistId, signal)

    return { queryKey, queryFn, enabled: !!codelistId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCodelistHeaderHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetCodelistHeaderQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCodelistHeaderHook>>>>
export type GetCodelistHeaderQueryError = ApiError

export function useGetCodelistHeader<TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistHeaderHook>>>, TError = ApiError>(
    codelistId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistHeaderHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetCodelistHeaderQueryOptions(codelistId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetCodelistGraphDataHook = () => {
    const getCodelistGraphData = useCodeListRepoSwaggerClient<ApiCodelistGraphData>()

    return useCallback(
        (graphUuid: string, signal?: AbortSignal) => {
            return getCodelistGraphData({ url: `/codelists/codelistgraphdata/${graphUuid}`, method: 'GET', signal })
        },
        [getCodelistGraphData],
    )
}

export const getGetCodelistGraphDataQueryKey = (graphUuid: string) => {
    return [`/codelists/codelistgraphdata/${graphUuid}`] as const
}

export const useGetCodelistGraphDataQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistGraphDataHook>>>, TError = ApiError>(
    graphUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistGraphDataHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCodelistGraphDataQueryKey(graphUuid)

    const getCodelistGraphData = useGetCodelistGraphDataHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCodelistGraphDataHook>>>> = ({ signal }) =>
        getCodelistGraphData(graphUuid, signal)

    return { queryKey, queryFn, enabled: !!graphUuid, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCodelistGraphDataHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetCodelistGraphDataQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCodelistGraphDataHook>>>>
export type GetCodelistGraphDataQueryError = ApiError

export function useGetCodelistGraphData<TData = Awaited<ReturnType<ReturnType<typeof useGetCodelistGraphDataHook>>>, TError = ApiError>(
    graphUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCodelistGraphDataHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetCodelistGraphDataQueryOptions(graphUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetAutoincrementTypesHook = () => {
    const getAutoincrementTypes = useCodeListRepoSwaggerClient<ApiAutoincrementType[]>()

    return useCallback(
        (params?: GetAutoincrementTypesParams, signal?: AbortSignal) => {
            return getAutoincrementTypes({ url: `/codelists/autoincrementtype`, method: 'GET', params, signal })
        },
        [getAutoincrementTypes],
    )
}

export const getGetAutoincrementTypesQueryKey = (params?: GetAutoincrementTypesParams) => {
    return [`/codelists/autoincrementtype`, ...(params ? [params] : [])] as const
}

export const useGetAutoincrementTypesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAutoincrementTypesHook>>>, TError = ApiError>(
    params?: GetAutoincrementTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAutoincrementTypesHook>>>, TError, TData> },
) => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetAutoincrementTypesQueryKey(params)

    const getAutoincrementTypes = useGetAutoincrementTypesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAutoincrementTypesHook>>>> = ({ signal }) =>
        getAutoincrementTypes(params, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetAutoincrementTypesHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey }
}

export type GetAutoincrementTypesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAutoincrementTypesHook>>>>
export type GetAutoincrementTypesQueryError = ApiError

export function useGetAutoincrementTypes<TData = Awaited<ReturnType<ReturnType<typeof useGetAutoincrementTypesHook>>>, TError = ApiError>(
    params?: GetAutoincrementTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAutoincrementTypesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = useGetAutoincrementTypesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
