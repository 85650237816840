import { ButtonGroupRow, ButtonPopup, LoadingIndicator, TextHeading } from '@isdd/idsk-ui-kit'
import { ButtonLink } from '@isdd/idsk-ui-kit/button-link/ButtonLink'
import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'
import { Del } from '@isdd/idsk-ui-kit/typography/Del'
import {
    APPROVAL_PROCESS,
    ATTRIBUTE_NAME,
    PROJECT_STATE_ENUM,
    PROJEKT_STANOVISKO,
    TYPE_OF_APPROVAL_PROCESS_DEFAULT,
} from '@isdd/metais-common/api/constants'
import { ApiError, ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { useIsOwnerByGid } from '@isdd/metais-common/api/generated/iam-swagger'
import { useGetNextStates } from '@isdd/metais-common/api/generated/kris-swagger'
import { getGetDocumentQueryKey, useGetDocument, useGetDocumentHook } from '@isdd/metais-common/api/generated/wiki-swagger'
import { getChangeOwnerLabel } from '@isdd/metais-common/componentHelpers/ci/getCiDefaultMetaAttributes'
import styles from '@isdd/metais-common/components/entity-header/ciEntityHeader.module.scss'
import { PROJECT, PROJECT_STATUS, RoleEnum, ROLES } from '@isdd/metais-common/constants'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { Can } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions } from '@isdd/metais-common/hooks/permissions/useUserAbility'
import { IBulkActionResult, useBulkAction } from '@isdd/metais-common/hooks/useBulkAction'
import { ChangeOwnerBulkModal, InvalidateBulkModal, MutationFeedback, ReInvalidateBulkModal, VALIDATION_STATES } from '@isdd/metais-common/index'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    entityName: string
    entityId: string
    entityItemName: string
    entityData?: ConfigurationItemUi
    ciRoles: string[]
    isInvalidated: boolean
    refetchCi: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<ConfigurationItemUi, ApiError>>
    isRelation?: boolean
    editButton: React.ReactNode
}

export const ProjectEntityIdHeader: React.FC<Props> = ({
    entityData,
    entityId,
    entityItemName,
    ciRoles,
    isInvalidated,
    refetchCi,
    isRelation,
    editButton,
}) => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const {
        state: { token, user },
    } = useAuth()

    const isLoggedIn = !!user?.uuid

    const { data: isOwnerByGid } = useIsOwnerByGid(
        {
            gids: [entityData?.metaAttributes?.owner ?? ''],
            login: user?.login,
        },
        { query: { enabled: entityData && token !== null && isLoggedIn } },
    )
    const isOwnerOfCi = isOwnerByGid?.isOwner?.[0]?.owner
    const typeOfApprovalProcess = entityData?.attributes?.[ATTRIBUTE_NAME.EA_Profil_Projekt_schvalovaci_proces] ?? TYPE_OF_APPROVAL_PROCESS_DEFAULT
    const { data: xWikiDocData, fetchStatus: xWikiFetchStatus } = useGetDocument(
        PROJECT,
        entityId,
        PROJEKT_STANOVISKO,
        {},
        { query: { retry: false, enabled: token !== null && isLoggedIn } },
    )
    const projectStatus = entityData?.attributes?.[ATTRIBUTE_NAME.EA_Profil_Projekt_status] as string

    const userHasRoleByName = (role: string) => {
        return user?.roles.includes(role)
    }

    const { data: nexStates } = useGetNextStates(entityId, { query: { enabled: isLoggedIn } })

    const showActions = VALIDATION_STATES.includes(entityData?.attributes?.[PROJECT_STATUS] as PROJECT_STATE_ENUM)
        ? user?.roles.includes('PROJEKT_SCHVALOVATEL') || user?.roles.includes(ROLES.R_ADMIN)
        : true

    const { handleReInvalidate, handleInvalidate, errorMessage, isBulkLoading, handleConfirmProject, handleProjectReturn } = useBulkAction(isRelation)
    const [showInvalidate, setShowInvalidate] = useState<boolean>(false)
    const [showReInvalidate, setShowReInvalidate] = useState<boolean>(false)
    const [showChangeOwner, setShowChangeOwner] = useState<boolean>(false)
    const [bulkActionResult, setBulkActionResult] = useState<IBulkActionResult>()

    const handleBulkAction = (actionResult: IBulkActionResult) => {
        setBulkActionResult(actionResult)
        refetchCi()
    }

    const handleCloseBulkModal = (actionResult: IBulkActionResult, closeFunction: (value: React.SetStateAction<boolean>) => void) => {
        closeFunction(false)
        handleBulkAction(actionResult)
    }

    const isInBackState = (): boolean => {
        if (projectStatus === PROJECT_STATE_ENUM.c_stav_projektu_1) {
            return false
        } else {
            return typeOfApprovalProcess === APPROVAL_PROCESS.MANDATORY_APPROVAL ? true : !(projectStatus === PROJECT_STATE_ENUM.c_stav_projektu_12)
        }
    }

    const entityListData = entityData ? [entityData] : []

    const canProjectReturn =
        projectStatus &&
        !(!entityData || entityData?.metaAttributes?.state === 'INVALIDATED') &&
        userHasRoleByName(RoleEnum.PROJEKT_SCHVALOVATEL) &&
        isInBackState()

    const canProjectConfirm = !(!entityData || entityData?.metaAttributes?.state === 'INVALIDATED' || !isOwnerOfCi)

    const [localLoading, setLocalLoading] = useState<boolean>(false)

    const xWikiSpecialDoc = useGetDocumentHook()
    const createAndOpenSpecialDoc = () => {
        setLocalLoading(true)
        if (entityData?.uuid) {
            xWikiSpecialDoc(PROJECT, entityData?.uuid, PROJEKT_STANOVISKO, { createIfNotExists: true })
                .then((doc) => {
                    doc.xwikiUrl && window.open(doc.xwikiUrl, '_blank')
                    queryClient.invalidateQueries(getGetDocumentQueryKey(PROJECT, entityData?.uuid ?? '', PROJEKT_STANOVISKO))
                })
                .catch(() => setBulkActionResult({ isSuccess: false, isError: true }))
                .finally(() => {
                    setLocalLoading(false)
                })
        }
    }

    return (
        <>
            <MutationFeedback
                success={bulkActionResult?.isSuccess}
                successMessage={bulkActionResult?.successMessage}
                error={bulkActionResult?.isError}
                errorMessage={bulkActionResult?.errorMessage}
                onMessageClose={() => setBulkActionResult(undefined)}
            />
            <div className={styles.headerDiv}>
                {(isBulkLoading || localLoading) && <LoadingIndicator fullscreen />}
                <TextHeading size="XL">
                    <Del isInvalid={isInvalidated}>{entityItemName}</Del>
                </TextHeading>
                {isLoggedIn && showActions && (
                    <ButtonGroupRow>
                        <Can I={Actions.EDIT} a={`ci.${entityId}`}>
                            {editButton}
                        </Can>
                        <ButtonPopup
                            buttonClassName={styles.noWrap}
                            buttonLabel={t('ciType.moreButton')}
                            popupHorizontalPosition="right"
                            popupContent={(closePopup) => {
                                return (
                                    <div className={styles.buttonLinksDiv}>
                                        <Tooltip
                                            key={'invalidateItem'}
                                            descriptionElement={errorMessage}
                                            position={'top center'}
                                            tooltipContent={(open) => (
                                                <ButtonLink
                                                    disabled={isInvalidated}
                                                    onClick={() =>
                                                        handleInvalidate(
                                                            entityListData,
                                                            () => {
                                                                setShowInvalidate(true)
                                                            },
                                                            open,
                                                        )
                                                    }
                                                    label={t('ciType.invalidateItem')}
                                                    aria={{ 'aria-haspopup': 'dialog' }}
                                                />
                                            )}
                                        />

                                        <Tooltip
                                            key={'revalidateItem'}
                                            descriptionElement={errorMessage}
                                            position={'top center'}
                                            tooltipContent={(open) => (
                                                <ButtonLink
                                                    disabled={!isInvalidated}
                                                    onClick={() =>
                                                        handleReInvalidate(
                                                            entityListData,
                                                            () => {
                                                                setShowReInvalidate(true)
                                                            },
                                                            open,
                                                        )
                                                    }
                                                    label={t('ciType.revalidateItem')}
                                                    aria={{ 'aria-haspopup': 'dialog' }}
                                                />
                                            )}
                                        />

                                        <Can I={Actions.CHANGE_OWNER} a={`ci.${entityId}`}>
                                            <ButtonLink
                                                onClick={() => {
                                                    setShowChangeOwner(true)
                                                }}
                                                label={getChangeOwnerLabel(t, entityData?.type)}
                                                aria={{ 'aria-haspopup': 'dialog' }}
                                            />
                                        </Can>

                                        {canProjectConfirm &&
                                            nexStates &&
                                            nexStates.map((state, index) => (
                                                <Tooltip
                                                    key={index}
                                                    descriptionElement={errorMessage}
                                                    position={'top center'}
                                                    tooltipContent={(open) => {
                                                        return (
                                                            <ButtonLink
                                                                key={state}
                                                                onClick={() =>
                                                                    handleConfirmProject(state, entityData, handleBulkAction, open, closePopup)
                                                                }
                                                                label={t(`ciType.actions.next.${state.replace('.', '_')}`)}
                                                            />
                                                        )
                                                    }}
                                                />
                                            ))}

                                        {canProjectReturn && (
                                            <ButtonLink
                                                onClick={() => handleProjectReturn(entityId, handleBulkAction, closePopup)}
                                                label={t('ciType.actions.return.return')}
                                            />
                                        )}
                                        {!xWikiDocData && xWikiFetchStatus === 'idle' && (
                                            <Can I={Actions.APPROVE_KRIS} a={`ci.${entityId}`}>
                                                <ButtonLink
                                                    key={'buttonOpen'}
                                                    label={t('ciType.generateOpinion')}
                                                    onClick={() => createAndOpenSpecialDoc()}
                                                />
                                            </Can>
                                        )}
                                    </div>
                                )
                            }}
                        />
                    </ButtonGroupRow>
                )}
                {isBulkLoading && <LoadingIndicator fullscreen />}

                <InvalidateBulkModal
                    items={entityListData}
                    open={showInvalidate}
                    onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, setShowInvalidate)}
                    onClose={() => setShowInvalidate(false)}
                    isRelation={isRelation}
                />
                <ReInvalidateBulkModal
                    items={entityListData}
                    open={showReInvalidate}
                    onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, setShowReInvalidate)}
                    onClose={() => setShowReInvalidate(false)}
                    isRelation={isRelation}
                />
                <ChangeOwnerBulkModal
                    items={entityListData}
                    open={showChangeOwner}
                    onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, setShowChangeOwner)}
                    onClose={() => setShowChangeOwner(false)}
                    ciRoles={ciRoles}
                    isRelation={isRelation}
                />
            </div>
        </>
    )
}
