import { PRE_LOGIN_PATH } from '@isdd/metais-common/contexts/auth/authConfig'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { RouterRoutes } from '@isdd/metais-common/navigation/routeNames'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const AuthSuccessPage: React.FC = () => {
    const navigate = useNavigate()
    const loginPath = localStorage.getItem(PRE_LOGIN_PATH)
    const { state: state } = useAuth()

    useEffect(() => {
        if (!state.isTokenLoaded) {
            return
        }

        if (loginPath && !loginPath.startsWith(RouterRoutes.AUTH_SUCCESS)) {
            navigate(loginPath)
            return
        }

        navigate(RouterRoutes.HOME)
    }, [loginPath, navigate, state.isTokenLoaded])

    return <>...</>
}

export default AuthSuccessPage
