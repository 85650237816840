import { QueryFeedback } from '@isdd/metais-common/index'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth, CustomAuthActions } from '@isdd/metais-common/contexts/auth/authContext'
import { useQueryClient } from '@tanstack/react-query'

import { MainContentWrapper } from '@/components/MainContentWrapper'

export const LogoutPage: React.FC = () => {
    const logoutURL =
        import.meta.env.VITE_REST_CLIENT_IAM_OIDC_BASE_URL +
        (import.meta.env.VITE_IAM_OIDC_PATH ? `/${import.meta.env.VITE_IAM_OIDC_PATH}/logout` : '/logout')

    const ref = useRef<HTMLFormElement>(null)

    const { dispatch } = useAuth()
    const queryClient = useQueryClient()

    const { t } = useTranslation()

    useEffect(() => {
        dispatch({ type: CustomAuthActions.LOGOUT_USER })
        queryClient.clear()
        ref.current?.submit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <MainContentWrapper>
            <QueryFeedback loading withChildren indicatorProps={{ fullscreen: true, label: t('titles.logout') }}>
                <form ref={ref} action={logoutURL} />
            </QueryFeedback>
        </MainContentWrapper>
    )
}
