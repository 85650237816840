import {
    FooterRouteNames,
    LoginRouteNames,
    NavigationSubRoutes,
    RouteNames,
    RouterRoutes,
    SLARouteNames,
} from '@isdd/metais-common/navigation/routeNames'
import { useTranslation } from 'react-i18next'

import { RootRouteContainer } from './route-containers/RootRouteContainer'

import { PageNotFound } from '@/components/views/page-not-found/PageNotFound'
import { DevTestScreen } from '@/pages/DevTestScreen'
import { ForgottenPasswordPage } from '@/pages/ForgottenPasswordPage'
import { Home } from '@/pages/Home'
import { IdentityTermsPage } from '@/pages/IdentityTermsPage'
import { LoginProblemsPage } from '@/pages/LoginProblemsPage'
import AboutApplicationPage from '@/pages/about-application/aboutApp'
import { BulkListPage } from '@/pages/bulk-list/BulkList'
import AsEntityDetailPage from '@/pages/ci/AS/[entityId]'
import CloneASPage from '@/pages/ci/AS/[entityId]/clone'
import AtributObjektuEvidencieEditPage from '@/pages/ci/AtributObjektuEvidencie/[entityId]/edit'
import CreateAtributObjektuEvidenciePage from '@/pages/ci/AtributObjektuEvidencie/create'
import { AtributObjektEvidencieListPage } from '@/pages/ci/AtributObjektuEvidencie/entity'
import KRISListPage from '@/pages/ci/KRIS'
import KrisEntityDetailPage from '@/pages/ci/KRIS/[entityId]'
import KRISDocumentsListPage from '@/pages/ci/KRIS/[entityId]/documents'
import Evaluation from '@/pages/ci/KRIS/[entityId]/evaluation'
import Goals from '@/pages/ci/KRIS/[entityId]/goals'
import Tasks from '@/pages/ci/KRIS/[entityId]/tasks'
import CreateKrisEntityPage from '@/pages/ci/KRIS/create'
import KsEntityDetailPage from '@/pages/ci/KS/[entityId]'
import { SlaContractDetailPage } from '@/pages/ci/Kontrakt/[entityId]'
import { SlaContractHistory } from '@/pages/ci/Kontrakt/[entityId]/history'
import { SlaContractInformation } from '@/pages/ci/Kontrakt/[entityId]/information'
import { SlaContractSupportContact } from '@/pages/ci/Kontrakt/[entityId]/supportContact'
import { SlaContractList } from '@/pages/ci/Kontrakt/list'
import PlaceOfOperationEntityListPage from '@/pages/ci/MiestoPrevadzky'
import PlaceOfOperationEntityDetailPage from '@/pages/ci/MiestoPrevadzky/[entityId].tsx/[entityId]'
import OlaContractDetailPage from '@/pages/ci/OLA_Kontrakt/[entityId]'
import { OlaContractEdit } from '@/pages/ci/OLA_Kontrakt/[entityId]/edit'
import { OlaContractDetail } from '@/pages/ci/OLA_Kontrakt/[entityId]/information'
import { OlaContractCreate } from '@/pages/ci/OLA_Kontrakt/create'
import { EditEvidenceObject } from '@/pages/ci/ObjektEvidencie/[entityId]/edit'
import { CreateEvidenceObject } from '@/pages/ci/ObjektEvidencie/create'
import { ObjektEvidencieListPage } from '@/pages/ci/ObjektEvidencie/entity'
import ITVSExceptionsEditPage from '@/pages/ci/OsobitnyPostup/[entityId]/edit'
import ITVSExceptionsInformation from '@/pages/ci/OsobitnyPostup/[entityId]/information'
import CreateITVSExceptionsPage from '@/pages/ci/OsobitnyPostup/create'
import { ITVSExceptionListPage } from '@/pages/ci/OsobitnyPostup/entity'
import POIsListPage from '@/pages/ci/PO_IS'
import PO_ISEntityDetailPage from '@/pages/ci/PO_IS/[entityId]'
import PO_ISInformationOutlet from '@/pages/ci/PO_IS/[entityId]/information'
import POIsPOListPage from '@/pages/ci/PO_IS_PO'
import PO_IS_POEntityDetailPage from '@/pages/ci/PO_IS_PO/[entityId]'
import PO_IS_POInformationOutlet from '@/pages/ci/PO_IS_PO/[entityId]/information'
import POPOListPage from '@/pages/ci/PO_PO'
import PO_POEntityDetailPage from '@/pages/ci/PO_PO/[entityId]'
import PO_PODocumentsOutlet from '@/pages/ci/PO_PO/[entityId]/documents'
import PO_POHistoryOutlet from '@/pages/ci/PO_PO/[entityId]/history'
import PO_POInformationOutlet from '@/pages/ci/PO_PO/[entityId]/information'
import PO_PORelationshipOutlet from '@/pages/ci/PO_PO/[entityId]/relationships'
import PrincipleEntityDetailPage from '@/pages/ci/Princip/[entityId]'
import ProjectEntityDetailPage from '@/pages/ci/Projekt/[entityId]'
import ActivitiesListPage from '@/pages/ci/Projekt/[entityId]/activities'
import ProjectDocumentsListPage from '@/pages/ci/Projekt/[entityId]/documents'
import EditProjectPage from '@/pages/ci/Projekt/[entityId]/edit'
import CreateProjectPage from '@/pages/ci/Projekt/create'
import ReferenceRegisterDetail from '@/pages/ci/ReferenceRegister'
import TrainingEntityDetailPage from '@/pages/ci/Trainings/[entityId]'
import EditTrainingEntityPage from '@/pages/ci/Trainings/[entityId]/edit'
import TrainingInformation from '@/pages/ci/Trainings/[entityId]/information'
import TrainingInvitePage from '@/pages/ci/Trainings/[entityId]/invite'
import CreateTrainingEntityPage from '@/pages/ci/Trainings/create'
import { TrainingListPage } from '@/pages/ci/Trainings/entity'
import ZCEntityDetailPage from '@/pages/ci/ZC/[entityId]'
import ZCListPage from '@/pages/ci/ZC/entity'
import EntityDetailPage from '@/pages/ci/[entityName]/[entityId]'
import DocumentsListPage from '@/pages/ci/[entityName]/[entityId]/documents'
import EditEntityPage from '@/pages/ci/[entityName]/[entityId]/edit'
import History from '@/pages/ci/[entityName]/[entityId]/history'
import CompareSinglePage from '@/pages/ci/[entityName]/[entityId]/history/[firstId]'
import ComparePage from '@/pages/ci/[entityName]/[entityId]/history/[firstId]/[secondId]'
import Information from '@/pages/ci/[entityName]/[entityId]/information'
import CreateProjektItemAndRelation from '@/pages/ci/[entityName]/[entityId]/new-ci/Projekt'
import CreateCiItemAndRelation from '@/pages/ci/[entityName]/[entityId]/new-ci/[tabName]'
import NewCiRelationPage from '@/pages/ci/[entityName]/[entityId]/new-relation/[tabName]'
import RelationshipsAccordionPage from '@/pages/ci/[entityName]/[entityId]/relationships'
import CreateEntityPage from '@/pages/ci/[entityName]/create'
import CiListPage from '@/pages/ci/[entityName]/entity'
import { Contact } from '@/pages/contact/Contact'
import CookiesInfoPage from '@/pages/cookies/info'
import PersonalDataInfoPage from '@/pages/cookies/personalDataProtection'
import CookiesSettings from '@/pages/cookies/settings'
import TermsOfUse from '@/pages/cookies/termsOfUse'
import CodeListDetailPage from '@/pages/data-objects/codelists/[id]/detail'
import EditCodeListPage from '@/pages/data-objects/codelists/[id]/edit'
import CodeListPage from '@/pages/data-objects/codelists/list'
import CodeListHierarchyPage from '@/pages/data-objects/hierarchy-codelists'
import RefIdentifiersPage from '@/pages/data-objects/ref-identifiers'
import RefIdentifierDetailPage from '@/pages/data-objects/ref-identifiers/[id]/detail'
import RefIdentifierEditPage from '@/pages/data-objects/ref-identifiers/[id]/edit'
import RefIdentifierCreatePage from '@/pages/data-objects/ref-identifiers/create'
import RequestListDetailPage from '@/pages/data-objects/requestlist/[requestId]/detail'
import RequestListEditPage from '@/pages/data-objects/requestlist/[requestId]/edit'
import RequestListCreatePage from '@/pages/data-objects/requestlist/create'
import RequestListPage from '@/pages/data-objects/requestlist/requestList'
import GlobalSearchPage from '@/pages/global/search/search'
import GeneralHowTo from '@/pages/howto'
import HowToGenericPage from '@/pages/howto/[howToEnumType]'
import HowToMonitoringPage from '@/pages/howto/monitoringHowTo'
import ImportParametersPage from '@/pages/monitoring/import'
import DetailServicePage from '@/pages/monitoring/services/monitoras/[serviceUuid]'
import InsertServicePage from '@/pages/monitoring/services/monitoras/insert/[serviceUuid]'
import ServicesListPage from '@/pages/monitoring/services/services'
import NotificationsPage from '@/pages/notifications'
import NotificationsDetailPage from '@/pages/notifications/[id]'
import PublicAuthoritiesHierarchyPage from '@/pages/public-authorities-hierarchy'
import RefRegistersDetail from '@/pages/refregisters/[entityId]'
import RefRegistersEdit from '@/pages/refregisters/[entityId]/edit'
import RefRegistersHistory from '@/pages/refregisters/[entityId]/history'
import RefRegistersCompareSinglePage from '@/pages/refregisters/[entityId]/history/[firstId]'
import RefRegistersComparePage from '@/pages/refregisters/[entityId]/history/[firstId]/[secondId]'
import RefRegistersHistoryChanges from '@/pages/refregisters/[entityId]/historyChanges'
import RefRegistersInformation from '@/pages/refregisters/[entityId]/information'
import RefRegistersCreate from '@/pages/refregisters/create'
import ReferenceRegisters from '@/pages/refregisters/refRegisterList'
import Failed from '@/pages/registration/failed'
import Registration from '@/pages/registration/registration'
import Success from '@/pages/registration/success'
import RelationDetailPage from '@/pages/relation/[entityName]/[entityId]/[relationshipId]'
import RelationListPage from '@/pages/relations'
import { ReportsDetailPage } from '@/pages/reports/[entityId]/report'
import ReportsListPage from '@/pages/reports/reports'
import SiteMapPage from '@/pages/siteMap'
import SLADetailPage from '@/pages/sla-detail'
import SLAParamsListPage from '@/pages/sla-params-list/[entityName]'
import { SpravcaObsahu } from '@/pages/spravca-obsahu/SpravcaObsahu'
import DraftsListEditPage from '@/pages/standardization/draftslist/[entityId]/edit'
import DraftDetail from '@/pages/standardization/draftslist/[entityId]/form'
import DraftsListCreatePage from '@/pages/standardization/draftslist/create'
import DraftsListListPage from '@/pages/standardization/draftslist/list'
import GroupsListPage from '@/pages/standardization/groupslist'
import GroupDetailPage from '@/pages/standardization/groupslist/[groupId]'
import GroupEditPage from '@/pages/standardization/groupslist/[groupId]/edit'
import CreateGroupPage from '@/pages/standardization/groupslist/create'
import GroupItvsDetailPage from '@/pages/standardization/groupslist/itvs'
import GroupEditItvsPage from '@/pages/standardization/groupslist/itvs/edit'
import MeetingsListPage from '@/pages/standardization/meetingslist'
import MeetingDetailPage from '@/pages/standardization/meetingslist/[meetingId]'
import MeetingEditPage from '@/pages/standardization/meetingslist/[meetingId]/edit'
import CreateMeetingPage from '@/pages/standardization/meetingslist/create'
import VotesListPage from '@/pages/standardization/voteslist'
import VoteDetailPage from '@/pages/standardization/voteslist/[voteIdParam]'
import VoteEditPage from '@/pages/standardization/voteslist/[voteIdParam]/edit'
import VoteCreatePage from '@/pages/standardization/voteslist/create'
import { TechnicalOperator } from '@/pages/technical-operator/TechnicalOperator'
import DeclarationPage from '@/pages/technical/declaration'
import TasksPage from '@/pages/ulohy'
import TaskDetailPage from '@/pages/ulohy/[taskId]'
import UserProfilePage from '@/pages/userprofile/profile'
import KSHelper from '@/pages/ks-helper'
import OpenDataPage from '@/pages/opendata'
import RefIdentifiersRequestsPage from '@/pages/data-objects/ref-identifiers-requests'
import ISVSDetailPage from '@/pages/ci/ISVS/[entityId]'
import DeclarationAccordionPage from '@/pages/ci/ISVS/[entityId]/declaration'
import IsvsInformation from '@/pages/ci/ISVS/[entityId]/information'
import ISVSFinancesPage from '@/pages/ci/ISVS/[entityId]/finances'
import CreateFormularPage from '@/pages/ci/Formular/create'
import EditFormularEntityPage from '@/pages/ci/Formular/[entityId]/edit'
import ObjektEvidencieInformation from '@/pages/ci/ObjektEvidencie/[entityId]/information'
import AttributeRecordObjectInformation from '@/pages/ci/AtributObjektuEvidencie/[entityId]/information'
import AtributObjektuEvidancieEntityDetailPage from '@/pages/ci/AtributObjektuEvidencie/[entityId]'
import ObjectEvidencieDetailPage from '@/pages/ci/ObjektEvidencie'
import RefIdentifierRequestDetailPage from '@/pages/data-objects/ref-identifiers-requests/[id]/detail'
import RefIdentifierRequestEditPage from '@/pages/data-objects/ref-identifiers-requests/[id]/edit'
import AuthSuccessPage from '@/pages/auth-success'
import { LogoutPage } from '@/pages/LogoutPage'

export interface RouteConfig {
    path?: string
    component: React.FC
    slug?: string
    index?: boolean
    subRoutes?: RouteConfig[]
    pageDefaultTitle: string
}

const PO_POInfoOutlet = (): RouteConfig => {
    const { t } = useTranslation()
    return {
        slug: RouterRoutes.CI_PO_PO_DETAIL,
        component: PO_POInformationOutlet,
        index: true,
        pageDefaultTitle: t('titles.ciDetailDefault'),
    }
}
const PO_ISInfoOutlet = (): RouteConfig => {
    const { t } = useTranslation()
    return {
        slug: RouterRoutes.CI_PO_IS_DETAIL,
        component: PO_ISInformationOutlet,
        index: true,
        pageDefaultTitle: t('titles.ciDetailDefault'),
    }
}
const PO_IS_POInfoOutlet = (): RouteConfig => {
    const { t } = useTranslation()
    return {
        slug: RouterRoutes.CI_PO_IS_PO_DETAIL,
        component: PO_IS_POInformationOutlet,
        index: true,
        pageDefaultTitle: t('titles.ciDetailDefault'),
    }
}

const POOutlets = (): RouteConfig[] => {
    const { t } = useTranslation()
    return [
        {
            path: RouterRoutes.DOCUMENTS_OUTLET,
            slug: RouterRoutes.DOCUMENTS_OUTLET,
            component: PO_PODocumentsOutlet,
            pageDefaultTitle: t('titles.ciDetailDefault'),
        },
        {
            path: RouterRoutes.RELATIONSHIPS_OUTLET,
            slug: RouterRoutes.RELATIONSHIPS_OUTLET,
            component: PO_PORelationshipOutlet,
            pageDefaultTitle: t('titles.ciDetailDefault'),
        },
        {
            path: RouterRoutes.HISTORY_OUTLET,
            slug: RouterRoutes.HISTORY_OUTLET,
            component: PO_POHistoryOutlet,
            pageDefaultTitle: t('titles.ciDetailDefault'),
        },
    ]
}

const GeneralCiDetailInformationOutlet = (): RouteConfig => {
    const { t } = useTranslation()
    return {
        slug: RouterRoutes.CI_DETAIL,
        component: Information,
        index: true,
        pageDefaultTitle: t('titles.ciDetailDefault'),
    }
}

const GeneralCiDetailOutlets = (): RouteConfig[] => {
    const { t } = useTranslation()
    return [
        GeneralCiDetailInformationOutlet(),
        {
            path: RouterRoutes.DOCUMENTS_OUTLET,
            slug: RouterRoutes.DOCUMENTS_OUTLET,
            component: DocumentsListPage,
            pageDefaultTitle: t('titles.ciDetailDefault'),
        },
        {
            path: RouterRoutes.RELATIONSHIPS_OUTLET,
            slug: RouterRoutes.RELATIONSHIPS_OUTLET,
            component: RelationshipsAccordionPage,
            pageDefaultTitle: t('titles.ciDetailDefault'),
        },
        {
            path: RouterRoutes.HISTORY_OUTLET,
            slug: RouterRoutes.HISTORY_OUTLET,
            component: History,
            pageDefaultTitle: t('titles.ciDetailDefault'),
        },
    ]
}

const GeneralCiRoutes = (): RouteConfig[] => {
    const { t } = useTranslation()
    return [
        {
            path: RouterRoutes.CI_LIST,
            slug: RouterRoutes.CI_LIST,
            component: CiListPage,
            pageDefaultTitle: t('titles.ciList', { ci: '' }),
        },
        {
            path: RouterRoutes.CI_CREATE,
            slug: RouterRoutes.CI_CREATE,
            component: CreateEntityPage,
            pageDefaultTitle: t('titles.ciCreateEntity', { ci: '' }),
        },
        {
            path: RouterRoutes.CI_EDIT,
            slug: RouterRoutes.CI_EDIT,
            component: EditEntityPage,
            pageDefaultTitle: t('titles.ciEdit', { ci: '' }),
        },
        {
            path: RouterRoutes.CI_AS_CLONE,
            slug: RouterRoutes.CI_AS_CLONE,
            component: CloneASPage,
            pageDefaultTitle: t('titles.ciCloneDefault'),
        },
        {
            path: RouterRoutes.KS_PUBLISH_PAGE,
            slug: RouterRoutes.KS_PUBLISH_PAGE,
            component: KSHelper,
            pageDefaultTitle: t('KSPublishHelper.button'),
        },
        {
            path: RouterRoutes.CI_DETAIL,
            slug: RouterRoutes.CI_DETAIL,
            component: EntityDetailPage,
            subRoutes: GeneralCiDetailOutlets(),
            pageDefaultTitle: t('titles.ciDetailDefault'),
        },
        {
            path: RouterRoutes.HISTORY_COMPARE_SINGLE_ITEM,
            slug: RouterRoutes.HISTORY_COMPARE_SINGLE_ITEM,
            component: CompareSinglePage,
            pageDefaultTitle: t('breadcrumbs.compareHistory'),
        },
        {
            path: RouterRoutes.HISTORY_COMPARE_TWO_ITEMS,
            slug: RouterRoutes.HISTORY_COMPARE_TWO_ITEMS,
            component: ComparePage,
            pageDefaultTitle: t('breadcrumbs.compareHistory'),
        },
        {
            path: RouterRoutes.CI_CREATE_ITEM_RELATION,
            slug: RouterRoutes.CI_CREATE_ITEM_RELATION,
            component: CreateCiItemAndRelation,
            pageDefaultTitle: t('breadcrumbs.newCiAndRelation'),
        },
        {
            path: RouterRoutes.CI_CREATE_PROJEKT_RELATION,
            slug: RouterRoutes.CI_CREATE_PROJEKT_RELATION,
            component: CreateProjektItemAndRelation,
            pageDefaultTitle: t('breadcrumbs.newCiAndRelation'),
        },
        {
            path: RouterRoutes.CI_CREATE_RELATION,
            slug: RouterRoutes.CI_CREATE_RELATION,
            component: NewCiRelationPage,
            pageDefaultTitle: t('breadcrumbs.newRelation'),
        },
    ]
}

export const RoutesConfig = (): RouteConfig[] => {
    const { t } = useTranslation()
    return [
        {
            slug: RouterRoutes.HOME,
            path: RouterRoutes.HOME,
            component: RootRouteContainer,
            pageDefaultTitle: t('titles.mainPage'),
            subRoutes: [
                {
                    index: true,
                    component: Home,
                    pageDefaultTitle: t('titles.mainPage'),
                },
                {
                    path: RouterRoutes.AUTH_SUCCESS,
                    slug: RouterRoutes.AUTH_SUCCESS,
                    component: AuthSuccessPage,
                    pageDefaultTitle: t('titles.mainPage'),
                },
                {
                    path: RouterRoutes.LOGOUT,
                    slug: RouterRoutes.LOGOUT,
                    component: LogoutPage,
                    pageDefaultTitle: t('titles.logoutPage'),
                },
                ...GeneralCiRoutes(),
                {
                    path: RouterRoutes.IDENTITY_TERMS,
                    slug: RouterRoutes.IDENTITY_TERMS,
                    component: IdentityTermsPage,
                    pageDefaultTitle: t('LicenseAgreement.Title'),
                },
                {
                    path: RouterRoutes.DEV_TEST_SCREEN,
                    slug: RouterRoutes.DEV_TEST_SCREEN,
                    component: DevTestScreen,
                    pageDefaultTitle: 'TEST',
                },
                {
                    path: RouterRoutes.USER_PROFILE,
                    slug: RouterRoutes.USER_PROFILE,
                    component: UserProfilePage,
                    pageDefaultTitle: t('userProfile.heading'),
                },
                {
                    path: RouterRoutes.TASKS,
                    slug: RouterRoutes.TASKS,
                    component: TasksPage,
                    pageDefaultTitle: t('titles.myTasks'),
                },
                {
                    path: RouterRoutes.TASK_DETAIL,
                    slug: RouterRoutes.TASK_DETAIL,
                    component: TaskDetailPage,
                    pageDefaultTitle: t('titles.taskDetail'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_VOTE_LIST,
                    slug: RouterRoutes.STANDARDIZATION_VOTE_LIST,
                    component: VotesListPage,
                    pageDefaultTitle: t('votes.breadcrumbs.VotesLists'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_VOTE_DETAIL,
                    slug: RouterRoutes.STANDARDIZATION_VOTE_DETAIL,
                    component: VoteDetailPage,
                    pageDefaultTitle: t('votes.voteDetail.detail'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_VOTE_DETAIL_EXTERNAL,
                    slug: RouterRoutes.STANDARDIZATION_VOTE_DETAIL_EXTERNAL,
                    component: VoteDetailPage,
                    pageDefaultTitle: t('votes.voteDetail.detail'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_VOTE_DETAIL_EXTERNAL_VETO,
                    slug: RouterRoutes.STANDARDIZATION_VOTE_DETAIL_EXTERNAL_VETO,
                    component: VoteDetailPage,
                    pageDefaultTitle: t('votes.voteDetail.detail'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_VOTE_CREATE,
                    slug: RouterRoutes.STANDARDIZATION_VOTE_CREATE,
                    component: VoteCreatePage,
                    pageDefaultTitle: t('votes.voteEdit.newVoteTitle'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_VOTE_EDIT,
                    slug: RouterRoutes.STANDARDIZATION_VOTE_EDIT,
                    component: VoteEditPage,
                    pageDefaultTitle: t('votes.voteEdit.editVoteTitle'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_MEETINGS_LIST,
                    slug: RouterRoutes.STANDARDIZATION_MEETINGS_LIST,
                    component: MeetingsListPage,
                    pageDefaultTitle: t('titles.meetings'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_MEETINGS_DETAIL,
                    slug: RouterRoutes.STANDARDIZATION_MEETINGS_DETAIL,
                    component: MeetingDetailPage,
                    pageDefaultTitle: t('titles.meetingDetail'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_MEETINGS_DETAIL_PARTICIPATE,
                    slug: RouterRoutes.STANDARDIZATION_MEETINGS_DETAIL_PARTICIPATE,
                    component: MeetingDetailPage,
                    pageDefaultTitle: t('titles.meetingDetail'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_MEETINGS_CREATE,
                    slug: RouterRoutes.STANDARDIZATION_MEETINGS_CREATE,
                    component: CreateMeetingPage,
                    pageDefaultTitle: t('titles.meetingsCreate'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_MEETINGS_EDIT,
                    slug: RouterRoutes.STANDARDIZATION_MEETINGS_EDIT,
                    component: MeetingEditPage,
                    pageDefaultTitle: t('meetings.editMeeting'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_GROUPS_LIST,
                    slug: RouterRoutes.STANDARDIZATION_GROUPS_LIST,
                    component: GroupsListPage,
                    pageDefaultTitle: t('titles.groupsOfCommission'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_GROUPS_DETAIL_ITVS,
                    slug: RouterRoutes.STANDARDIZATION_GROUPS_DETAIL_ITVS,
                    component: GroupItvsDetailPage,
                    pageDefaultTitle: t('titles.groupDetail'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_GROUPS_DETAIL,
                    slug: RouterRoutes.STANDARDIZATION_GROUPS_DETAIL,
                    component: GroupDetailPage,
                    pageDefaultTitle: t('titles.groupDetail'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_GROUPS_CREATE,
                    slug: RouterRoutes.STANDARDIZATION_GROUPS_CREATE,
                    component: CreateGroupPage,
                    pageDefaultTitle: t('titles.groupCreate'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_GROUPS_EDIT_ITVS,
                    slug: RouterRoutes.STANDARDIZATION_GROUPS_EDIT_ITVS,
                    component: GroupEditItvsPage,
                    pageDefaultTitle: t('titles.groupEdit'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_GROUPS_EDIT,
                    slug: RouterRoutes.STANDARDIZATION_GROUPS_EDIT,
                    component: GroupEditPage,
                    pageDefaultTitle: t('titles.groupEdit'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_DRAFTS_LIST,
                    slug: RouterRoutes.STANDARDIZATION_DRAFTS_LIST,
                    component: DraftsListListPage,
                    pageDefaultTitle: t('breadcrumbs.draftsList'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_DRAFTS_DETAIL,
                    slug: RouterRoutes.STANDARDIZATION_DRAFTS_DETAIL,
                    component: DraftDetail,
                    pageDefaultTitle: t('draftsList.detail'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_DRAFTS_CREATE,
                    slug: RouterRoutes.STANDARDIZATION_DRAFTS_CREATE,
                    component: DraftsListCreatePage,
                    pageDefaultTitle: t('DraftsList.createForm.heading'),
                },
                {
                    path: RouterRoutes.STANDARDIZATION_DRAFTS_EDIT,
                    slug: RouterRoutes.STANDARDIZATION_DRAFTS_EDIT,
                    component: DraftsListEditPage,
                    pageDefaultTitle: t('DraftsList.createForm.heading'),
                },
                {
                    path: RouterRoutes.REPORTS_DETAIL,
                    slug: RouterRoutes.REPORTS_DETAIL,
                    component: ReportsDetailPage,
                    pageDefaultTitle: t('titles.reportDetail'),
                },
                {
                    path: RouterRoutes.REPORTS_LIST,
                    slug: RouterRoutes.REPORTS_LIST,
                    component: ReportsListPage,
                    pageDefaultTitle: t('titles.reports'),
                },
                {
                    path: RouterRoutes.IMPORT_MONITORING_PARAMETERS,
                    slug: RouterRoutes.IMPORT_MONITORING_PARAMETERS,
                    component: ImportParametersPage,
                    pageDefaultTitle: t('navMenu.lists.monitoringImport'),
                },
                {
                    path: RouterRoutes.MONITORING_SERVICES,
                    slug: RouterRoutes.MONITORING_SERVICES,
                    component: ServicesListPage,
                    pageDefaultTitle: t('titles.monitoringServices'),
                },
                {
                    path: RouterRoutes.MONITORING_DETAIL,
                    slug: RouterRoutes.MONITORING_DETAIL,
                    component: DetailServicePage,
                    pageDefaultTitle: t('titles.monitoringServicesDetail'),
                },
                {
                    path: RouterRoutes.MONITORING_INSERT,
                    slug: RouterRoutes.MONITORING_INSERT,
                    component: InsertServicePage,
                    pageDefaultTitle: t('titles.monitoringInsert'),
                },
                {
                    path: RouterRoutes.REPORTS_CREATE,
                    slug: RouterRoutes.REPORTS_CREATE,
                    component: DraftsListCreatePage,
                    pageDefaultTitle: t('DraftsList.createForm.heading'),
                },
                {
                    path: RouterRoutes.RELATIONS,
                    slug: RouterRoutes.RELATIONS,
                    component: RelationDetailPage,
                    pageDefaultTitle: t('relationDetail.heading'),
                },
                {
                    path: RouterRoutes.REGISTRATION,
                    slug: RouterRoutes.REGISTRATION,
                    component: Registration,
                    pageDefaultTitle: t('registration.title'),
                },
                {
                    path: RouterRoutes.REGISTRATION_SUCCESS,
                    slug: RouterRoutes.REGISTRATION_SUCCESS,
                    component: Success,
                    pageDefaultTitle: t('registration.title'),
                },
                {
                    path: RouterRoutes.REGISTRATION_FAILED,
                    slug: RouterRoutes.REGISTRATION_FAILED,
                    component: Failed,
                    pageDefaultTitle: t('registration.title'),
                },
                {
                    path: RouterRoutes.PUBLIC_AUTHORITIES_HIERARCHY,
                    slug: RouterRoutes.PUBLIC_AUTHORITIES_HIERARCHY,
                    component: PublicAuthoritiesHierarchyPage,
                    pageDefaultTitle: t('titles.publicAuthoritiesHierarchy'),
                },
                {
                    path: RouterRoutes.NOTIFICATIONS,
                    slug: RouterRoutes.NOTIFICATIONS,
                    component: NotificationsPage,
                    pageDefaultTitle: t('titles.notifications'),
                },
                {
                    path: RouterRoutes.NOTIFICATIONS_DETAIL,
                    slug: RouterRoutes.NOTIFICATIONS_DETAIL,
                    component: NotificationsDetailPage,
                    pageDefaultTitle: t('titles.notificationDetail'),
                },
                {
                    path: RouterRoutes.HOW_TO_GENERAL_PAGE,
                    slug: RouterRoutes.HOW_TO_GENERAL_PAGE,
                    component: GeneralHowTo,
                    pageDefaultTitle: '',
                },
                {
                    path: RouterRoutes.HOW_TO_GENERIC_PAGE,
                    slug: RouterRoutes.HOW_TO_GENERIC_PAGE,
                    component: HowToGenericPage,
                    pageDefaultTitle: '',
                },
                {
                    path: RouterRoutes.HOW_TO_MONITORING_PAGE,
                    slug: RouterRoutes.HOW_TO_MONITORING_PAGE,
                    component: HowToMonitoringPage,
                    pageDefaultTitle: t(`breadcrumbs.wiki.MONITORING_HOWTO`),
                },
                {
                    path: RouterRoutes.GLOBAL_SEARCH,
                    slug: RouterRoutes.GLOBAL_SEARCH,
                    component: GlobalSearchPage,
                    pageDefaultTitle: t('globalSearch.headerDefault'),
                },
                {
                    path: RouterRoutes.DATA_OBJECT_CODE_LIST,
                    slug: RouterRoutes.DATA_OBJECT_CODE_LIST,
                    component: CodeListPage,
                    pageDefaultTitle: t('titles.codeListList'),
                },
                {
                    path: RouterRoutes.DATA_OBJECT_CODE_LIST_DETAIL,
                    slug: RouterRoutes.DATA_OBJECT_CODE_LIST_DETAIL,
                    component: CodeListDetailPage,
                    pageDefaultTitle: t('codeList.breadcrumbs.detail'),
                },
                {
                    path: RouterRoutes.DATA_OBJECT_CODE_LIST_EDIT,
                    slug: RouterRoutes.DATA_OBJECT_CODE_LIST_EDIT,
                    component: EditCodeListPage,
                    pageDefaultTitle: t('codeList.breadcrumbs.detailEdit'),
                },
                {
                    path: RouterRoutes.DATA_OBJECT_CODE_LIST_HIERARCHY,
                    slug: RouterRoutes.DATA_OBJECT_CODE_LIST_HIERARCHY,
                    component: CodeListHierarchyPage,
                    pageDefaultTitle: t('titles.codeListHierarchy'),
                },
                {
                    path: RouterRoutes.DATA_OBJECT_REQUESTS_LIST,
                    slug: RouterRoutes.DATA_OBJECT_REQUESTS_LIST,
                    component: RequestListPage,
                    pageDefaultTitle: t('titles.codeListRequestsList'),
                },
                {
                    path: RouterRoutes.DATA_OBJECT_REQUESTS_DETAIL,
                    slug: RouterRoutes.DATA_OBJECT_REQUESTS_DETAIL,
                    component: RequestListDetailPage,
                    pageDefaultTitle: t('titles.codeListRequestsDetail'),
                },
                {
                    path: RouterRoutes.DATA_OBJECT_REQUESTS_EDIT,
                    slug: RouterRoutes.DATA_OBJECT_REQUESTS_EDIT,
                    component: RequestListEditPage,
                    pageDefaultTitle: t('codeList.breadcrumbs.requestEdit'),
                },
                {
                    path: RouterRoutes.DATA_OBJECT_REQUESTS_CREATE,
                    slug: RouterRoutes.DATA_OBJECT_REQUESTS_CREATE,
                    component: RequestListCreatePage,
                    pageDefaultTitle: t('codeList.breadcrumbs.requestCreate'),
                },
                {
                    path: RouterRoutes.DATA_OBJECT_REF_IDENTIFIERS_LIST,
                    slug: RouterRoutes.DATA_OBJECT_REF_IDENTIFIERS_LIST,
                    component: RefIdentifiersPage,
                    pageDefaultTitle: t('titles.refIdentifiers'),
                },
                {
                    path: RouterRoutes.DATA_OBJECT_REF_IDENTIFIER_REQUESTS,
                    slug: RouterRoutes.DATA_OBJECT_REF_IDENTIFIER_REQUESTS,
                    component: RefIdentifiersRequestsPage,
                    pageDefaultTitle: t('titles.refIdentifiers'),
                },
                {
                    path: RouterRoutes.DATA_OBJECT_REF_IDENTIFIER_REQUESTS_DETAIL,
                    slug: RouterRoutes.DATA_OBJECT_REF_IDENTIFIER_REQUESTS_DETAIL,
                    component: RefIdentifierRequestDetailPage,
                    pageDefaultTitle: t('titles.refIdentifiers'),
                },
                {
                    path: RouterRoutes.DATA_OBJECT_REF_IDENTIFIER_REQUESTS_EDIT,
                    slug: RouterRoutes.DATA_OBJECT_REF_IDENTIFIER_REQUESTS_EDIT,
                    component: RefIdentifierRequestEditPage,
                    pageDefaultTitle: t('titles.refIdentifiersEdit'),
                },
                {
                    path: RouterRoutes.DATA_OBJECT_REF_IDENTIFIERS_DETAIL,
                    slug: RouterRoutes.DATA_OBJECT_REF_IDENTIFIERS_DETAIL,
                    component: RefIdentifierDetailPage,
                    pageDefaultTitle: t('titles.refIdentifiers'),
                },
                {
                    path: RouterRoutes.DATA_OBJECT_REF_IDENTIFIERS_CREATE,
                    slug: RouterRoutes.DATA_OBJECT_REF_IDENTIFIERS_CREATE,
                    component: RefIdentifierCreatePage,
                    pageDefaultTitle: t('breadcrumbs.refIdentifiersCreate'),
                },
                {
                    path: RouterRoutes.DATA_OBJECT_REF_IDENTIFIERS_EDIT,
                    slug: RouterRoutes.DATA_OBJECT_REF_IDENTIFIERS_EDIT,
                    component: RefIdentifierEditPage,
                    pageDefaultTitle: t('breadcrumbs.refIdentifiersEdit'),
                },
                {
                    path: RouterRoutes.REF_REGISTERS_LIST,
                    slug: RouterRoutes.REF_REGISTERS_LIST,
                    component: ReferenceRegisters,
                    pageDefaultTitle: t('titles.refIdentifiers'),
                },
                {
                    path: FooterRouteNames.COOKIES,
                    slug: FooterRouteNames.COOKIES,
                    component: CookiesInfoPage,
                    pageDefaultTitle: 'Cookies',
                },
                {
                    path: FooterRouteNames.ACCESSIBILITY_DECLARATION,
                    slug: FooterRouteNames.ACCESSIBILITY_DECLARATION,
                    component: DeclarationPage,
                    pageDefaultTitle: t('footer.accessibilityDeclaration'),
                },
                {
                    path: FooterRouteNames.PERSONAL_DATA_PROTECTION,
                    slug: FooterRouteNames.PERSONAL_DATA_PROTECTION,
                    component: PersonalDataInfoPage,
                    pageDefaultTitle: t('cookies.personalDataProtection.heading1'),
                },
                {
                    path: FooterRouteNames.TERMS_OF_USE,
                    slug: FooterRouteNames.TERMS_OF_USE,
                    component: TermsOfUse,
                    pageDefaultTitle: t('cookies.termsOfUse.heading1'),
                },
                {
                    path: FooterRouteNames.COOKIES_SETTINGS,
                    slug: FooterRouteNames.COOKIES_SETTINGS,
                    component: CookiesSettings,
                    pageDefaultTitle: t('cookies.settings.heading'),
                },
                {
                    path: FooterRouteNames.ABOUT_APPLICATION,
                    slug: FooterRouteNames.ABOUT_APPLICATION,
                    component: AboutApplicationPage,
                    pageDefaultTitle: t('aboutApp.heading'),
                },
                {
                    path: RouterRoutes.CI_REFERENCE_REGISTER,
                    slug: RouterRoutes.CI_REFERENCE_REGISTER,
                    component: ReferenceRegisterDetail,
                    pageDefaultTitle: t('refRegisters.detail.title', { name: '' }),
                },
                {
                    path: RouterRoutes.CI_PLACE_OF_OPERATION,
                    slug: RouterRoutes.CI_PLACE_OF_OPERATION,
                    component: PlaceOfOperationEntityListPage,
                    pageDefaultTitle: t('titles.ciList', { ci: '' }),
                },
                {
                    path: RouterRoutes.CI_PLACE_OF_OPERATION_DETAIL,
                    slug: RouterRoutes.CI_PLACE_OF_OPERATION_DETAIL,
                    component: PlaceOfOperationEntityDetailPage,
                    subRoutes: [GeneralCiDetailInformationOutlet()],
                    pageDefaultTitle: t('titles.ciDetailDefault'),
                },
                {
                    path: RouterRoutes.REF_REGISTERS_DETAIL,
                    slug: RouterRoutes.REF_REGISTERS_DETAIL,
                    component: RefRegistersDetail,
                    pageDefaultTitle: t('refRegisters.detail.title', { name: '' }),
                    subRoutes: [
                        {
                            slug: RouterRoutes.REF_REGISTERS_DETAIL,
                            component: RefRegistersInformation,
                            index: true,
                            pageDefaultTitle: t('refRegisters.detail.title', { name: '' }),
                        },
                        {
                            path: RouterRoutes.HISTORY_CHANGES_OUTLET,
                            slug: RouterRoutes.HISTORY_CHANGES_OUTLET,
                            component: RefRegistersHistoryChanges,
                            pageDefaultTitle: t('refRegisters.detail.title', { name: '' }),
                        },
                        {
                            path: RouterRoutes.HISTORY_OUTLET,
                            slug: RouterRoutes.HISTORY_OUTLET,
                            component: RefRegistersHistory,
                            pageDefaultTitle: t('refRegisters.detail.title', { name: '' }),
                        },
                    ],
                },
                {
                    path: RouterRoutes.REF_REGISTERS_CREATE,
                    slug: RouterRoutes.REF_REGISTERS_CREATE,
                    component: RefRegistersCreate,
                    pageDefaultTitle: t('refRegisters.create.title'),
                },
                {
                    path: RouterRoutes.REF_REGISTERS_EDIT,
                    slug: RouterRoutes.REF_REGISTERS_EDIT,
                    component: RefRegistersEdit,
                    pageDefaultTitle: t('refRegisters.edit.title'),
                },
                {
                    path: RouterRoutes.REF_REGISTERS_HISTORY_COMPARE_SINGLE_ITEM,
                    slug: RouterRoutes.REF_REGISTERS_HISTORY_COMPARE_SINGLE_ITEM,
                    component: RefRegistersCompareSinglePage,
                    pageDefaultTitle: t('breadcrumbs.compareHistory'),
                },
                {
                    path: RouterRoutes.REF_REGISTERS_HISTORY_COMPARE_TWO_ITEMS,
                    slug: RouterRoutes.REF_REGISTERS_HISTORY_COMPARE_TWO_ITEMS,
                    component: RefRegistersComparePage,
                    pageDefaultTitle: t('breadcrumbs.compareHistory'),
                },
                {
                    path: RouterRoutes.CI_PO_IS,
                    slug: RouterRoutes.CI_PO_IS,
                    component: POIsListPage,
                    pageDefaultTitle: t('titles.PO_IS'),
                },
                {
                    path: RouterRoutes.CI_PO_IS_PO,
                    slug: RouterRoutes.CI_PO_IS_PO,
                    component: POIsPOListPage,
                    pageDefaultTitle: t('titles.PO_IS_PO'),
                },
                {
                    path: RouterRoutes.CI_PO_PO,
                    slug: RouterRoutes.CI_PO_PO,
                    component: POPOListPage,
                    pageDefaultTitle: t('titles.PO_PO'),
                },
                {
                    path: RouterRoutes.CI_PO_PO_DETAIL,
                    slug: RouterRoutes.CI_PO_PO_DETAIL,
                    component: PO_POEntityDetailPage,
                    subRoutes: [PO_POInfoOutlet(), ...POOutlets()],
                    pageDefaultTitle: t('titles.ciDetailDefault'),
                },
                {
                    path: RouterRoutes.CI_PO_IS_DETAIL,
                    slug: RouterRoutes.CI_PO_IS_DETAIL,
                    component: PO_ISEntityDetailPage,
                    subRoutes: [PO_ISInfoOutlet(), ...POOutlets()],
                    pageDefaultTitle: t('titles.ciDetailDefault'),
                },
                {
                    path: RouterRoutes.CI_PO_IS_PO_DETAIL,
                    slug: RouterRoutes.CI_PO_IS_PO_DETAIL,
                    component: PO_IS_POEntityDetailPage,
                    subRoutes: [PO_IS_POInfoOutlet(), ...POOutlets()],
                    pageDefaultTitle: t('titles.ciDetailDefault'),
                },
                {
                    path: RouterRoutes.CI_PRINCIPLE_DETAIL,
                    slug: RouterRoutes.CI_PRINCIPLE_DETAIL,
                    component: PrincipleEntityDetailPage,
                    subRoutes: [GeneralCiDetailInformationOutlet()],
                    pageDefaultTitle: t('titles.ciDetailDefault'),
                },
                {
                    path: RouterRoutes.CI_PRINCIPLE_CREATE,
                    slug: RouterRoutes.CI_PRINCIPLE_CREATE,
                    component: CreateEntityPage,
                    pageDefaultTitle: t('titles.ciCreateEntity', { ci: '' }),
                },
                {
                    path: RouterRoutes.CI_TRAINING_DETAIL,
                    slug: RouterRoutes.CI_TRAINING_DETAIL,
                    component: TrainingEntityDetailPage,
                    pageDefaultTitle: t('titles.ciDetailDefault'),
                    subRoutes: [
                        ...GeneralCiDetailOutlets().filter((outlet) => outlet.slug !== RouterRoutes.CI_DETAIL),
                        {
                            slug: RouterRoutes.CI_DETAIL,
                            component: TrainingInformation,
                            index: true,
                            pageDefaultTitle: t('titles.ciDetailDefault'),
                        },
                    ],
                },
                {
                    path: RouterRoutes.CI_TRAINING,
                    slug: RouterRoutes.CI_TRAINING,
                    component: TrainingListPage,
                    pageDefaultTitle: t('titles.ciList', { ci: '' }),
                },
                {
                    path: RouterRoutes.CI_TRAINING_CREATE,
                    slug: RouterRoutes.CI_TRAINING_CREATE,
                    component: CreateTrainingEntityPage,
                    pageDefaultTitle: t('titles.ciCreateEntity', { ci: '' }),
                },
                {
                    path: RouterRoutes.CI_TRAINING_EDIT,
                    slug: RouterRoutes.CI_TRAINING_EDIT,
                    component: EditTrainingEntityPage,
                    pageDefaultTitle: t('titles.ciEdit', { ci: '' }),
                },
                {
                    path: RouterRoutes.CI_TRAINING_INVITE,
                    slug: RouterRoutes.CI_TRAINING_INVITE,
                    component: TrainingInvitePage,
                    pageDefaultTitle: t('trainings.invitedTitle'),
                },
                {
                    path: RouterRoutes.CI_ZC,
                    slug: RouterRoutes.CI_ZC,
                    component: ZCListPage,
                    pageDefaultTitle: t('titles.ciList', { ci: '' }),
                },
                {
                    path: RouterRoutes.CI_ZC_DETAIL,
                    slug: RouterRoutes.CI_ZC_DETAIL,
                    component: ZCEntityDetailPage,
                    subRoutes: GeneralCiDetailOutlets(),
                    pageDefaultTitle: t('titles.ciDetailDefault'),
                },
                {
                    path: RouterRoutes.CI_KRIS_LIST,
                    slug: RouterRoutes.CI_KRIS_LIST,
                    component: KRISListPage,
                    pageDefaultTitle: t('titles.ciList', { ci: '' }),
                },
                {
                    path: RouterRoutes.CI_KRIS_DETAIL,
                    slug: RouterRoutes.CI_KRIS_DETAIL,
                    component: KrisEntityDetailPage,
                    pageDefaultTitle: t('titles.ciDetailDefault'),
                    subRoutes: [
                        {
                            path: RouterRoutes.DOCUMENTS_OUTLET,
                            slug: RouterRoutes.DOCUMENTS_OUTLET,
                            component: KRISDocumentsListPage,
                            pageDefaultTitle: t('titles.ciDetailDefault'),
                        },
                        ...GeneralCiDetailOutlets(),
                        {
                            path: RouterRoutes.GOALS_OUTLET,
                            slug: RouterRoutes.GOALS_OUTLET,
                            component: Goals,
                            pageDefaultTitle: t('titles.ciDetailDefault'),
                        },
                        {
                            path: RouterRoutes.TASKS_OUTLET,
                            slug: RouterRoutes.TASKS_OUTLET,
                            component: Tasks,
                            pageDefaultTitle: t('titles.ciDetailDefault'),
                        },
                        {
                            path: RouterRoutes.EVALUATION_OUTLET,
                            slug: RouterRoutes.EVALUATION_OUTLET,
                            component: Evaluation,
                            pageDefaultTitle: t('titles.ciDetailDefault'),
                        },
                    ],
                },
                {
                    path: RouterRoutes.CI_KRIS_CREATE,
                    slug: RouterRoutes.CI_KRIS_CREATE,
                    component: CreateKrisEntityPage,
                    pageDefaultTitle: t('titles.ciCreateEntity', { ci: '' }),
                },
                {
                    path: RouterRoutes.CI_PROJECT_DETAIL,
                    slug: RouterRoutes.CI_PROJECT_DETAIL,
                    component: ProjectEntityDetailPage,
                    pageDefaultTitle: t('titles.ciDetailDefault'),
                    subRoutes: [
                        ...GeneralCiDetailOutlets().filter((outlet) => outlet.path !== RouterRoutes.DOCUMENTS_OUTLET),
                        {
                            path: RouterRoutes.ACTIVITIES_OUTLET,
                            slug: RouterRoutes.ACTIVITIES_OUTLET,
                            component: ActivitiesListPage,
                            pageDefaultTitle: t('titles.ciDetailDefault'),
                        },
                        {
                            path: RouterRoutes.DOCUMENTS_OUTLET,
                            slug: RouterRoutes.DOCUMENTS_OUTLET,
                            component: ProjectDocumentsListPage,
                            pageDefaultTitle: t('titles.ciDetailDefault'),
                        },
                    ],
                },
                {
                    path: RouterRoutes.CI_PROJECT_CREATE,
                    slug: RouterRoutes.CI_PROJECT_CREATE,
                    component: CreateProjectPage,
                    pageDefaultTitle: t('titles.ciCreateEntity', { ci: '' }),
                },
                {
                    path: RouterRoutes.CI_PROJECT_EDIT,
                    slug: RouterRoutes.CI_PROJECT_EDIT,
                    component: EditProjectPage,
                    pageDefaultTitle: t('titles.ciEdit', { ci: '' }),
                },
                {
                    path: RouterRoutes.CI_KS_DETAIL,
                    slug: RouterRoutes.CI_KS_DETAIL,
                    component: KsEntityDetailPage,
                    subRoutes: [GeneralCiDetailInformationOutlet()],
                    pageDefaultTitle: t('titles.ciDetailDefault'),
                },
                {
                    path: RouterRoutes.CI_KS_CREATE,
                    slug: RouterRoutes.CI_KS_CREATE,
                    component: CreateEntityPage,
                    pageDefaultTitle: t('titles.ciCreateEntity', { ci: '' }),
                },
                {
                    path: RouterRoutes.CI_FORMULAR_CREATE,
                    slug: RouterRoutes.CI_FORMULAR_CREATE,
                    component: CreateFormularPage,
                    pageDefaultTitle: t('titles.ciCreateEntity', { ci: '' }),
                },
                {
                    path: RouterRoutes.CI_FORMULAR_EDIT,
                    slug: RouterRoutes.CI_FORMULAR_EDIT,
                    component: EditFormularEntityPage,
                    pageDefaultTitle: t('titles.ciCreateEntity', { ci: '' }),
                },
                {
                    path: RouterRoutes.CI_AS_DETAIL,
                    slug: RouterRoutes.CI_AS_DETAIL,
                    component: AsEntityDetailPage,
                    subRoutes: [GeneralCiDetailInformationOutlet()],
                    pageDefaultTitle: t('titles.ciEdit'),
                },
                {
                    path: RouterRoutes.CI_AS_CREATE,
                    slug: RouterRoutes.CI_AS_CREATE,
                    component: CreateEntityPage,
                    pageDefaultTitle: t('titles.ciCreateEntity', { ci: '' }),
                },
                {
                    path: RouterRoutes.ITVS_EXCEPTIONS_LIST,
                    slug: RouterRoutes.ITVS_EXCEPTIONS_LIST,
                    component: ITVSExceptionListPage,
                    pageDefaultTitle: t('titles.ciList', { ci: '' }),
                },
                {
                    path: RouterRoutes.ITVS_EXCEPTIONS_CREATE,
                    slug: RouterRoutes.ITVS_EXCEPTIONS_CREATE,
                    component: CreateITVSExceptionsPage,
                    pageDefaultTitle: t('titles.ciCreateEntity', { ci: '' }),
                },
                {
                    path: RouterRoutes.ITVS_EXCEPTIONS_DETAIL,
                    slug: RouterRoutes.ITVS_EXCEPTIONS_DETAIL,
                    component: EntityDetailPage,
                    pageDefaultTitle: t('titles.ciDetailDefault'),
                    subRoutes: [
                        {
                            slug: RouterRoutes.CI_DETAIL,
                            component: ITVSExceptionsInformation,
                            index: true,
                            pageDefaultTitle: t('titles.ciDetailDefault'),
                        },
                    ],
                },
                {
                    path: RouterRoutes.ITVS_EXCEPTIONS_EDIT,
                    slug: RouterRoutes.ITVS_EXCEPTIONS_EDIT,
                    component: ITVSExceptionsEditPage,
                    pageDefaultTitle: t('titles.ciEdit', { ci: '' }),
                },
                {
                    path: RouterRoutes.CI_ISVS_DETAIL,
                    slug: RouterRoutes.CI_ISVS_DETAIL,
                    component: ISVSDetailPage,
                    pageDefaultTitle: t('titles.ciDetailDefault'),
                    subRoutes: [
                        ...GeneralCiDetailOutlets().filter((outlet) => outlet.slug !== RouterRoutes.CI_DETAIL),
                        {
                            path: RouterRoutes.WEB_DECLARATION,
                            slug: RouterRoutes.WEB_DECLARATION,
                            component: DeclarationAccordionPage,
                            pageDefaultTitle: t('titles.ciDetailDefault'),
                        },
                        {
                            path: RouterRoutes.FINANCES,
                            slug: RouterRoutes.FINANCES,
                            component: ISVSFinancesPage,
                            pageDefaultTitle: t('ciType.finances'),
                        },
                        {
                            slug: RouterRoutes.CI_DETAIL,
                            component: IsvsInformation,
                            index: true,
                            pageDefaultTitle: t('titles.ciDetailDefault'),
                        },
                    ],
                },
                {
                    path: RouterRoutes.CI_ISVS_CREATE,
                    slug: RouterRoutes.CI_ISVS_CREATE,
                    component: CreateEntityPage,
                    pageDefaultTitle: t('titles.ciCreateEntity', { ci: '' }),
                },
                {
                    path: NavigationSubRoutes.ATRIBUTY_OBJEKTY_EVIDENCIE,
                    slug: NavigationSubRoutes.ATRIBUTY_OBJEKTY_EVIDENCIE,
                    component: AtributObjektEvidencieListPage,
                    pageDefaultTitle: t('titles.ciList', { ci: '' }),
                },
                {
                    path: NavigationSubRoutes.ATRIBUTY_OBJEKTY_EVIDENCIE_DETAIL,
                    slug: NavigationSubRoutes.ATRIBUTY_OBJEKTY_EVIDENCIE_DETAIL,
                    component: AtributObjektuEvidancieEntityDetailPage,
                    pageDefaultTitle: t('titles.ciDetailDefault'),
                    subRoutes: [
                        ...GeneralCiDetailOutlets().filter((outlet) => outlet.slug !== RouterRoutes.CI_DETAIL),
                        {
                            slug: NavigationSubRoutes.ATRIBUTY_OBJEKTY_EVIDENCIE_DETAIL,
                            component: AttributeRecordObjectInformation,
                            index: true,
                            pageDefaultTitle: t('titles.ciDetailDefault'),
                        },
                    ],
                },
                {
                    path: NavigationSubRoutes.OBJEKTY_EVIDENCIE,
                    slug: NavigationSubRoutes.OBJEKTY_EVIDENCIE,
                    component: ObjektEvidencieListPage,
                    pageDefaultTitle: t('titles.ciList', { ci: '' }),
                },
                {
                    path: NavigationSubRoutes.OBJEKTY_EVIDENCIE_DETAIL,
                    slug: NavigationSubRoutes.OBJEKTY_EVIDENCIE_DETAIL,
                    component: ObjectEvidencieDetailPage,
                    pageDefaultTitle: t('titles.ciDetailDefault'),
                    subRoutes: [
                        ...GeneralCiDetailOutlets().filter((outlet) => outlet.slug !== RouterRoutes.CI_DETAIL),
                        {
                            slug: NavigationSubRoutes.OBJEKTY_EVIDENCIE_DETAIL,
                            component: ObjektEvidencieInformation,
                            index: true,
                            pageDefaultTitle: t('titles.ciDetailDefault'),
                        },
                    ],
                },
                {
                    path: RouterRoutes.ATRIBUT_OBJEKTU_EVIDENCIE_CREATE,
                    slug: RouterRoutes.ATRIBUT_OBJEKTU_EVIDENCIE_CREATE,
                    component: CreateAtributObjektuEvidenciePage,
                    pageDefaultTitle: t('titles.ciCreateEntity', { ci: '' }),
                },
                {
                    path: RouterRoutes.ATRIBUT_OBJEKTU_EVIDENCIE_EDIT,
                    slug: RouterRoutes.ATRIBUT_OBJEKTU_EVIDENCIE_EDIT,
                    component: AtributObjektuEvidencieEditPage,
                    pageDefaultTitle: t('titles.ciEdit', { ci: '' }),
                },
                {
                    path: NavigationSubRoutes.OBJEKTY_EVIDENCIE_CREATE,
                    slug: NavigationSubRoutes.OBJEKTY_EVIDENCIE_CREATE,
                    component: CreateEvidenceObject,
                    pageDefaultTitle: t('titles.ciCreateEntity', { ci: '' }),
                },
                {
                    path: NavigationSubRoutes.OBJEKTY_EVIDENCIE_EDIT,
                    slug: NavigationSubRoutes.OBJEKTY_EVIDENCIE_EDIT,
                    component: EditEvidenceObject,
                    pageDefaultTitle: t('titles.ciEdit', { ci: '' }),
                },
                {
                    path: LoginRouteNames.LOGIN_PROBLEMS,
                    slug: LoginRouteNames.LOGIN_PROBLEMS,
                    component: LoginProblemsPage,
                    pageDefaultTitle: t('breadcrumbs.loginProblems'),
                },
                {
                    path: LoginRouteNames.FORGOTTEN_PASSWORD,
                    slug: LoginRouteNames.FORGOTTEN_PASSWORD,
                    component: ForgottenPasswordPage,
                    pageDefaultTitle: t('breadcrumbs.forgottenPassword'),
                },
                {
                    path: RouterRoutes.SLA_CONTRACT_LIST,
                    slug: RouterRoutes.SLA_CONTRACT_LIST,
                    component: SlaContractList,
                    pageDefaultTitle: t('titles.ciList', { ci: '' }),
                },
                {
                    path: RouterRoutes.OLA_CONTRACT_CREATE,
                    slug: RouterRoutes.OLA_CONTRACT_CREATE,
                    component: OlaContractCreate,
                    pageDefaultTitle: t('titles.ciCreateEntity', { ci: '' }),
                },
                {
                    path: RouterRoutes.OLA_CONTRACT_EDIT,
                    slug: RouterRoutes.OLA_CONTRACT_EDIT,
                    component: OlaContractEdit,
                    pageDefaultTitle: t('titles.ciEdit', { ci: '' }),
                },
                {
                    path: RouterRoutes.OLA_CONTRACT_DETAIL,
                    slug: RouterRoutes.OLA_CONTRACT_DETAIL,
                    component: OlaContractDetailPage,
                    pageDefaultTitle: t('olaContracts.detail.heading'),
                    subRoutes: [
                        {
                            slug: RouterRoutes.OLA_CONTRACT_DETAIL,
                            component: OlaContractDetail,
                            index: true,
                            pageDefaultTitle: t('olaContracts.detail.heading'),
                        },
                    ],
                },
                {
                    path: RouterRoutes.SLA_CONTRACT_DETAIL,
                    slug: RouterRoutes.SLA_CONTRACT_DETAIL,
                    component: SlaContractDetailPage,
                    pageDefaultTitle: t('titles.ciDetailDefault'),
                    subRoutes: [
                        {
                            slug: RouterRoutes.SLA_CONTRACT_DETAIL,
                            component: SlaContractInformation,
                            index: true,
                            pageDefaultTitle: t('titles.ciDetailDefault'),
                        },
                        {
                            path: RouterRoutes.SLA_CONTRACT_SUPPORT_CONTACT,
                            slug: RouterRoutes.SLA_CONTRACT_SUPPORT_CONTACT,
                            component: SlaContractSupportContact,
                            pageDefaultTitle: t('titles.ciDetailDefault'),
                        },
                        {
                            path: RouterRoutes.SLA_CONTRACT_HISTORY,
                            slug: RouterRoutes.SLA_CONTRACT_HISTORY,
                            component: SlaContractHistory,
                            pageDefaultTitle: t('titles.ciDetailDefault'),
                        },
                    ],
                },
                {
                    path: SLARouteNames.SLADetail,
                    slug: SLARouteNames.SLADetail,
                    component: SLADetailPage,
                    pageDefaultTitle: t('sla-detail.slaDetail'),
                },
                {
                    path: SLARouteNames.SLAParamsList,
                    slug: SLARouteNames.SLAParamsList,
                    component: SLAParamsListPage,
                    pageDefaultTitle: t('sla-params-list.slaList'),
                },
                {
                    path: RouterRoutes.RELATION_LIST,
                    slug: RouterRoutes.RELATION_LIST,
                    component: RelationListPage,
                    pageDefaultTitle: t('titles.relationsSearch'),
                },
                {
                    path: RouterRoutes.BULK_ACTION_ITEM_LIST,
                    slug: RouterRoutes.BULK_ACTION_ITEM_LIST,
                    component: BulkListPage,
                    pageDefaultTitle: t('bulkList.title'),
                },
                {
                    path: FooterRouteNames.CONTENT_ADMIN,
                    slug: FooterRouteNames.CONTENT_ADMIN,
                    component: SpravcaObsahu,
                    pageDefaultTitle: t('footer.contentAdmin'),
                },
                {
                    path: FooterRouteNames.TECHNICAL_OPERATOR,
                    slug: FooterRouteNames.TECHNICAL_OPERATOR,
                    component: TechnicalOperator,
                    pageDefaultTitle: t('footer.technicalOperator'),
                },
                {
                    path: RouteNames.CONTACT,
                    slug: RouteNames.CONTACT,
                    component: Contact,
                    pageDefaultTitle: t('footer.contact'),
                },
                {
                    path: FooterRouteNames.SITEMAP,
                    slug: FooterRouteNames.SITEMAP,
                    component: SiteMapPage,
                    pageDefaultTitle: t('footer.webResidencyMap'),
                },
                {
                    path: FooterRouteNames.OPENDATA,
                    slug: FooterRouteNames.OPENDATA,
                    component: OpenDataPage,
                    pageDefaultTitle: t('footer.opendata'),
                },
                {
                    path: '*',
                    component: PageNotFound,
                    pageDefaultTitle: '',
                },
            ],
        },
    ]
}
